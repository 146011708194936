/* eslint-disable @typescript-eslint/no-unused-vars */
import { useIsLogged } from "@business-layer/business-logic/lib/auth";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { APP_URLS } from "@constants/urls.constants";
import {
  CommonButton,
  CustomAnimateWrapper,
  ModernParagraph,
  TAnimateVariants,
} from "@presentational/atoms";
import { HiddenValleyIntroHeader } from "@presentational/organisms";
import { SingleSectionTemplate } from "@presentational/templates";
import { COLOR_THEME } from "@presentational/themes/colors";
import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const VARIANTS: { [key: string]: TAnimateVariants } = {
  introHeader: {
    initial: { y: -150, opacity: 0 },
    animate: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      y: -150,
      opacity: 0,
      transition: { duration: 0.8, ease: "anticipate" },
    },
  },
  mountainLayer3: {
    initial: { y: 150, opacity: 0 },
    animate: {
      y: 50,
      opacity: 1,
      transition: { delay: 0.5, duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      y: "100vh",
      opacity: 1,
      transition: { duration: 0.8, ease: "anticipate" },
    },
  },
  mountainLayer2: {
    initial: { y: 150, opacity: 0 },
    animate: {
      y: 50,
      opacity: 1,
      transition: { delay: 1, duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      y: "100vh",
      opacity: 1,
      transition: { duration: 0.8, ease: "anticipate" },
    },
  },
  mountainLayer1: {
    initial: { y: 150, opacity: 0 },
    animate: {
      y: 50,
      opacity: 1,
      transition: { delay: 1.5, duration: 0.5, ease: "easeInOut" },
    },
    exit: {
      y: "100vh",
      opacity: 1,
      transition: { duration: 0.8, ease: "anticipate" },
    },
  },
  playMusicBtn: {
    initial: { rotate: 0 },
    animate: {
      rotate: -360,
      transition: {
        duration: 10,
        ease: "linear",
        repeat: Infinity,
      },
    },
    exit: { rotate: 0 },
  },
  langBtn: {
    initial: {
      width: "2rem",
      height: "2rem",
      borderRadius: "5rem",
      borderColor: COLOR_THEME.Gray,
      color: COLOR_THEME.Gray,
      backgroundColor: COLOR_THEME.Dark,
    },
    animate: {
      width: "8rem",
      height: "8.5rem",
      borderRadius: "1rem",
      borderColor: COLOR_THEME.White,
      color: COLOR_THEME.Dark,
      backgroundColor: COLOR_THEME.White,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    exit: {
      width: "2rem",
      height: "2rem",
      borderRadius: "5rem",
      borderColor: COLOR_THEME.Gray,
      color: COLOR_THEME.Gray,
      backgroundColor: COLOR_THEME.Dark,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  },
  langLabelSpan: {
    initial: {
      display: "none",
    },
    exit: {
      display: "none",
      transition: {
        duration: 0.01,
        ease: "easeInOut",
      },
    },
    animate: {
      display: "inline-block",
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  },
  langDropdown: {
    initial: {
      height: "0rem",
      opacity: 0,
      pointerEvents: "none",
      visibility: "hidden",
    },
    exit: {
      height: "0rem",
      opacity: 0,
      pointerEvents: "none",
      visibility: "hidden",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
    animate: {
      height: "auto",
      opacity: 1,
      pointerEvents: "auto",
      visibility: "visible",
      transition: {
        duration: 0.5,
        ease: "easeInOut",
      },
    },
  },
};

function IntroHiddenValley() {
  const { trans, currentLang } = useLanguage();
  const navigate = useNavigate();
  const [isOut, setIsOut] = useState<boolean>(false);
  const isLogged = useIsLogged();

  function handleClickGetStarted() {
    setIsOut(true);
    setTimeout(() => {
      navigate(
        `/${currentLang}${isLogged ? APP_URLS.DASHBOARD : APP_URLS.LOGIN}`
      );
    }, 800);
  }

  return (
    <SingleSectionTemplate>
      <AnimatePresence mode="wait">
        {isOut === false ? (
          <>
            <HiddenValleyIntroHeader />
            <div className="w-full h-full relative flex flex-col justify-start">
              <div className="flex-grow"></div>
              <div className="w-full z-20 flex-shrink relative pointer-events-none">
                {/* <CustomAnimateWrapper
                  as="img"
                  variants={VARIANTS.mountainLayer3}
                  key="mountain-layer-3"
                  src="/assets/imgs/graphics/mountain_layer_3.png"
                  alt="Hello world"
                  className="absolute bottom-0 left-0 w-full h-auto z-40"
                >
                  <div className="w-full h-auto object-cover relative">
                    <img
                      src="/assets/imgs/graphics/mountain_layer_3.png"
                      alt="Hello world"
                      className="w-full h-auto object-cover relative z-20"
                    />
                    <CustomAnimateWrapper
                      as="img"
                      variants={{
                        initial: { x: -50, y: 100, opacity: 0 },
                        animate: {
                          x: 0,
                          y: 0,
                          opacity: 1,
                          transition: {
                            delay: 2,
                            duration: 0.3,
                            type: "spring",
                            stiffness: 300,
                            damping: 20,
                            bounce: 0.5,
                          },
                        },
                        exit: { x: -50, y: 100, opacity: 0 },
                      }}
                      key="intro-blueberry"
                      src="/assets/imgs/graphics/blueberry.png"
                      alt="Hello world"
                      className="absolute top-[-2.5rem] left-3 w-[9rem] h-[9rem] object-cover z-10"
                    />
                    <div
                      key="blueberry_chat_box"
                      className="absolute bottom-[calc(100%-2rem)] left-[9rem] object-cover z-10 flex flex-col justify-end"
                    >
                      <CustomAnimateWrapper
                        as="div"
                        key={"blueberry_chat_box"}
                        variants={{
                          initial: { x: -100, y: 100, scaleX: 0, opacity: 0 },
                          animate: {
                            x: 0,
                            y: 0,
                            scaleX: 1,
                            opacity: 1,
                            transition: {
                              delay: 2.25,
                              duration: 0.3,
                              type: "spring",
                              stiffness: 300,
                              damping: 20,
                              bounce: 0.5,
                            },
                          },
                          exit: { x: -50, y: 100, opacity: 0 },
                        }}
                      >
                        <div className="py-4 px-6 bg-White rounded-2xl shadow-md relative max-w-96">
                          <ModernParagraph
                            weight="400"
                            size="base"
                            className="text-justify"
                          >
                            {trans(
                              "HIDDEN_VALLEY",
                              "intro.about-hidden-valley"
                            )}
                          </ModernParagraph>

                          <div className="bg-White w-6 h-6 absolute top-[calc(100%-1.35rem)] left-[-0.15rem] rounded-ss-full rounded-ee-full"></div>
                        </div>
                        <div className="mt-2 flex-row flex justify-center">
                          <CommonButton
                            theme="Primary"
                            className="rounded-full hover:!bg-Sky pointer-events-auto"
                            size="small"
                            onClick={handleClickGetStarted}
                          >
                            {trans("HIDDEN_VALLEY", "intro.get-started")}
                          </CommonButton>
                        </div>
                      </CustomAnimateWrapper>
                    </div>
                  </div>
                </CustomAnimateWrapper> */}
                {/* <CustomAnimateWrapper
                  as="img"
                  key="mountain-layer-2"
                  src="/assets/imgs/graphics/mountain_layer_2.png"
                  alt="Hello world"
                  className="relative w-full h-auto object-cover z-20"
                  variants={VARIANTS.mountainLayer2}
                />
                <CustomAnimateWrapper
                  as="img"
                  key="mountain-layer-1"
                  src="/assets/imgs/graphics/mountain_layer_1.png"
                  alt="Hello world"
                  className="absolute bottom-0 left-0 w-full h-auto object-cover z-10"
                  variants={VARIANTS.mountainLayer1}
                /> */}
              </div>
            </div>
          </>
        ) : null}
      </AnimatePresence>
    </SingleSectionTemplate>
  );
}
export default IntroHiddenValley;
