import {
  AccountService,
  TEditAccountDetailProps,
  TEditAccountDetailResponse,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

const service = new AccountService();

export const useEditAccountDetailMutation = () => {
  return useMutation<
    TEditAccountDetailResponse,
    Error,
    TEditAccountDetailProps,
    unknown
  >({
    mutationFn: service.editAccountDetail,
  });
};
