import { useDispatch } from "react-redux";
import { setBluelyraSeekTo } from "../context";

export const useChangeBluelyraSeekTo = () => {
  const dispatch = useDispatch();
  function onSeekTo(val: number) {
    dispatch(setBluelyraSeekTo(val));
  }
  return {
    onSeekTo,
  };
};
