import { APP_FALLBACK_EXIT_DURATION } from "@constants/system.constants";
import "@presentational/styles/animation.scss";
import { motion } from "framer-motion";

function FallbackLoader() {
  return (
    <motion.div
      key="FallbackLoader"
      initial={{ opacity: 1 }}
      exit={{
        opacity: 0,
        transition: {
          duration: APP_FALLBACK_EXIT_DURATION,
          ease: "easeInOut",
        },
      }}
      className="fallback-loader bg-Dark"
    >
      <div
        className="fallback-loader__container"
        style={{
          backgroundImage: `url(
            "/assets/imgs/loading/loading-sprite.white.png"
          )`,
        }}
      ></div>
    </motion.div>
  );
}

export default FallbackLoader;
