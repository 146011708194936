import { useDispatch } from "react-redux";
import { addNewPassword } from "../context/passwordReducers";
import { useAddNewHiddenValleyPasswordMutation } from "../../fetching/mutation";
import { TAddNewPasswordParams } from "@business-layer/services";
import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { AES } from "crypto-js";
import { ENV_KEYS } from "@business-layer/business-logic/configs";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

export const useAddNewHiddenValleyPassword = () => {
  const dispatch = useDispatch();
  const { mutateAsync, isPending } = useAddNewHiddenValleyPasswordMutation();
  const token = useGetToken();
  const { trans } = useLanguage();

  async function onAdd(
    params: Omit<TAddNewPasswordParams, "token">
  ): Promise<void> {
    try {
      // Encrypt the password
      const encryptedPassword = AES.encrypt(
        params.value,
        ENV_KEYS.HIDDEN_VALLEY_ENCRYPT_SECRET
      ).toString();
      const { createdPassword } = await mutateAsync({
        ...params,
        value: encryptedPassword,
        token,
      });
      dispatch(addNewPassword(createdPassword));
    } catch (error: any) {
      console.error(error);
      throw new Error(trans("SERVER", error.message));
    }
  }
  return {
    onAdd,
    isLoading: isPending,
  };
};
