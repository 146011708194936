import {
  TGetCurrentWeatherProps,
  TGetCurrentWeatherResponse,
  WeatherService,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

const service = new WeatherService();

export const useGetCurrentWeatherMutation = () => {
  return useMutation<
    TGetCurrentWeatherResponse,
    Error,
    TGetCurrentWeatherProps,
    unknown
  >({
    mutationFn: service.getCurrentWeather,
  });
};
