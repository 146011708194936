import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { TWeatherState } from "./type";
import { getLocalStorage } from "@utils/helpers";
import { WEATHER_LOCAL_STORAGE_KEY } from "../../constants";

const initialState: TWeatherState = getLocalStorage(
  WEATHER_LOCAL_STORAGE_KEY.WEATHER
) ?? { coord: null, weather: null, forecastAt: null };

const slice = createSlice<
  TWeatherState,
  SliceCaseReducers<TWeatherState>,
  "weather",
  SliceSelectors<TWeatherState>
>({
  name: "weather",
  initialState,
  reducers: {
    setAllWeatherData: (
      state: TWeatherState,
      action: PayloadAction<TWeatherState>
    ) => {
      return action.payload;
    },
    setWeather: (
      state: TWeatherState,
      action: PayloadAction<TWeatherState["weather"]>
    ) => {
      return {
        ...state,
        weather: action.payload,
      };
    },
    setCoord: (
      state: TWeatherState,
      action: PayloadAction<TWeatherState["coord"]>
    ) => {
      return {
        ...state,
        coord: action.payload,
      };
    },
  },
});

export const { setWeather, setCoord, setAllWeatherData } = slice.actions;
export default slice.reducer;
