import { TLang } from "@constants/lang.constants";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

export const useChangeLanguage = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  function onChange(lang: TLang) {
    const newPathName = `/${lang}${pathname.slice(3)}`;
    navigate(newPathName, { replace: true });
    i18n.changeLanguage(lang);
  }
  return {
    onChange,
  };
};
