import { z } from "zod";

export enum EPlanName {
  Novice = "Novice",
  Elite = "Elite",
  Legendary = "Legendary",
}
export interface IPreviewPlan {
  planId: string;
  name: EPlanName;
}
export interface IPlan extends IPreviewPlan {
  pricing: number;
  yearlyDiscount: number;
}
export const PreviewPlanSchema = z.object({
  planId: z.string(),
  name: z.string(),
});
export const PlanSchema = PreviewPlanSchema.merge(
  z.object({
    pricing: z.number(),
    yearlyDiscount: z.number(),
  })
);
