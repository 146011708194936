import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { AnimatePresence } from "framer-motion";
import { TSuccessSubmitData } from "@presentational/organisms";
import {
  AnimateWrapper,
  CommonButton,
  CustomAnimateWrapper,
  EmptyBlueberry,
  ModernParagraph,
} from "@presentational/atoms";
import {
  useFetchAllHiddenValleyPasswords,
  useAddNewHiddenValleyPassword,
} from "@business-layer/business-logic/lib/hiddenValley";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import { HiddenValleyPasswordsList } from "@presentational/molecules";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { HiddenValleyHomeVariants } from "./variants";
import { IHiddenValleyPassword } from "@business-layer/services/entities";
import { AppContent } from "@presentational/templates";

const HiddenValleyPasswordInteractionForm = lazy(
  () =>
    import("@presentational/organisms/form/HiddenValleyPasswordInteractionForm")
);
const CommonOverlayModal = lazy(
  () => import("@presentational/molecules/overlay/CommonOverlayModal")
);

function HiddenValleyHome() {
  const { data: hiddenValleyPasswords } = useFetchAllHiddenValleyPasswords();
  const { onAdd, isLoading } = useAddNewHiddenValleyPassword();
  const { toastPromise, toastError } = useNotification();
  const { trans } = useLanguage();
  const [isCreatingPassword, setIsCreatingPassword] = useState<boolean>(false);
  const [displayedPasswords, setDisplayedPasswords] = useState<
    IHiddenValleyPassword[]
  >([]);
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [tags, setTags] = useState<string[]>([]);

  const onCreateNewPassword = useCallback(async (data: TSuccessSubmitData) => {
    const addNewPasswordPromise = onAdd({
      name: data.name,
      value: data.password,
      encryptLevel: data.selectedEncryptMethod,
      key: data.key.length > 0 ? data.key : null,
      icon: data.selectedIcon,
    });
    toastPromise({
      loadingMsg: trans(
        "HIDDEN_VALLEY",
        "dashboard.create-new-password-inprogress"
      ),
      successMsg: trans(
        "HIDDEN_VALLEY",
        "dashboard.create-new-password-success"
      ),
      promise: addNewPasswordPromise,
    });
    try {
      await addNewPasswordPromise;
      handleCloseCreatePasswordForm();
    } catch (error: any) {
      toastError(error.message);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleCloseCreatePasswordForm = useCallback(() => {
    setIsCreatingPassword(false);
  }, []);
  const handleOpenCreatePasswordForm = useCallback(() => {
    setIsCreatingPassword(true);
  }, []);

  useEffect(() => {
    if (Array.isArray(hiddenValleyPasswords)) {
      const newTags = new Set<string>();
      hiddenValleyPasswords.forEach((p) => p.key && newTags.add(p.key));
      setTags(Array.from(newTags));

      if (selectedTag && !newTags.has(selectedTag)) {
        setSelectedTag(null);
      } else {
        setDisplayedPasswords(
          selectedTag === null
            ? hiddenValleyPasswords
            : hiddenValleyPasswords.filter((p) => selectedTag === p.key)
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hiddenValleyPasswords]);

  useEffect(() => {
    if (!hiddenValleyPasswords || hiddenValleyPasswords.length === 0) return;
    if (selectedTag === null) {
      setDisplayedPasswords(hiddenValleyPasswords);
      return;
    }
    setDisplayedPasswords(
      hiddenValleyPasswords.filter((p) => selectedTag === p.key)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTag]);

  return (
    <AppContent scrollable={true}>
      <div
        key={"interaction-tab"}
        className="mb-2 flex flex-row justify-between items-center gap-2"
      >
        <div className="flex flex-row gap-2">
          <CommonButton
            size="minion"
            theme="Primary"
            type="button"
            className="rounded-full transition-opacity"
            disabled={!hiddenValleyPasswords}
            style={{
              opacity: selectedTag === null ? 1 : 0.4,
              padding: "0.4rem 1.15rem",
            }}
            onClick={() => setSelectedTag(null)}
          >
            {trans("HIDDEN_VALLEY", "dashboard.tag-filter-default-all")}
          </CommonButton>
          {tags.map((t) => (
            <CommonButton
              key={t}
              size="minion"
              theme="Primary"
              type="button"
              className="rounded-full"
              disabled={!hiddenValleyPasswords}
              style={{
                opacity: selectedTag === t ? 1 : 0.4,
                padding: "0.4rem 1.15rem",
              }}
              onClick={() => setSelectedTag(t)}
            >
              #{t}
            </CommonButton>
          ))}
        </div>

        <CommonButton
          size="small"
          theme="Primary"
          className="rounded-lg"
          disabled={!hiddenValleyPasswords}
          type="button"
          onClick={handleOpenCreatePasswordForm}
        >
          +{" "}
          {trans("HIDDEN_VALLEY", "dashboard.short-create-new-password-title")}
        </CommonButton>
      </div>
      <AnimatePresence mode="wait">
        {!hiddenValleyPasswords ? (
          <CustomAnimateWrapper
            as="div"
            key={`password-loader`}
            variants={HiddenValleyHomeVariants.passwordLoader as any}
          >
            {Array(5)
              .fill(0)
              .map((_, index) => (
                <div
                  key={`password-loader@${index}`}
                  className="w-full h-14 bg-Primary rounded-lg mb-3"
                />
              ))}
          </CustomAnimateWrapper>
        ) : hiddenValleyPasswords.length > 0 ? (
          <HiddenValleyPasswordsList
            key={`password-list`}
            passwords={displayedPasswords}
          />
        ) : (
          <AnimateWrapper
            as="div"
            animateType="opacity"
            key={`password-empty`}
            className="w-full h-full flex flex-col justify-center items-center"
          >
            <div className="w-40 opacity-40">
              <EmptyBlueberry />
            </div>
            <ModernParagraph size="base" className="text-White mb-3">
              Bạn chưa lưu trữ mật khẩu nào cả
            </ModernParagraph>
            <CommonButton
              size="base"
              theme="Primary"
              onClick={handleOpenCreatePasswordForm}
              className="rounded-full hover:scale-x-110"
            >
              <ModernParagraph size="base" className="text-White">
                + Thêm mới
              </ModernParagraph>
            </CommonButton>
          </AnimateWrapper>
        )}
      </AnimatePresence>
      <Suspense>
        <AnimatePresence mode="wait">
          {isCreatingPassword ? (
            <CommonOverlayModal onClose={handleCloseCreatePasswordForm}>
              <HiddenValleyPasswordInteractionForm
                key={"password-form"}
                onSuccessSubmit={onCreateNewPassword}
                disabled={isLoading}
                onClose={handleCloseCreatePasswordForm}
              />
            </CommonOverlayModal>
          ) : null}
        </AnimatePresence>
      </Suspense>
    </AppContent>
  );
}

export default HiddenValleyHome;
