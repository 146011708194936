import { z } from "zod";

export interface IPreviewDiary {
  diaryId: string;
  title: string;
  description: string | null;
  createdAt: string;
  diaryImages: {
    diaryImageId: string;
    url: string;
  }[];
  diaryViews: number;
}

export const PreviewDiarySchema = z.object({
  diaryId: z.string(),
  title: z.string(),
  description: z.string().nullable(),
  createdAt: z.string(),
  diaryImages: z.array(
    z.object({
      diaryImageId: z.string(),
      url: z.string(),
    })
  ),
  diaryViews: z.number(),
});
