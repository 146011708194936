import { TokenSchema } from "@business-layer/services/entities";
import z from "zod";

const SPreLoginResponse = z.object({
  roleName: z.string(),
});
const SPreRegisterResponse = z.void();
const SLoginResponse = z.object({
  message: z.string(),
  token: TokenSchema,
  refreshToken: TokenSchema,
});
const SRegisterResponse = z.object({
  message: z.string(),
  token: TokenSchema,
  refreshToken: TokenSchema,
});
const SRefreshTokenResponse = z.object({
  message: z.string(),
  token: TokenSchema,
  refreshToken: TokenSchema,
});

export {
  SLoginResponse,
  SRefreshTokenResponse,
  SPreLoginResponse,
  SPreRegisterResponse,
  SRegisterResponse,
};
