import { TValidationTransKeys } from "@constants/lang.constants";
import * as y from "yup";

export const emailSchema = y.object({
  input: y
    .string()
    .typeError("common.email.typeError" as TValidationTransKeys)
    .required("common.email.required" as TValidationTransKeys)
    .email("common.email.typeError" as TValidationTransKeys)
    .transform((value) => value.trim()),
});
export const passwordSchema = y.object({
  input: y
    .string()
    .typeError("common.password.typeError" as TValidationTransKeys)
    .min(6, "common.password.min" as TValidationTransKeys)
    .transform((value) => value.trim()),
});
export const usernameSchema = y.object({
  input: y
    .string()
    .typeError("common.typeError" as TValidationTransKeys)
    .required("register.username.required" as TValidationTransKeys)
    .max(50, "register.username.max" as TValidationTransKeys)
    .transform((value) => value.trim()),
});
