import { HeaderLangSelectBtn, HeaderMusicBtn } from "@presentational/atoms";

export default function HiddenValleyIntroControlBar() {
  return (
    <ul className="relative flex flex-row items-start justify-end gap-4 h-full">
      <li className="pt-1">
        <HeaderLangSelectBtn />
      </li>
      <li className="pt-1">
        <HeaderMusicBtn />
      </li>
    </ul>
  );
}
