export const APP_URLS = {
  LOGIN: "/login",
  INTRO_HIDDEN_VALLEY: "/",
  DASHBOARD: "/your-home",
  HIDDEN_VALLEY: "/hidden-valley",
  DREAMING: "/dreaming",
  ACCOUNT: "/account",
};
export const PRIVATE_ROUTES = {
  HIDDEN_VALLEY: APP_URLS.HIDDEN_VALLEY,
  ACCOUNT: APP_URLS.ACCOUNT,
  DASHBOARD: APP_URLS.DASHBOARD,
};
export const PUBLIC_ROUTES = {
  LOGIN: APP_URLS.LOGIN,
  INTRO_HIDDEN_VALLEY: APP_URLS.INTRO_HIDDEN_VALLEY,
};
