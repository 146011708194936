import { BLUELYRA_ENDPOINTS } from "../../config/apis";
import { Services } from "../../service";
import { SearchYoutubeVideosResponseSchema } from "./schema";
import {
  TSearchYoutubeVideosParams,
  TSearchYoutubeVideosResponse,
} from "./type";

export * from "./type";
export class BluelyraService extends Services {
  private readonly ytDataV3APIKey =
    process.env.REACT_APP_YOUTUBE_DATA_API_V3_API_KEY;

  searchYoutubeVideos = async ({
    token,
    ...params
  }: TSearchYoutubeVideosParams): Promise<TSearchYoutubeVideosResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SearchYoutubeVideosResponseSchema,
          TSearchYoutubeVideosResponse
        >({
          ...BLUELYRA_ENDPOINTS.searchYoutubeVideos,
          isUseProxy: true,
          params: {
            ...params,
            key: this.ytDataV3APIKey,
          },
          schema: SearchYoutubeVideosResponseSchema,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
