import {
  AuthService,
  TLoginResponse,
  TLoginParams,
  TPreLoginResponse,
  TPreLoginParams,
  TPreRegisterParams,
  TPreRegisterResponse,
  TRegisterResponse,
  TRegisterParams,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

// Initialize the AuthService
const authService = new AuthService();

export const useLoginMutation = () => {
  return useMutation<TLoginResponse, Error, TLoginParams, unknown>({
    mutationFn: authService.login,
  });
};
export const useRegisterMutation = () => {
  return useMutation<TRegisterResponse, Error, TRegisterParams, unknown>({
    mutationFn: authService.register,
  });
};
export const usePreLoginMutation = () => {
  return useMutation<TPreLoginResponse, Error, TPreLoginParams, unknown>({
    mutationFn: authService.preLogin,
  });
};
export const usePreRegisterMutation = () => {
  return useMutation<TPreRegisterResponse, Error, TPreRegisterParams, unknown>({
    mutationFn: authService.preRegister,
  });
};
