import axiosLib, { isAxiosError, AxiosError } from "axios";

function isDevelopment(): boolean {
  return !process.env["NODE_ENV"] || process.env["NODE_ENV"] === "development";
}

const axiosInstance = axiosLib.create();

const isCancel = (error: any): boolean => {
  return axiosLib.isCancel(error);
};

type THttpMethod = "GET" | "POST" | "PUT" | "PATCH" | "DELETE" | "HEAD";
type TEndpoint = {
  method: THttpMethod;
  url: string;
};
export type TServiceEndpoints = { [key: string]: TEndpoint };

export {
  axiosInstance as client,
  AxiosError,
  isCancel,
  isAxiosError,
  isDevelopment,
};
