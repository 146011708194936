import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { THiddenValleyTransKeys } from "@constants/lang.constants";
import { ModernParagraph } from "@presentational/atoms";
import { COLOR_THEME } from "@presentational/themes/colors";
import { memo } from "react";

type TProps = {
  imgPublicPath: string;
  selectionName: THiddenValleyTransKeys;
  selectionKey: string;
  description: THiddenValleyTransKeys;
  isActive: boolean;
  onSelect: (selectionKey: any | null) => void;
  disabled?: boolean;
  gradient?: [string, string];
  gradientDirection?: string;
  isLock?: boolean;
};
function EncryptionChoice({
  imgPublicPath,
  selectionName,
  selectionKey,
  description,
  isActive,
  onSelect,
  disabled,
  gradient,
  gradientDirection,
  isLock,
}: TProps) {
  const { trans } = useLanguage();
  // const transDescription = trans("HIDDEN_VALLEY", description);
  function handleSelect() {
    !disabled && onSelect(isActive ? null : selectionKey);
  }
  return (
    <button
      type="button"
      disabled={disabled}
      className="py-3 w-36 flex flex-row items-center justify-center gap-1 whitespace-nowrap relative transition-all disabled:cursor-not-allowed"
      onClick={handleSelect}
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-Secondary border-solid border-[2px] rounded-lg z-0"
        style={{
          opacity: isActive ? 1 : 0.6,
          borderColor: isActive ? COLOR_THEME.White : COLOR_THEME.Secondary,
        }}
      ></div>
      <img src={imgPublicPath} alt="super-shield" className="w-4 h-4 z-10" />
      <ModernParagraph
        size="base"
        className="text-White leading-4 z-10"
        gradient={gradient}
        gradientDirection={gradientDirection}
      >
        {trans("HIDDEN_VALLEY", selectionName as any)}
      </ModernParagraph>
      {isLock ? (
        <i className="fi fi-sr-lock text-White absolute top-1 right-1 text-xs"></i>
      ) : null}

      {/* <i
          className="fi fi-sr-checkbox text-Sky text-sm absolute -top-1 -right-1 pointer-events-none transition-opacity"
          style={{ opacity: isActive ? 1 : 0 }}
        ></i> */}
    </button>
  );
}

export default memo(EncryptionChoice);
