import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { APP_INIT_ANIM_DELAY_DURATION } from "@constants/system.constants";
import {
  CommonButton,
  Typewriter,
  ModernParagraph,
  AnimateWrapper,
} from "@presentational/atoms";

export function MethodSelectChat({
  onSelect,
}: {
  onSelect: (method: any) => void;
}) {
  const { trans } = useLanguage();
  const greeting = [trans("AUTH", "greeting.which-methods-do-u-want")];

  function handleOpenLogin() {
    onSelect("login");
  }
  function handleOpenRegister() {
    onSelect("register");
  }

  return (
    <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full flex flex-col justify-end flex-grow pointer-events-auto">
      <AnimateWrapper
        as="div"
        animateType="opacity-riseUp"
        key={"login-greeting"}
        delay={APP_INIT_ANIM_DELAY_DURATION}
        className="flex flex-row gap-3 items-center justify-start pt-4"
      >
        <img
          src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
          alt="blueberry sensei"
          className="w-10 h-10 rounded-full shadow-[0px_0px_4px_3px_Primary] object-cover"
        />
        <ModernParagraph size="medium" className="text-White font-light">
          <Typewriter
            words={greeting}
            typeSpeed={20}
            infinite={false}
            // loop={1}
            cursor={false}
          />
        </ModernParagraph>
      </AnimateWrapper>
      <AnimateWrapper
        as="div"
        animateType="opacity-riseUp"
        delay={APP_INIT_ANIM_DELAY_DURATION + 0.1}
        key={`userInteractionChild_1`}
        className="flex flex-row items-center justify-end mb-2"
      >
        <CommonButton
          className="rounded-full hover:!bg-Sky hover:!text-Dark"
          size="medium"
          theme="Primary"
          onClick={handleOpenLogin}
        >
          {trans("AUTH", "greeting.login-method")}
        </CommonButton>
      </AnimateWrapper>
      <AnimateWrapper
        as="div"
        animateType="opacity-riseUp"
        delay={APP_INIT_ANIM_DELAY_DURATION + 0.2}
        key={`userInteractionChild_2`}
        className="flex flex-row items-center justify-end mb-2"
      >
        <CommonButton
          className="rounded-full hover:!bg-Pink"
          size="medium"
          theme="DeepPink"
          onClick={handleOpenRegister}
        >
          {trans("AUTH", "greeting.register-method")}
        </CommonButton>
      </AnimateWrapper>
      {/* <motion.div
        key={`userInteractionChild_3`}
        initial={{ opacity: 0, y: 100 }}
        animate={{
          opacity: 1,
          y: 0,
          transition: { duration: 0.5, ease: "easeInOut", delay: 2 },
        }}
        exit={{
          opacity: 0,
          y: -200,
          transition: { duration: 0.3, ease: "easeInOut" },
        }}
        className="flex flex-row items-center justify-end mb-2"
      >
        <CommonButton
          className="rounded-full hover:!bg-Gray"
          size="medium"
          theme="White"
          onClick={handleOpenGoogleLogin}
        >
          <img
            src="/assets/imgs/icons/google.png"
            alt="Google"
            className="w-4 h-4 mr-2"
          />
          {trans("AUTH", "google-login-method")}
        </CommonButton>
      </motion.div> */}
    </div>
  );
}
