import { AnimateWrapper } from "@presentational/atoms";

export default function AppContent({
  children,
  className = "",
  style = {},
  scrollable = false,
}: {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
  scrollable?: boolean;
}) {
  const defaultStyle: React.CSSProperties = {
    ...style,
    overflowY: scrollable ? "auto" : "hidden",
  };
  return (
    <AnimateWrapper
      className={`${className} no-scrollbar w-full h-screen pt-4 pb-4 overflow-x-hidden`}
      as="main"
      key="AppContent"
      animateType="opacity"
      style={defaultStyle}
    >
      {children}
    </AnimateWrapper>
  );
}
