import { useSelector } from "react-redux";
import { TBluelyraContextData } from "../context";
import { useMemo } from "react";

export const useGetBluelyraProfile = () => {
  const profile = useSelector<
    TBluelyraContextData,
    TBluelyraContextData["profile"]
  >((state) => state.profile);
  return useMemo(() => profile, [profile]);
};
