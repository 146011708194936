function EmptyBlueberry() {
  return (
    <img
      src="/assets/imgs/icons/empty.png"
      alt="empty"
      className="w-full h-auto aspect-square object-cover"
    />
  );
}
export default EmptyBlueberry;
