import { useQuery } from "@tanstack/react-query";
import { USE_QUERY_KEYS } from "@business-layer/business-logic/configs";
import { AccountService } from "@business-layer/services";

const service = new AccountService();

export const useFetchAccountDetailQuery = (token: string | null) => {
  return useQuery({
    queryKey: [USE_QUERY_KEYS.GET_ACCOUNT_DETAIL, token],
    queryFn: () => service.getAccountDetail({ token }),
    staleTime: Infinity,
    gcTime: Infinity,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
};
