import { TValidationTransKeys } from "@constants/lang.constants";
import * as y from "yup";

export const SearchYoutubeFormSchema = y.object({
  keys: y
    .string()
    .typeError("common.typeError" as TValidationTransKeys)
    .required("search-youtube-form.keys.required" as TValidationTransKeys)
    .max(300, "search-youtube-form.keys.max" as TValidationTransKeys)
    .transform((value) => value.trim()),
});
