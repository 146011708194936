import { TValidationTransKeys } from "@constants/lang.constants";
import * as y from "yup";
export const CreateNewHiddenValleyPasswordSchema = y.object({
  name: y
    .string()
    .required(
      "hidden-valley-new-psw-form.name.required" as TValidationTransKeys
    )
    .max(100, "hidden-valley-new-psw-form.name.max" as TValidationTransKeys)
    .transform((value) => value.trim()),
  password: y
    .string()
    .required(
      "hidden-valley-new-psw-form.password.required" as TValidationTransKeys
    )
    .max(255, "hidden-valley-new-psw-form.password.max" as TValidationTransKeys)
    .transform((value) => value.trim()),
  key: y
    .string()
    .max(50, "hidden-valley-new-psw-form.key.max" as TValidationTransKeys)
    .transform((value) => value.trim()),
});
