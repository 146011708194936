import { useDispatch } from "react-redux";
import { setBluelyraVolume } from "../context";

export const useChangeBluelyraVolume = () => {
  const dispatch = useDispatch();
  function onChange(newVolume: number) {
    dispatch(setBluelyraVolume(newVolume));
  }
  return {
    onChange,
  };
};
