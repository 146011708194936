import { TLang } from "@constants/lang.constants";
import { COLOR_THEME } from "@presentational/themes/colors";
import { getHeadingFontConfig } from "@utils/helpers/font.helper";
import React, { forwardRef, memo, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { DotSpinnerLoader } from "@presentational/atoms/loader";

type TSize = "minion" | "small" | "base" | "medium";
type TTheme =
  | "Dark"
  | "Primary"
  | "DeepPink"
  | "Pink"
  | "White"
  | "Secondary"
  | "Zinc";
export type TCommonButtonProps = {
  children: React.ReactNode;
  className?: string;
  size: TSize;
  theme: TTheme;
  onClick?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  type?: "button" | "reset" | "submit";
  style?: React.CSSProperties;
};

function getFontSize(size: TSize): string {
  switch (size) {
    case "minion":
      return "0.65rem";
    case "small":
      return "0.75rem";
    case "base":
      return "0.875rem";
    case "medium":
      return "1rem";
    default:
      return "inherit";
  }
}
function getColor(theme: TTheme) {
  switch (theme) {
    case "Pink":
      return {
        backgroundColor: COLOR_THEME.Pink,
        color: COLOR_THEME.White,
      };
    case "Primary":
      return {
        backgroundColor: COLOR_THEME.Primary,
        color: COLOR_THEME.White,
      };
    case "DeepPink":
      return {
        backgroundColor: COLOR_THEME.DeepPink,
        color: COLOR_THEME.White,
      };
    case "Zinc":
      return {
        backgroundColor: COLOR_THEME.Zinc,
        color: COLOR_THEME.Dark,
      };
    case "Dark":
      return {
        backgroundColor: COLOR_THEME.Dark,
        color: COLOR_THEME.White,
      };
    case "White":
      return {
        backgroundColor: COLOR_THEME.White,
        color: COLOR_THEME.Dark,
      };
    case "Secondary":
      return {
        backgroundColor: COLOR_THEME.Zinc,
        color: COLOR_THEME.Dark,
      };
    default:
      return {
        backgroundColor: COLOR_THEME.Dark,
        color: COLOR_THEME.White,
      };
  }
}
export const CommonButton = forwardRef<HTMLButtonElement, TCommonButtonProps>(
  (
    {
      children,
      className,
      size,
      theme,
      onClick,
      disabled = false,
      isLoading = false,
      type = "button",
      style = {},
    },
    ref
  ) => {
    const { i18n } = useTranslation();
    const defaultStyle = {
      ...getHeadingFontConfig(i18n.language as TLang),
      fontSize: getFontSize(size),
      padding: "10px 30px",
      ...getColor(theme),
      transition: "0.2s all ease-in-out",
      ...style,
    };
    const loading = useMemo(
      () =>
        isLoading ? (
          <span className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
            <DotSpinnerLoader size="0.75rem" color="White" />
          </span>
        ) : null,
      [isLoading]
    );

    return (
      <button
        ref={ref}
        className={`${className} relative disabled:!cursor-not-allowed disabled:!bg-Gray disabled:!text-Dark transition-all`}
        style={defaultStyle}
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        <div
          style={{
            opacity: isLoading ? 0 : 1,
          }}
          className="flex-row flex items-center justify-center"
        >
          {children}
        </div>
        {loading}
      </button>
    );
  }
);
export default memo(CommonButton);
