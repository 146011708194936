import { useDispatch } from "react-redux";
import { useFetchAccountDetailQuery } from "../../fetching/query";
import { useEffect } from "react";
import { setAccountDetail } from "../context";
import { useGetToken } from "@business-layer/business-logic/lib/auth";

export const useFetchAccountDetail = () => {
  const token = useGetToken();
  const { refetch, data, isLoading } = useFetchAccountDetailQuery(token);
  const dispatch = useDispatch();

  useEffect(() => {
    if (data) {
      dispatch(setAccountDetail(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { refetch, data, isLoading };
};
