import {
  HiddenValleyService,
  TAddNewPasswordParams,
  TAddNewPasswordResponse,
  TDeletePasswordParams,
  TDeletePasswordResponse,
  TGetAllPasswordsParams,
  TGetAllPasswordsResponse,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

const service = new HiddenValleyService();

export const useGetAllHiddenValleyPasswordMutation = () => {
  return useMutation<
    TGetAllPasswordsResponse,
    Error,
    TGetAllPasswordsParams,
    unknown
  >({
    mutationFn: service.getAllPasswords,
  });
};
export const useAddNewHiddenValleyPasswordMutation = () => {
  return useMutation<
    TAddNewPasswordResponse,
    Error,
    TAddNewPasswordParams,
    unknown
  >({
    mutationFn: service.addNewPassword,
  });
};
export const useDeleteHiddenValleyPasswordMutation = () => {
  return useMutation<
    TDeletePasswordResponse,
    Error,
    TDeletePasswordParams,
    unknown
  >({
    mutationFn: service.deletePassword,
  });
};
