import { AxiosError, AxiosResponse } from "axios";
import { client } from "../config/axios";
import { getCookie, setCookie } from "@utils/helpers";
import { COOKIE_KEYS } from "@business-layer/business-logic/configs";
import { AuthService } from "../lib";
import { IToken } from "../entities";
import { activeMiddlewares } from "../config/middlewares";

let isRefreshing = false;
let refreshSubscribers: Array<(token: string) => void> = [];

function onRefreshed(token: string) {
  refreshSubscribers.map((callback) => callback(token));
  refreshSubscribers = [];
}

function addRefreshSubscriber(callback: (token: string) => void) {
  refreshSubscribers.push(callback);
}

export function enableRefreshTokenMiddleware(
  onLogout: () => void,
  setToken: (token: IToken) => void,
  setRefreshToken: (token: IToken) => void
) {
  if (activeMiddlewares.refresh_token) {
    client.interceptors.response.use(
      (response: AxiosResponse) => response,
      async (error: AxiosError) => {
        const originalRequest: any = error.config;

        if (
          error.response?.status === 401 &&
          (error.response?.data as any)?.message === "EXPIRED_TOKEN"
        ) {
          if (!isRefreshing) {
            isRefreshing = true;
            const rt = await getCookie(COOKIE_KEYS.REFRESH_TOKEN);

            if (rt) {
              try {
                const { token, refreshToken } =
                  await new AuthService().refreshToken(rt);
                setToken(token);
                setRefreshToken(refreshToken);
                setCookie(COOKIE_KEYS.ACCESS_TOKEN, token.value);
                setCookie(COOKIE_KEYS.REFRESH_TOKEN, refreshToken.value);
                isRefreshing = false;
                onRefreshed(token.value);

                // Retry original request with new token
                if (originalRequest.headers) {
                  originalRequest.headers.Authorization = `Bearer ${token.value}`;
                }
                return client(originalRequest);
              } catch (err) {
                onLogout();
                isRefreshing = false;
                return Promise.reject(err);
              }
            } else {
              onLogout();
              isRefreshing = false;
              return Promise.reject(error);
            }
          }

          return new Promise((resolve) => {
            addRefreshSubscriber((token: string) => {
              if (originalRequest.headers) {
                originalRequest.headers.Authorization = `Bearer ${token}`;
              }
              resolve(client(originalRequest));
            });
          });
        }

        return Promise.reject(error);
      }
    );
  }
}
