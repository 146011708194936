import { PUBLIC_ROUTES } from "@constants/urls.constants";
import { AppTemplate } from "@presentational/templates";
import { Navigate, Outlet } from "react-router-dom";

export function PrivateRoute(props: {
  isLogged: boolean;
  currentLang: string;
}) {
  return props.isLogged ? (
    <AppTemplate>
      <Outlet />
    </AppTemplate>
  ) : (
    <Navigate to={`/${props.currentLang}${PUBLIC_ROUTES.LOGIN}`} />
  );
}
