import {
  APP_URLS,
  PRIVATE_ROUTES,
  PUBLIC_ROUTES,
} from "../constants/urls.constants";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  // IntroHiddenValley,
  Login,
  HiddenValleyHome,
  Account,
  Dashboard,
} from "../pages";
import { Navigate } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { getLocalesRoutePath } from "@utils/helpers/route.helper";
import { useIsLogged } from "@business-layer/business-logic/lib/auth";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

function Router() {
  const isLogged = useIsLogged();
  const { currentLang } = useLanguage();
  return (
    <BrowserRouter>
      <Routes>
        {/* NO LOCALES ROUTES */}
        {Object.values(APP_URLS).map((path, index) => (
          <Route
            path={`${path}`}
            key={`default_path#${index}`}
            element={<Navigate to={`/${currentLang}${path}`} />}
          />
        ))}
        {/* PUBLIC ROUTES */}
        <Route
          path={getLocalesRoutePath(PUBLIC_ROUTES.LOGIN)}
          element={
            isLogged ? (
              <Navigate to={`/${currentLang}${PRIVATE_ROUTES.DASHBOARD}`} />
            ) : (
              <Login />
            )
          }
        />
        {/* <Route
          path={getLocalesRoutePath(PUBLIC_ROUTES.INTRO_HIDDEN_VALLEY)}
          element={<IntroHiddenValley />}
        /> */}
        {/* PRIVATE ROUTES */}
        <Route
          element={
            <PrivateRoute isLogged={isLogged} currentLang={currentLang} />
          }
        >
          <Route
            path={getLocalesRoutePath(PRIVATE_ROUTES.DASHBOARD)}
            element={<Dashboard key={"Dashboard"} />}
          />
          <Route
            path={getLocalesRoutePath(PRIVATE_ROUTES.HIDDEN_VALLEY)}
            element={<HiddenValleyHome key={"HiddenValleyHome"} />}
          />

          <Route
            path={getLocalesRoutePath(PRIVATE_ROUTES.ACCOUNT)}
            element={<Account key={"Account"} />}
          />
        </Route>
        {/* Not found */}
        <Route
          path={getLocalesRoutePath(PUBLIC_ROUTES.INTRO_HIDDEN_VALLEY)}
          element={
            <Navigate to={`/${currentLang}${PRIVATE_ROUTES.DASHBOARD}`} />
          }
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Router;
