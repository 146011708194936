import { COLOR_THEME } from "@presentational/themes/colors";
import { LANG_NS, ALL_LANG_TRANSLATIONS } from "./lang.constants";
import { EPlanName } from "@business-layer/services/entities";

const PLANS_TRANS_DEFAULT_NS = "COMMON";
export const USER_PLANS: {
  [key in EPlanName]: {
    img: string;
    ns: keyof typeof LANG_NS;
    name: ALL_LANG_TRANSLATIONS["COMMON"];
    bgColor: [string, string] | string;
    textColor: string;
  };
} = {
  Novice: {
    img: "/assets/imgs/plan/novice_plan.png",
    ns: PLANS_TRANS_DEFAULT_NS,
    name: "user-plan.novice",
    bgColor: COLOR_THEME.Dark,
    textColor: "#6ED9D6",
  },
  Elite: {
    img: "/assets/imgs/plan/elite_plan.png",
    ns: PLANS_TRANS_DEFAULT_NS,
    name: "user-plan.elite",
    bgColor: ["#5CE5FF", "#FCFFFF"],
    textColor: COLOR_THEME.Dark,
  },
  Legendary: {
    img: "/assets/imgs/plan/legendary_plan.png",
    ns: PLANS_TRANS_DEFAULT_NS,
    name: "user-plan.legendary",
    bgColor: ["#DEA63B", "#FBF7BF"],
    textColor: COLOR_THEME.Dark,
  },
};
