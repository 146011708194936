import { THiddenValleyTransKeys } from "./lang.constants";

export type THiddenValleyEncryptionMethod = "Standard" | "Superd";
export const ENCRYPT_METHODS: {
  [key in THiddenValleyEncryptionMethod]: {
    imgPublicPath: string;
    selectionName: THiddenValleyTransKeys;
    selectionKey: THiddenValleyEncryptionMethod;
    description: THiddenValleyTransKeys;
    gradient?: [string, string];
    gradientDirection?: string;
  };
} = {
  Standard: {
    imgPublicPath: "/assets/imgs/icons/med-shield.png",
    selectionName: "dashboard.encrypt-standard-name",
    selectionKey: "Standard",
    description: "dashboard.encrypt-standard-description",
    gradient: ["#75452E", "#FBE9D8"],
    gradientDirection: "to right",
  },
  Superd: {
    imgPublicPath: "/assets/imgs/icons/super-shield.png",
    selectionName: "dashboard.encrypt-super-name",
    selectionKey: "Superd",
    description: "dashboard.encrypt-super-description",
    gradient: ["#4FA3E6", "#EF5161"],
    gradientDirection: "to right",
  },
};
