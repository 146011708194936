import { forwardRef, memo } from "react";
import { motion } from "framer-motion";
import { TAnimateVariants } from "./type";

type MotionComponentProps<Tag extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[Tag] & {
    as: Tag;
    delay?: number;
    variants: TAnimateVariants;
    key: string;
    isLayoutAnimation?: boolean;
  };

const CustomAnimateWrapper = forwardRef(
  <Tag extends keyof JSX.IntrinsicElements>(
    {
      children,
      as,
      delay = 0,
      variants,
      isLayoutAnimation,
      ...motionProps
    }: MotionComponentProps<Tag>,
    ref: React.Ref<HTMLElement>
  ) => {
    const Component = motion[as as keyof typeof motion];

    return (
      <Component
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        layout={isLayoutAnimation}
        {...motionProps}
      >
        {children}
      </Component>
    );
  }
);

export default memo(CustomAnimateWrapper) as typeof CustomAnimateWrapper;
