// Importing necessary modules and functions
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useAccessToken } from "./useAccessToken";
import { useRegisterMutation } from "../../fetching/mutation";
import { TRegisterParams } from "@business-layer/services";
import { useHandleRefreshToken } from "./useHandleRefreshToken";

export const useRegister = () => {
  const { trans } = useLanguage();
  const { setToken } = useAccessToken();
  const { setRefreshToken } = useHandleRefreshToken();
  const { mutateAsync, isPending } = useRegisterMutation();

  const onRegister = async (props: TRegisterParams): Promise<string> => {
    try {
      try {
        const res = await mutateAsync(props);
        setToken({ ...res.token, expires: res.refreshToken.expires });
        setRefreshToken(res.refreshToken);
        return trans("SERVER", res.message as any);
      } catch (error: any) {
        throw new Error(trans("SERVER", error.message));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    onRegister,
    isLoading: isPending,
  };
};
