import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import {
  ENCRYPT_METHODS,
  THiddenValleyEncryptionMethod,
} from "@constants/encrypt.constants";
import { HIDDEN_VALLEY_PSW_ICONS } from "@constants/icon.constants";
import {
  Heading,
  ModernParagraph,
  CommonButton,
  IconBtn,
  CommonBtnWithPopover,
} from "@presentational/atoms";
import { CommonInput } from "@presentational/atoms/input";
import { EncryptionChoice } from "@presentational/molecules";
import { COLOR_THEME } from "@presentational/themes/colors";
import {
  CreateNewHiddenValleyPasswordSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { useState, useCallback, memo } from "react";
import { useForm } from "react-hook-form";

type THiddenValleyPasswordInteractionForm = {
  name: string;
  password: string;
  key: string;
};
export type TSuccessSubmitData = THiddenValleyPasswordInteractionForm & {
  selectedEncryptMethod: THiddenValleyEncryptionMethod;
  selectedIcon: string | null;
};
type TProps = {
  onSuccessSubmit: (data: TSuccessSubmitData) => void;
  disabled?: boolean;
  onClose: () => void;
};
function HiddenValleyPasswordInteractionForm({
  onSuccessSubmit,
  disabled,
  onClose,
}: TProps) {
  const [selectedEncryptMethod, setSelectedEncryptMethod] =
    useState<THiddenValleyEncryptionMethod | null>(null);
  const [selectedIcon, setSelectedIcon] = useState<string | null>(null);
  const { trans } = useLanguage();
  const { toastReactHookFormErrorMsg } = useNotification();
  const resolver = useYupValidationResolver(
    CreateNewHiddenValleyPasswordSchema
  );
  const { register, handleSubmit, reset, watch } =
    useForm<THiddenValleyPasswordInteractionForm>({
      defaultValues: {
        name: "",
        password: "",
        key: "",
      },
      resolver,
    });
  const handleSelectEncryptMethod = useCallback(
    (selectionKey: THiddenValleyEncryptionMethod | null) => {
      setSelectedEncryptMethod(selectionKey);
    },
    []
  );
  function renderIcon(ico: { publicPath: string; key: string }) {
    const { keyWillSelect, isActive, ...config } =
      selectedIcon === ico.key
        ? {
            backgroundColor: COLOR_THEME.Primary,
            opacity: 1,
            keyWillSelect: null,
            isActive: true,
          }
        : {
            backgroundColor: COLOR_THEME.Transparent,
            opacity: 0.7,
            keyWillSelect: ico.key,
            isActive: false,
          };
    return (
      <button
        type="button"
        key={ico.key}
        className="hover:!opacity-100 transition-all w-9 h-9 rounded-lg grid place-items-center relative disabled:cursor-not-allowed disabled:!opacity-70"
        style={config}
        onClick={() => !disabled && setSelectedIcon(keyWillSelect)}
        disabled={disabled}
      >
        <img src={ico.publicPath} alt={ico.key} className="w-7 h-7" />
        <i
          className="fi fi-sr-checkbox text-Sky text-sm absolute -top-1 -right-1 pointer-events-none transition-opacity"
          style={{ opacity: isActive ? 1 : 0 }}
        ></i>
      </button>
    );
  }
  function onValid(data: THiddenValleyPasswordInteractionForm) {
    if (disabled) {
      return;
    }
    if (!selectedEncryptMethod) {
      return;
    }
    onSuccessSubmit({ ...data, selectedEncryptMethod, selectedIcon });
    reset();
    setSelectedEncryptMethod(null);
    setSelectedIcon(null);
  }
  return (
    <form
      key={"hidden-valley-create-password-form"}
      className="overflow-y-auto flex flex-col justify-between relative z-10 rounded-xl w-[clamp(300px,50vw,800px)]"
      autoComplete="off"
      onSubmit={handleSubmit(onValid, toastReactHookFormErrorMsg)}
    >
      <div className="flex flex-col gap-3 overflow-y-auto no-scrollbar flex-grow text-White">
        <Heading as="h3" className="text-center">
          {trans("HIDDEN_VALLEY", "dashboard.create-new-password-title")}
        </Heading>
        <CommonInput
          register={register}
          watcher={watch}
          name="name"
          maxLength={255}
          disabled={disabled}
          label={trans("HIDDEN_VALLEY", "dashboard.input-label-name")}
          placeholder={trans(
            "HIDDEN_VALLEY",
            "dashboard.input-placeholder-name"
          )}
        />
        <CommonInput
          register={register}
          watcher={watch}
          label={trans("HIDDEN_VALLEY", "dashboard.input-label-password")}
          name="password"
          placeholder={trans(
            "HIDDEN_VALLEY",
            "dashboard.input-placeholder-password"
          )}
          maxLength={255}
          type="password"
          disabled={disabled}
        />
        <ModernParagraph size="base" className="text-White">
          {trans("HIDDEN_VALLEY", "dashboard.input-label-encrypt")}
        </ModernParagraph>
        <div className="flex flex-row gap-3">
          {Object.values(ENCRYPT_METHODS).map((method) => (
            <EncryptionChoice
              {...method}
              isActive={selectedEncryptMethod === method.selectionKey}
              onSelect={handleSelectEncryptMethod}
              disabled={disabled}
              key={method.selectionKey}
            />
          ))}
        </div>
        <CommonInput
          register={register}
          watcher={watch}
          label={trans("HIDDEN_VALLEY", "dashboard.input-label-key")}
          name="key"
          placeholder={trans(
            "HIDDEN_VALLEY",
            "dashboard.input-placeholder-key"
          )}
          maxLength={20}
          disabled={disabled}
        />
        <div className="w-full flex flex-row flex-wrap justify-between items-center">
          <ModernParagraph size="base" className="text-White">
            {trans("HIDDEN_VALLEY", "dashboard.input-label-icon")}
          </ModernParagraph>
          <CommonBtnWithPopover
            position="top-left"
            size="small"
            theme="Pink"
            className="rounded-lg !py-1 !px-4"
            type="button"
            disabled={disabled}
            popoverChildren={
              <div className="grid grid-cols-5 gap-4 p-3 w-[15.25rem]">
                <div className="col-span-5">
                  <ModernParagraph size="base" className="text-White">
                    {trans(
                      "HIDDEN_VALLEY",
                      "dashboard.input-icon-popover-title"
                    )}
                  </ModernParagraph>
                </div>
                {Object.values(HIDDEN_VALLEY_PSW_ICONS).map(renderIcon)}
              </div>
            }
            popoverBgColor="LightDark"
          >
            + {trans("HIDDEN_VALLEY", "dashboard.input-icon-add-btn-label")}
          </CommonBtnWithPopover>
        </div>
        {selectedIcon ? (
          <div className="flex flex-row gap-3 items-center">
            <img
              src={
                HIDDEN_VALLEY_PSW_ICONS[
                  selectedIcon as keyof typeof HIDDEN_VALLEY_PSW_ICONS
                ].publicPath
              }
              alt={
                HIDDEN_VALLEY_PSW_ICONS[
                  selectedIcon as keyof typeof HIDDEN_VALLEY_PSW_ICONS
                ].key
              }
              className="w-4 h-4"
            />
            <ModernParagraph size="small" weight="200" className="text-White">
              #{selectedIcon}
            </ModernParagraph>
            <IconBtn
              iconClassName={"fi-sr-trash-xmark text-Red"}
              size={"small"}
              type={"button"}
              onClick={() => setSelectedIcon(null)}
            />
          </div>
        ) : null}
      </div>
      <div className="flex flex-row justify-center pt-4 gap-3">
        <CommonButton
          className="rounded-lg"
          size="base"
          theme="Zinc"
          disabled={disabled}
          type="button"
          onClick={onClose}
        >
          {trans("COMMON", "cancel-title")}
        </CommonButton>
        <CommonButton
          className="rounded-lg"
          size="base"
          theme="Pink"
          isLoading={disabled}
          type="submit"
        >
          {trans("HIDDEN_VALLEY", "dashboard.confirm-create-btn-label")}
        </CommonButton>
      </div>
    </form>
  );
}

export default memo(HiddenValleyPasswordInteractionForm);
