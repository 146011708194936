import { Variants } from "framer-motion";

export const HiddenValleyHomeVariants: { [key: string]: Variants } = {
  passwordLoader: {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
      transition: {
        repeat: Infinity,
        repeatType: "reverse",
        duration: 0.5,
        ease: "linear",
      },
    },
    exit: {
      opacity: 0,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  },
};
