import {
  AnimateWrapper,
  CommonButton,
  DotSpinnerLoader,
  Heading,
  ModernParagraph,
} from "@presentational/atoms";
import { useLogout } from "@business-layer/business-logic/lib/auth";
import {
  useLanguage,
  useNavigateWithCurrentLang,
} from "@business-layer/business-logic/lib/lang";
import { APP_URLS } from "@constants/urls.constants";
import { useAccountDetail } from "@business-layer/business-logic/lib/account";
import { formatDate } from "@utils/helpers/datetime.helper";
import { USER_PLANS } from "@constants/plan.constants";
import { lazy, Suspense, useMemo, useState } from "react";
import { EPlanName } from "@business-layer/services/entities";
import { ALL_LANG_TRANSLATIONS } from "@constants/lang.constants";
import { COLOR_THEME } from "@presentational/themes/colors";
import { AppContent } from "@presentational/templates";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import { AnimatePresence } from "framer-motion";
import { INITIAL_ANIM_DELAY } from "@constants/system.constants";

const DiariesTab = lazy(
  () => import("@presentational/organisms/accountTabs/DiariesTab")
);
const FriendsTab = lazy(
  () => import("@presentational/organisms/accountTabs/FriendsTab")
);
const EditProfileForm = lazy(
  () => import("@presentational/organisms/form/EditProfileForm")
);
const EditProfileModal = lazy(
  () => import("@presentational/molecules/overlay/CommonOverlayModal")
);

type TTabs = "diaries" | "commission" | "friends";
const TABS: {
  name: ALL_LANG_TRANSLATIONS["COMMON"];
  key: TTabs;
}[] = [
  { name: "account-page.tabs.diaries", key: "diaries" },
  { name: "account-page.tabs.friends", key: "friends" },
  // { name: "account-page.tabs.commission", key: "commission" },
];

function Account() {
  const { data: accountDetail } = useAccountDetail();
  const { onLogout } = useLogout();
  const { handleNavigate } = useNavigateWithCurrentLang();
  const { trans, currentLang } = useLanguage();
  const { toastInfo } = useNotification();
  const [selectedTab, setSelectedTab] = useState<TTabs>(TABS[0].key);
  const [isShowEditProfileModal, setIsShowEditProfileModal] =
    useState<boolean>(false);

  const SYSTEM_ACTIONS: {
    label: ALL_LANG_TRANSLATIONS["COMMON"];
    icon: string;
    action: () => void;
  }[] = useMemo(
    () => [
      {
        label: "account-page.system-action.member-pack",
        icon: "fi-ss-flame",
        action: () => {
          toastInfo(trans("COMMON", "notification.info-coming-soon"));
        },
      },
      {
        label: "account-page.system-action.security",
        icon: "fi-ss-shield-check",
        action: () => {
          toastInfo(trans("COMMON", "notification.info-coming-soon"));
        },
      },
      {
        label: "account-page.system-action.help-center",
        icon: "fi-ss-headset",
        action: () => {
          toastInfo(trans("COMMON", "notification.info-coming-soon"));
        },
      },
      {
        label: "account-page.system-action.feedback",
        icon: "fi-ss-heart",
        action: () => {
          toastInfo(trans("COMMON", "notification.info-coming-soon"));
        },
      },
      {
        label: "account-page.system-action.logout",
        icon: "fi-ss-undo",
        action: handleLogout,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentLang]
  );

  function handleLogout() {
    onLogout();
    handleNavigate(APP_URLS.INTRO_HIDDEN_VALLEY);
  }

  if (accountDetail) {
    const userPlan =
      USER_PLANS[
        Object.keys(USER_PLANS).includes(accountDetail.plan.name)
          ? accountDetail.plan.name
          : EPlanName.Novice
      ];
    // const userPlan = USER_PLANS[EPlanName.Legendary];
    return (
      <>
        <div className="grid grid-cols-[11rem,1fr] gap-3 overflow-hidden">
          <AppContent
            className="col-span-1 flex flex-col gap-2"
            scrollable={true}
          >
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY}
              animateType="opacity-lightScale"
              key="account-avatar"
            >
              <img
                src={accountDetail.avatar}
                width={500}
                height={500}
                className="w-full h-auto aspect-square rounded-[2rem] object-cover border-[4px] border-solid"
                style={{
                  borderColor: Array.isArray(userPlan.bgColor)
                    ? userPlan.bgColor[0]
                    : COLOR_THEME.Primary,
                }}
                alt={accountDetail.username}
              />
            </AnimateWrapper>
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY + 0.1}
              animateType="opacity-lightScale"
              key="account-plan"
              className="w-full h-40 min-h-40 rounded-xl grid place-items-center bg-Primary relative"
            >
              <img
                src={userPlan.img}
                alt={accountDetail.plan.name}
                className="w-1/2 h-auto aspect-square object-cover"
                width={200}
                height={200}
              />
              <div className="w-[70%] min-w-[fit-content] h-fit absolute top-0 left-1/2 transform -translate-x-1/2 rounded-b-[1.5rem] overflow-hidden bg-Secondary p-[0.25rem] pt-0">
                <div
                  className="px-6 py-1 w-full rounded-b-[1.25rem]"
                  style={
                    Array.isArray(userPlan.bgColor)
                      ? {
                          background: `linear-gradient(to right, ${userPlan.bgColor[0]}, ${userPlan.bgColor[1]})`,
                          color: userPlan.textColor,
                        }
                      : {
                          backgroundColor: userPlan.bgColor,
                          color: userPlan.textColor,
                        }
                  }
                >
                  <ModernParagraph
                    size="small"
                    className="whitespace-nowrap line-clamp-1 text-center"
                    weight="700"
                  >
                    {trans(userPlan.ns, userPlan.name)}
                  </ModernParagraph>
                </div>
              </div>
            </AnimateWrapper>
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY + 0.2}
              animateType="opacity-lightScale"
              key="account-sidebar"
              className="w-full flex flex-col justify-between bg-Primary rounded-xl text-White px-6 pt-3 pb-5 gap-3"
            >
              <div className="flex-grow mb-4">
                <ModernParagraph size="base">
                  {trans("COMMON", "account-page.others.medal")}
                </ModernParagraph>
                <div className="flex flex-row flex-wrap justify-start items-start gap-3 mt-2">
                  {accountDetail.medals.map((m) => (
                    <img
                      key={m.medalId}
                      src={m.medalUrl}
                      alt={m.medalName}
                      className="w-8 h-8"
                    />
                  ))}
                </div>
              </div>
              <div className="flex-shrink">
                <ModernParagraph size="base">
                  {trans("COMMON", "account-page.others.system")}
                </ModernParagraph>
                <div className="flex flex-col gap-2 mt-3">
                  {SYSTEM_ACTIONS.map((sa) => (
                    <button
                      key={sa.label}
                      type="button"
                      className="flex flex-row gap-3 items-center text-White opacity-50 hover:opacity-100 transition-opacity"
                      onClick={sa.action}
                    >
                      <i className={`fi ${sa.icon} bottom-0 text-xs`}></i>
                      <ModernParagraph size="small" weight="200">
                        {trans("COMMON", sa.label)}
                      </ModernParagraph>
                    </button>
                  ))}
                </div>
              </div>
            </AnimateWrapper>
          </AppContent>
          <AppContent
            className="col-span-1 flex flex-col gap-2"
            scrollable={true}
          >
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY + 0.3}
              animateType="opacity-lightScale"
              key="account-detail-card"
              className="bg-Primary h-fit min-h-44 w-full overflow-hidden rounded-[1.5rem] px-7 py-3 text-White"
            >
              <div className="flex flex-row gap-2 justify-between items-center">
                <Heading as="h2" className="line-clamp-1">
                  @ {accountDetail.username}
                </Heading>
                <button
                  type="button"
                  onClick={() => setIsShowEditProfileModal(true)}
                >
                  <i className="fi fi-sr-select"></i>
                </button>
              </div>
              <ModernParagraph
                size="small"
                className="line-clamp-1 mt-1 opacity-60"
                weight="200"
              >
                {trans("COMMON", "account-page.others.account-create-at")}{" "}
                {formatDate(accountDetail.createdAt).dateMonthYear}
              </ModernParagraph>
              <ModernParagraph size="base" className="mt-2 line-clamp-3">
                {!accountDetail.description ||
                accountDetail.description.length === 0
                  ? trans("COMMON", "account-page.others.no-description")
                  : accountDetail.description}
              </ModernParagraph>
            </AnimateWrapper>
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY + 0.4}
              animateType="opacity-lightScale"
              key="account-tabs-navigator"
              className="flex flex-row gap-2"
            >
              {TABS.map(({ name, key }) => (
                <CommonButton
                  type="button"
                  key={key}
                  size="small"
                  theme="Primary"
                  className="bg-Primary rounded-full text-White transition-opacity hover:!opacity-100"
                  style={{
                    opacity: key === selectedTab ? 1 : 0.5,
                    paddingTop: "0.25rem",
                    paddingBottom: "0.25rem",
                  }}
                  onClick={() => setSelectedTab(key)}
                >
                  <ModernParagraph size="small">
                    {trans("COMMON", name)}
                  </ModernParagraph>
                </CommonButton>
              ))}
            </AnimateWrapper>
            <AnimateWrapper
              as="div"
              delay={INITIAL_ANIM_DELAY + 0.6}
              animateType="opacity-lightScale"
              key="account-avatar"
            >
              <Suspense>
                {selectedTab === "diaries" ? (
                  <DiariesTab diaries={accountDetail.diaries} />
                ) : selectedTab === "friends" ? (
                  <FriendsTab friends={accountDetail.friends} />
                ) : null}
              </Suspense>
            </AnimateWrapper>
          </AppContent>
        </div>
        <Suspense>
          <AnimatePresence mode="wait">
            {isShowEditProfileModal ? (
              <EditProfileModal
                onClose={() => setIsShowEditProfileModal(false)}
              >
                <EditProfileForm
                  accountDetail={accountDetail}
                  onClose={() => setIsShowEditProfileModal(false)}
                />
              </EditProfileModal>
            ) : null}
          </AnimatePresence>
        </Suspense>
      </>
    );
  } else
    return (
      <div className="grid place-items-center">
        <DotSpinnerLoader />
      </div>
    );
}

export default Account;
