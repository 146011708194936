import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { TBluelyraContextData } from "./type";

const initialState: TBluelyraContextData["process"] = {
  isPlaying: false,
  isLoop: false,
  volume: 0.8,
  played: 0,
  seekTo: null,
};

const slice = createSlice<
  TBluelyraContextData["process"],
  SliceCaseReducers<TBluelyraContextData["process"]>,
  "bluelyra-process",
  SliceSelectors<TBluelyraContextData["process"]>
>({
  name: "bluelyra-process",
  initialState,
  reducers: {
    setBluelyraProcess: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]>
    ) => {
      return action.payload;
    },
    setBluelyraVolume: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]["volume"]>
    ) => {
      return {
        ...state,
        volume: action.payload,
      };
    },
    setBluelyraPlayed: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]["played"]>
    ) => {
      return {
        ...state,
        played: action.payload,
      };
    },
    setBluelyraIsPlaying: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]["isPlaying"]>
    ) => {
      return {
        ...state,
        isPlaying: action.payload,
      };
    },
    setBluelyraIsLoop: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]["isLoop"]>
    ) => {
      return {
        ...state,
        isLoop: action.payload,
      };
    },
    setBluelyraSeekTo: (
      state: TBluelyraContextData["process"],
      action: PayloadAction<TBluelyraContextData["process"]["seekTo"]>
    ) => {
      return {
        ...state,
        seekTo: action.payload,
      };
    },
  },
});

export const {
  setBluelyraProcess,
  setBluelyraVolume,
  setBluelyraPlayed,
  setBluelyraIsPlaying,
  setBluelyraIsLoop,
  setBluelyraSeekTo,
} = slice.actions;
export default slice.reducer;
