import { useNavigateWithCurrentLang } from "@business-layer/business-logic/lib/lang";
import { DotSpinnerLoader } from "@presentational/atoms/loader";
import { memo } from "react";
import { Link } from "react-router-dom";

type TProps = {
  url: string | null; // null is loading
  alt: string;
  theme: "circle" | "square";
  href: string;
  containerStyle?: React.CSSProperties;
};
function AvatarBtn({ url, alt, theme, href, containerStyle }: TProps) {
  const { getHref } = useNavigateWithCurrentLang();
  return (
    <Link
      to={getHref(href)}
      className="w-full overflow-hidden"
      style={{
        borderRadius: theme === "circle" ? "999px" : "0.25rem",
        ...containerStyle,
      }}
    >
      {url ? (
        <img
          src={url}
          alt={alt}
          className="w-full aspect-square h-auto object-cover"
        />
      ) : (
        <div className="w-full aspect-square h-auto bg-Zinc grid place-items-center">
          <DotSpinnerLoader />
        </div>
      )}
    </Link>
  );
}

export default memo(AvatarBtn);
