import { useSelector } from "react-redux";
import { TBluelyraContextData } from "../context";
import { useMemo } from "react";

export const useGetBluelyraProcess = () => {
  const process = useSelector<
    TBluelyraContextData,
    TBluelyraContextData["process"]
  >((state) => state.process);
  return useMemo(() => process, [process]);
};
