import { AxiosError, InternalAxiosRequestConfig } from "axios";
import { client } from "../config/axios";
import { requestLimit } from "../config/ddos";
import { activeMiddlewares } from "../config/middlewares";

export function enableDdosFirewallMiddleware() {
  activeMiddlewares.ddos_firewall &&
    client.interceptors.request.use(
      (config: InternalAxiosRequestConfig) => {
        const now = Date.now();
        if (now - requestLimit.blockedUntil > requestLimit.blockDuration) {
          requestLimit.blockedUntil = 0;
        } else {
          return Promise.reject(
            new Error("Bạn đã bị cấm 1 lúc vì vượt giới hạn nhiều lần")
          );
        }
        // Reset the request count if more than a second has passed since last reset
        if (now - requestLimit.lastResetTime > 1000) {
          requestLimit.reqPerSecondCount = 0;
          requestLimit.lastResetTime = now;
        }
        if (
          requestLimit.reqPerSecondCount < requestLimit.reqPerSecond &&
          requestLimit.blockedUntil === 0
        ) {
          requestLimit.reqPerSecondCount++;
          return config;
        } else {
          requestLimit.exceedLimitCount++;
          if (requestLimit.exceedLimitCount >= requestLimit.exceedLimit) {
            requestLimit.blockedUntil = requestLimit.blockDuration + now;
          }
          return Promise.reject(new Error("Bạn đã đạt giới hạn số lần gọi"));
        }
      },
      (error: AxiosError) => {
        return Promise.reject(error);
      }
    );
}
