import { WEATHER_ENDPOINTS } from "../../config/apis";
import { Services } from "../../service";
import { GetCurrentWeatherSchema } from "./schema";
import { TGetCurrentWeatherProps, TGetCurrentWeatherResponse } from "./type";

export * from "./type";
export class WeatherService extends Services {
  private readonly openWeatherAPIKey =
    process.env.REACT_APP_OPEN_WEATHER_API_KEY;

  getCurrentWeather = async ({
    token,
    ...props
  }: TGetCurrentWeatherProps): Promise<TGetCurrentWeatherResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof GetCurrentWeatherSchema,
          TGetCurrentWeatherResponse
        >({
          ...WEATHER_ENDPOINTS.getCurrentWeather,
          isUseProxy: true,
          params: {
            ...props,
            appid: this.openWeatherAPIKey,
          },
          schema: GetCurrentWeatherSchema,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
