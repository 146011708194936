import { APP_URLS } from "@constants/urls.constants";
import { AnimatePresence } from "framer-motion";
import {
  useLanguage,
  useNavigateWithCurrentLang,
} from "@business-layer/business-logic/lib/lang";
import {
  AnimateWrapper,
  AvatarLink,
  ModernParagraph,
} from "@presentational/atoms";
import { lazy, Suspense, useCallback, useState } from "react";
import { useAccountDetail } from "@business-layer/business-logic/lib/account";

const APPS = [
  {
    name: "Hidden valley",
    logo: "/assets/imgs/logo/hidden-valley.png",
    href: APP_URLS.HIDDEN_VALLEY,
  },
  // {
  //   name: "Dreaming",
  //   logo: "/assets/imgs/logo/dreaming-logo.png",
  //   href: APP_URLS.DREAMING,
  // },
];

const NavLangDropdown = lazy(
  () => import("@presentational/molecules/dropdown/NavLangDropdown")
);

function AppNavbar() {
  const { trans } = useLanguage();
  const { getHref, handleNavigate } = useNavigateWithCurrentLang();
  const pathName = window.location.pathname;
  const [isSelectingLang, setIsSelectingLang] = useState<boolean>(false);
  const handleCloseDropdown = useCallback(() => setIsSelectingLang(false), []);
  const { data: accountDetail } = useAccountDetail();

  return (
    <nav className="col-span-1 h-[calc(100vh-2rem)] my-4 flex flex-col justify-between items-center bg-Primary rounded-full pt-6">
      <a href={getHref(APP_URLS.DASHBOARD)}>
        <img
          src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
          alt="blueberry sensei"
          width={200}
          height={200}
          className="w-12 h-12 object-cover"
        />
      </a>
      <ul className="flex flex-col items-center gap-8 w-full flex-grow pt-8">
        <li
          className="w-6 h-6 flex justify-center items-center hover:!opacity-100 hover:scale-105 transition-all"
          style={{
            opacity: pathName.includes(APP_URLS.DASHBOARD) ? 1 : 0.4,
          }}
        >
          <button onClick={() => handleNavigate(APP_URLS.DASHBOARD)}>
            <i className="fi fi-sr-home text-White text-lg"></i>
          </button>
        </li>
        {APPS.map((a) => (
          <li
            key={a.name}
            className="w-6 h-6 flex justify-center items-center hover:!opacity-100 hover:scale-105 transition-all"
            style={{
              opacity: pathName.includes(a.href) ? 1 : 0.4,
            }}
          >
            <button onClick={() => handleNavigate(a.href)}>
              <img
                src={a.logo}
                alt={a.name}
                className="w-7 h-auto aspect-square"
              />
            </button>
          </li>
        ))}
      </ul>
      <div className="flex flex-col items-center gap-4 w-[calc(100%-1rem)] flex-shrink bg-Secondary rounded-full mx-auto mb-2 px-3 pt-3 pb-5">
        <AvatarLink
          url={accountDetail?.avatar ?? null}
          alt="blueberrysensei"
          theme="circle"
          href={APP_URLS.ACCOUNT}
        />
        <hr className="bg-White w-full opacity-50" />
        <div className="relative w-4 h-6">
          <AnimatePresence mode="wait">
            {isSelectingLang ? (
              <Suspense>
                <NavLangDropdown onClose={handleCloseDropdown} />
              </Suspense>
            ) : null}
          </AnimatePresence>

          <button
            type="button"
            className="opacity-70 hover:opacity-100 transition-opacity text-White flex flex-row gap-2 flex-nowrap items-center justify-center absolute bottom-0 left-0 z-30"
            onClick={() => setIsSelectingLang((prev) => !prev)}
          >
            <i className="fi fi-rr-earth-asia"></i>

            <AnimatePresence mode="wait">
              {isSelectingLang ? (
                <AnimateWrapper
                  as="div"
                  key={"lang-label"}
                  animateType="opacity"
                >
                  <ModernParagraph size="small" className="whitespace-nowrap">
                    {trans("COMMON", "lang-label")}
                  </ModernParagraph>
                </AnimateWrapper>
              ) : null}
            </AnimatePresence>
          </button>
        </div>
        {/* <button
          type="button"
          className="opacity-70 hover:opacity-100 transition-opacity"
          onClick={() => {}}
        >
          <i className="fi fi-sr-settings text-White"></i>
        </button> */}
      </div>
    </nav>
  );
}

export default AppNavbar;
