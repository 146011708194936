import { useDispatch } from "react-redux";
import { setBluelyraIsLoop } from "../context";

export const useChangeBluelyraIsLoop = () => {
  const dispatch = useDispatch();
  function onChange(isLoop: boolean) {
    dispatch(setBluelyraIsLoop(isLoop));
  }
  return {
    onChange,
  };
};
