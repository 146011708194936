import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { useSearchYoutubeVideosMutation } from "../../fetching/mutations";
import { IYouTubeSearchResultItem } from "@business-layer/services/entities";

const DEFAULT_PARAMS = {
  maxResults: 30,
  part: "snippet",
};

export const useSearchYoutubeVideos = () => {
  const { mutateAsync, isPending } = useSearchYoutubeVideosMutation();
  const token = useGetToken();
  async function onSearch(keys: string): Promise<IYouTubeSearchResultItem[]> {
    const { items } = await mutateAsync({
      ...DEFAULT_PARAMS,
      q: keys,
      token,
    });
    return items;
  }
  return {
    onSearch,
    isLoading: isPending,
  };
};
