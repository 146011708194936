import React from "react";
import { APP_REDUCERS } from "../configs";
import QueryProvider from "./ReactQueryProvider";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import { AuthContextProvider } from "../lib/auth/process/provider/ContextProvider";

const APP_STORE = configureStore({
  reducer: APP_REDUCERS,
});

type BusinessLogicProviderType = {
  children: React.ReactNode;
};
export const BusinessLogicProvider: React.FC<BusinessLogicProviderType> = ({
  children,
}) => {
  // Wrap children inside active provider
  // const renderProviders = (children: React.ReactNode) => {
  //   return providerConfig.reduce((prevChildren, config) => {
  //     const Provider = config.provider;
  //     return <Provider>{prevChildren}</Provider>;
  //   }, children);
  // };

  return (
    <QueryProvider>
      <AuthContextProvider>
        <Provider store={APP_STORE}>{children}</Provider>
      </AuthContextProvider>
    </QueryProvider>
  );
};
