import { AUTH_ENDPOINT } from "../../config/apis";
import { Services } from "../../service";
import {
  SLoginResponse,
  SPreLoginResponse,
  SPreRegisterResponse,
  SRefreshTokenResponse,
  SRegisterResponse,
} from "./schema";
import {
  TLoginParams,
  TLoginResponse,
  TPreLoginParams,
  TPreLoginResponse,
  TPreRegisterParams,
  TPreRegisterResponse,
  TRefreshTokenResponse,
  TRegisterParams,
  TRegisterResponse,
} from "./type";

export * from "./type";
export class AuthService extends Services {
  preLogin = async (data: TPreLoginParams): Promise<TPreLoginResponse> => {
    this.abortController = new AbortController();
    try {
      const response = await this.fetchApi<
        typeof SPreLoginResponse,
        TPreLoginResponse
      >({
        ...AUTH_ENDPOINT.preLogin,
        schema: SPreLoginResponse,
        data,
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  };
  preRegister = async (
    data: TPreRegisterParams
  ): Promise<TPreRegisterResponse> => {
    this.abortController = new AbortController();
    try {
      const response = await this.fetchApi<
        typeof SPreRegisterResponse,
        TPreRegisterResponse
      >({
        ...AUTH_ENDPOINT.preRegister,
        schema: SPreRegisterResponse,
        data,
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  };
  login = async (data: TLoginParams): Promise<TLoginResponse> => {
    this.abortController = new AbortController();
    try {
      const response = await this.fetchApi<
        typeof SLoginResponse,
        TLoginResponse
      >({
        ...AUTH_ENDPOINT.login,
        schema: SLoginResponse,
        data,
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  };
  register = async (data: TRegisterParams): Promise<TRegisterResponse> => {
    this.abortController = new AbortController();
    try {
      const response = await this.fetchApi<
        typeof SRegisterResponse,
        TRegisterResponse
      >({
        ...AUTH_ENDPOINT.register,
        schema: SRegisterResponse,
        data,
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  };
  refreshToken = async (
    refreshToken: string
  ): Promise<TRefreshTokenResponse> => {
    this.abortController = new AbortController();
    try {
      const response = await this.fetchApi<
        typeof SRefreshTokenResponse,
        TRefreshTokenResponse
      >({
        ...AUTH_ENDPOINT.refreshToken,
        schema: SRefreshTokenResponse,
        data: { refreshToken },
        signal: this.abortController.signal,
        transformResponse: (res) => res,
      });
      return response;
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
