// Importing necessary modules and functions
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { usePreRegisterMutation } from "../../fetching/mutation";

export const usePreRegister = () => {
  const { trans } = useLanguage();
  const { mutateAsync, isPending } = usePreRegisterMutation();

  const onPreRegister = async (email: string): Promise<void> => {
    try {
      await mutateAsync({ email });
    } catch (error: any) {
      console.error(error);
      throw new Error(trans("SERVER", error.message));
    }
  };

  return {
    onPreRegister,
    isLoading: isPending,
  };
};
