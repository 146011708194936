"use client";

import { forwardRef, memo } from "react";
import { motion, Variant } from "framer-motion";

type TAnimate =
  | "opacity"
  | "opacity-lightScale"
  | "opacity-fullScale"
  | "fullScale"
  | "opacity-fullScale-x"
  | "opacity-riseUp"
  | "opacity-height-fullScale"
  | "opacity-fallDown";
const COMMON_ANIM_DURATION = 0.5;
const FAST_ANIM_DURATION = 0.2;
const COMMON_EXIT_DURATION = 0.3;

function getAnimateStyle(
  animateType: TAnimate,
  delay: number,
  isBounce: boolean,
  animDuration: number
): {
  variants: { [key in "initial" | "animate" | "exit"]: Variant };
  isLayoutAnimation: boolean;
} {
  const animateTransition = isBounce
    ? {
        duration: animDuration,
        delay,
        type: "spring",
        stiffness: 350,
        damping: 20,
        bounce: 0.8,
      }
    : {
        duration: animDuration,
        ease: "easeInOut",
        delay,
      };
  const exitTransition = {
    duration: COMMON_EXIT_DURATION,
    ease: "easeInOut",
  };

  switch (animateType) {
    case "opacity":
      return {
        variants: {
          initial: { opacity: 0 },
          animate: { opacity: 1, transition: animateTransition },
          exit: { opacity: 0, transition: exitTransition },
        },
        isLayoutAnimation: false,
      };
    case "opacity-lightScale":
      return {
        variants: {
          initial: { opacity: 0, scale: 0.9 },
          animate: { opacity: 1, scale: 1, transition: animateTransition },
          exit: { opacity: 0, scale: 0.9, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "opacity-fullScale":
      return {
        variants: {
          initial: { opacity: 0, scale: 0.8 },
          animate: { opacity: 1, scale: 1, transition: animateTransition },
          exit: { opacity: 0, scale: 0.8, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "fullScale":
      return {
        variants: {
          initial: { scale: 0.8 },
          animate: { scale: 1, transition: animateTransition },
          exit: { scale: 0.8, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "opacity-fullScale-x":
      return {
        variants: {
          initial: { opacity: 0, scaleX: 0.8 },
          animate: { opacity: 1, scaleX: 1, transition: animateTransition },
          exit: { opacity: 0, scaleX: 0.8, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "opacity-riseUp":
      return {
        variants: {
          initial: { opacity: 0, y: 150 },
          animate: { opacity: 1, y: 0, transition: animateTransition },
          exit: { opacity: 0, y: 150, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "opacity-fallDown":
      return {
        variants: {
          initial: { opacity: 0, y: -150 },
          animate: { opacity: 1, y: 0, transition: animateTransition },
          exit: { opacity: 0, y: -150, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
    case "opacity-height-fullScale":
      return {
        variants: {
          initial: { height: 0, opacity: 0 },
          animate: {
            height: "auto",
            opacity: 1,
            transition: animateTransition,
          },
          exit: { height: 0, opacity: 0, transition: exitTransition },
        },
        isLayoutAnimation: true,
      };
  }
}

type MotionComponentProps<Tag extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[Tag] & {
    as: Tag;
    delay?: number;
    animateType: TAnimate;
    key: string;
    isBounce?: boolean;
    isFastAnimation?: boolean;
  };

const AnimateWrapper = forwardRef(
  <Tag extends keyof JSX.IntrinsicElements>(
    {
      children,
      as,
      delay = 0,
      animateType,
      isBounce = false,
      isFastAnimation,
      ...motionProps
    }: MotionComponentProps<Tag>,
    ref: React.Ref<HTMLElement>
  ) => {
    const { variants, isLayoutAnimation } = getAnimateStyle(
      animateType,
      delay,
      isBounce,
      isFastAnimation ? FAST_ANIM_DURATION : COMMON_ANIM_DURATION
    );
    const Component = motion[as as keyof typeof motion];

    return (
      <Component
        variants={variants}
        initial="initial"
        animate="animate"
        exit="exit"
        layout={isLayoutAnimation}
        {...motionProps}
      >
        {as !== "img" && children}
      </Component>
    );
  }
);

export default memo(AnimateWrapper) as typeof AnimateWrapper;
