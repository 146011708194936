import {
  CommonButton,
  ModernParagraph,
  NerkoText,
} from "@presentational/atoms";
import { formatDate } from "@utils/helpers/datetime.helper";

function SundialWidget() {
  const currentDate = new Date();
  const { dateMonthYear, time24 } = formatDate(currentDate);
  return (
    <>
      <div className="flex flex-row justify-end items-start h-fit w-full pt-2 relative">
        <div className="h-10 flex flex-row justify-end items-end relative z-0">
          <div className="bg-Secondary relative w-8 h-[80%]">
            <div className="absolute top-0 left-0 w-full h-full bg-Primary rounded-br-[3rem]"></div>
          </div>
          <div className="bg-Secondary w-24 h-full rounded-tr-[4rem] rounded-tl-[1rem] pl-3 pt-2">
            <div className="flex flex-row items-center gap-1">
              <img
                src="/assets/imgs/icons/moon.svg"
                alt="time"
                width={50}
                height={50}
                className="w-4 h-4"
              />
              <ModernParagraph size="small" className="text-White">
                {time24}
              </ModernParagraph>
            </div>
            <ModernParagraph size="small" className="text-White">
              {dateMonthYear}
            </ModernParagraph>
          </div>
        </div>
        <NerkoText
          size="large"
          className="text-White absolute left-6 bottom-1 z-10"
        >
          Sundial
        </NerkoText>
      </div>
      <div className="w-full bg-Secondary rounded-tl-[1rem] rounded-b-[1rem] px-3 py-4">
        <div className="grid grid-cols-7 grid-rows-5 gap-1">
          {Array(31)
            .fill(1)
            .map((_, index) => (
              <div
                key={index}
                className="bg-Primary rounded-md w-full h-auto aspect-square text-White grid place-items-center"
              >
                <ModernParagraph size="small">{index + 1}</ModernParagraph>
              </div>
            ))}
        </div>
        <div className="flex flex-row justify-between items-center mt-3">
          <div>
            <ModernParagraph size="small" className="text-White">
              Công việc còn lại
            </ModernParagraph>
            <NerkoText size="xlarge" className="text-White leading-8">
              15
            </NerkoText>
          </div>
          <CommonButton
            size="base"
            theme="Pink"
            className="rounded-full w-10 h-10 !p-0"
            onClick={() => {}}
          >
            <i className="fi fi-sr-paper-plane -bottom-1"></i>
          </CommonButton>
        </div>
      </div>
    </>
  );
}

export default SundialWidget;
