import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { getHeadingFontConfig } from "@utils/helpers/font.helper";
import { Toaster } from "sonner";

function CommonNotification() {
  const { currentLang } = useLanguage();
  return (
    <Toaster
      position="top-center"
      richColors={true}
      style={{
        ...getHeadingFontConfig(currentLang),
      }}
      theme="light"
      expand={false}
    />
  );
}
export default CommonNotification;
