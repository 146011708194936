import { AnimatePresence } from "framer-motion";
import { useState, useRef, useEffect } from "react";

import CommonButton, { TCommonButtonProps } from "./CommonButton";
import { COLOR_THEME, TColor } from "@presentational/themes/colors";
import { CustomAnimateWrapper } from "@presentational/atoms/animate";

const POSITION_STYLES = {
  "top-right": {
    popoverContainerStyles: {
      borderBottomLeftRadius: 0,
      transform: "translateY(-100%)",
    },
  },
  "bottom-right": {
    popoverContainerStyles: {
      borderTopLeftRadius: 0,
      transform: "translateY(-100%)",
    },
  },
  "top-left": {
    popoverContainerStyles: {
      borderBottomRightRadius: 0,
      transform: "translateY(-100%)",
    },
  },
  "bottom-left": {
    popoverContainerStyles: {
      borderTopRightRadius: 0,
      transform: "translateY(-100%)",
    },
  },
};
const VARIANTS = {
  popover: {
    initial: {
      width: "5rem",
      height: "5rem",
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      scale: 1,
      opacity: 1,
      width: "auto",
      height: "auto",
      transition: {
        duration: 0.3,
        type: "spring",
        stiffness: 300,
        damping: 20,
        bounce: 0.5,
      },
    },
    exit: {
      opacity: 0,
      scale: 0.5,
      width: "5rem",
      height: "5rem",
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
  },
};
type TProps = TCommonButtonProps & {
  position: "top-right" | "bottom-right" | "top-left" | "bottom-left";
  popoverChildren: React.ReactNode;
  popoverBgColor?: TColor;
};
function CommonBtnWithPopover({
  position,
  popoverChildren,
  popoverBgColor = "White",
  ...btnProps
}: TProps) {
  const [isPopoverVisible, setIsPopoverVisible] = useState<boolean>(false);
  const eventListenerRef = useRef<any>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const popoverRef = useRef<HTMLDivElement>(null);

  const togglePopover = () => {
    setIsPopoverVisible((prev) => !prev);
  };
  const updatePopoverPosition = () => {
    if (buttonRef.current && popoverRef.current) {
      console.log(buttonRef.current.getClientRects());
      const { top, bottom, left, right } =
        buttonRef.current.getBoundingClientRect();
      console.log(window.scrollY);
      const buttonHeight = bottom - top;
      const buttonWidth = right - left;
      switch (position) {
        case "top-right": {
          popoverRef.current.style.bottom = `${
            window.innerHeight - top + 10
          }px`;
          popoverRef.current.style.left = `${left + buttonWidth}px`;
          break;
        }
        case "bottom-right": {
          popoverRef.current.style.top = `${top + buttonHeight}px`;
          popoverRef.current.style.left = `${left + buttonWidth}px`;
          break;
        }
        case "top-left": {
          popoverRef.current.style.bottom = `${
            window.innerHeight - top + 10
          }px`;
          popoverRef.current.style.right = `${window.innerWidth - left}px`;
          break;
        }
        case "bottom-left": {
          popoverRef.current.style.top = `${top + buttonHeight}px`;
          popoverRef.current.style.right = `${window.innerWidth - left}px`;
          break;
        }
      }
    }
  };
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      if (isPopoverVisible) {
        updatePopoverPosition();
      }
    });
    if (isPopoverVisible && buttonRef.current && popoverRef.current) {
      eventListenerRef.current = (event: any) => {
        if (popoverRef.current && buttonRef.current) {
          if (
            popoverRef.current.contains(event.target) ||
            buttonRef.current.contains(event.target)
          ) {
            return;
          }
          setIsPopoverVisible(false);
        }
      };
      resizeObserver.observe(buttonRef.current);
      updatePopoverPosition();
      window.addEventListener("click", eventListenerRef.current);
    }
    return () => {
      resizeObserver.disconnect();
      eventListenerRef.current &&
        window.removeEventListener("click", eventListenerRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPopoverVisible]);
  return (
    <>
      <CommonButton
        {...btnProps}
        ref={buttonRef}
        onClick={() => {
          togglePopover();
        }}
      />
      <AnimatePresence mode="wait">
        {isPopoverVisible ? (
          <CustomAnimateWrapper
            as="div"
            key={`CommonBtnWithPopover`}
            ref={popoverRef}
            style={{
              ...POSITION_STYLES[position].popoverContainerStyles,
              backgroundColor: COLOR_THEME[popoverBgColor],
            }}
            className="rounded-2xl fixed z-20 transform shadow-md border-[1px] border-Gray"
            variants={VARIANTS.popover}
          >
            {popoverChildren}
          </CustomAnimateWrapper>
        ) : null}
      </AnimatePresence>
    </>
  );
}

export default CommonBtnWithPopover;
