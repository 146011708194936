import { z } from "zod";

export const SYouTubeThumbnail = z.object({
  url: z.string(),
  width: z.number().optional(),
  height: z.number().optional(),
});

export const SYouTubeVideoItem = z.object({
  kind: z.string(),
  etag: z.string(),
  id: z.string(),
  snippet: z.object({
    publishedAt: z.string(),
    channelId: z.string(),
    title: z.string(),
    description: z.string(),
    thumbnails: z.object({
      default: SYouTubeThumbnail,
      medium: SYouTubeThumbnail,
      high: SYouTubeThumbnail,
      standard: SYouTubeThumbnail.optional(),
      maxres: SYouTubeThumbnail.optional(),
    }),
    channelTitle: z.string(),
    tags: z.array(z.string()).optional(),
    categoryId: z.string(),
    liveBroadcastContent: z.string(),
    localized: z.object({
      title: z.string(),
      description: z.string(),
    }),
    defaultAudioLanguage: z.string().optional(),
  }),
});

export const SYouTubeVideoResponse = z.object({
  kind: z.string(),
  etag: z.string(),
  items: z.array(SYouTubeVideoItem),
  pageInfo: z.object({
    totalResults: z.number(),
    resultsPerPage: z.number(),
  }),
});

// TypeScript Interfaces
export interface IYouTubeThumbnail {
  url: string;
  width?: number;
  height?: number;
}

export interface IYouTubeVideoItem {
  kind: string;
  etag: string;
  id: string;
  snippet: {
    publishedAt: string;
    channelId: string;
    title: string;
    description: string;
    thumbnails: {
      default: IYouTubeThumbnail;
      medium: IYouTubeThumbnail;
      high: IYouTubeThumbnail;
      standard?: IYouTubeThumbnail;
      maxres?: IYouTubeThumbnail;
    };
    channelTitle: string;
    tags?: string[];
    categoryId: string;
    liveBroadcastContent: string;
    localized: {
      title: string;
      description: string;
    };
    defaultAudioLanguage?: string;
  };
}

export interface IYouTubeVideoResponse {
  kind: string;
  etag: string;
  items: IYouTubeVideoItem[];
  pageInfo: {
    totalResults: number;
    resultsPerPage: number;
  };
}

export interface IYouTubeSearchResultItem {
  kind: string; // Kiểu của item, ví dụ: 'youtube#video'
  etag: string; // ETag của item
  id: {
    kind: string; // Kiểu của ID, ví dụ: 'youtube#video'
    videoId?: string; // ID của video (nếu item là video)
    channelId?: string; // ID của kênh (nếu item là kênh)
    playlistId?: string; // ID của playlist (nếu item là playlist)
  };
  snippet: {
    publishedAt: string; // Ngày giờ video được đăng tải
    channelId: string; // ID của kênh đăng video
    title: string; // Tiêu đề của video
    description: string; // Mô tả của video
    thumbnails: {
      default: IYouTubeThumbnail;
      medium: IYouTubeThumbnail;
      high: IYouTubeThumbnail;
    };
    channelTitle: string; // Tên của kênh
    liveBroadcastContent: string; // Trạng thái phát trực tiếp (nếu có)
    publishTime: string; // Thời gian đăng tải video
  };
}

export interface IYouTubeSearchResponse {
  kind: string; // Kiểu của response, ví dụ: 'youtube#searchListResponse'
  etag: string; // ETag của response
  nextPageToken?: string; // Token để lấy trang tiếp theo (nếu có)
  regionCode: string; // Mã vùng của người dùng (ví dụ: 'US', 'VN')
  pageInfo: {
    totalResults: number; // Tổng số kết quả tìm kiếm có sẵn
    resultsPerPage: number; // Số kết quả trên mỗi trang
  };
  items: IYouTubeSearchResultItem[]; // Danh sách các kết quả tìm kiếm
}

export const SYouTubeSearchResultItem = z.object({
  kind: z.string(), // Kiểu của item
  etag: z.string(), // ETag của item
  id: z.object({
    kind: z.string(), // Kiểu của ID
    videoId: z.string().optional(), // ID của video (tùy chọn)
    channelId: z.string().optional(), // ID của kênh (tùy chọn)
    playlistId: z.string().optional(), // ID của playlist (tùy chọn)
  }),
  snippet: z.object({
    publishedAt: z.string(), // Ngày giờ video được đăng tải
    channelId: z.string(), // ID của kênh đăng video
    title: z.string(), // Tiêu đề của video
    description: z.string(), // Mô tả của video
    thumbnails: z.object({
      default: SYouTubeThumbnail,
      medium: SYouTubeThumbnail,
      high: SYouTubeThumbnail,
    }),
    channelTitle: z.string(), // Tên của kênh
    liveBroadcastContent: z.string(), // Trạng thái phát trực tiếp (nếu có)
    publishTime: z.string(), // Thời gian đăng tải video
  }),
});

export const SYouTubeSearchResponseSchema = z.object({
  kind: z.string(), // Kiểu của response
  etag: z.string(), // ETag của response
  nextPageToken: z.string().optional(), // Token để lấy trang tiếp theo (tùy chọn)
  regionCode: z.string(), // Mã vùng của người dùng
  pageInfo: z.object({
    totalResults: z.number(), // Tổng số kết quả tìm kiếm có sẵn
    resultsPerPage: z.number(), // Số kết quả trên mỗi trang
  }),
  items: z.array(SYouTubeSearchResultItem), // Danh sách các kết quả tìm kiếm
});
