// Importing necessary libraries and services
import { useMemo } from "react";
import { useAuthContext } from "../context";
import { getToken } from "./useAccessToken";

// Hook to check if the user is logged in
export const useIsLogged = () => {
  const {
    state: { token },
  } = useAuthContext();
  const cookieToken = getToken();
  return useMemo(() => Boolean(token || cookieToken), [token, cookieToken]);
};
