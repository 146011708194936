import React, { memo, ReactNode } from "react";
import { getHeadingFontConfig } from "@utils/helpers/font.helper";
import { COLOR_THEME, TColor } from "@presentational/themes/colors";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

type THeading = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | undefined;

type TProps = {
  as: THeading;
  children: ReactNode;
  className?: string;
  color?: TColor;
};
function Heading({
  as: Component = "p",
  children,
  className,
  color = "White",
}: TProps) {
  const { currentLang } = useLanguage();
  const style = {
    ...getHeadingFontConfig(currentLang),
    color: COLOR_THEME[color],
  };

  return React.createElement(Component, { className, style }, children);
}
export default memo(Heading);
