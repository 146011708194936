export const HIDDEN_VALLEY_PSW_ICONS = {
  antelope: {
    publicPath: "/assets/imgs/icons/antelope_4726758.png",
    key: "antelope",
  },
  cobra: { publicPath: "/assets/imgs/icons/cobra_1362523.png", key: "cobra" },
  elephant: {
    publicPath: "/assets/imgs/icons/elephant_1838775.png",
    key: "elephant",
  },
  frog: { publicPath: "/assets/imgs/icons/frog_394697.png", key: "frog" },
  gorilla: {
    publicPath: "/assets/imgs/icons/gorilla_848701.png",
    key: "gorilla",
  },
  hamster: {
    publicPath: "/assets/imgs/icons/hamster_5389261.png",
    key: "hamster",
  },
  hippopotamus: {
    publicPath: "/assets/imgs/icons/hippopotamus_2881963.png",
    key: "hippopotamus",
  },
  kangaroo: {
    publicPath: "/assets/imgs/icons/kangaroo_848559.png",
    key: "kangaroo",
  },
  koala: { publicPath: "/assets/imgs/icons/koala_3069172.png", key: "koala" },
  toucan: {
    publicPath: "/assets/imgs/icons/toucan_1838753.png",
    key: "toucan",
  },
  lion: { publicPath: "/assets/imgs/icons/lion_1838781.png", key: "lion" },
  tiger: { publicPath: "/assets/imgs/icons/tiger_1838786.png", key: "tiger" },
  monkey: { publicPath: "/assets/imgs/icons/monkey_257473.png", key: "monkey" },
  penguin: {
    publicPath: "/assets/imgs/icons/penguin_170469.png",
    key: "penguin",
  },
  rhinoceros: {
    publicPath: "/assets/imgs/icons/rhinoceros_3831184.png",
    key: "rhinoceros",
  },
};
