import { useDispatch } from "react-redux";
import { setBluelyraPlayed } from "../context";

export const useChangeBluelyraPlayed = () => {
  const dispatch = useDispatch();
  function onChange(newPlayed: number) {
    dispatch(setBluelyraPlayed(newPlayed));
  }
  return {
    onChange,
  };
};
