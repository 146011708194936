import {
  useDeleteHiddenValleyPassword,
  useUpdatePasswordOrder,
  useUnveilPassword,
} from "@business-layer/business-logic/lib/hiddenValley";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import { IHiddenValleyPassword } from "@business-layer/services/entities";
import { ENCRYPT_METHODS } from "@constants/encrypt.constants";
import { HIDDEN_VALLEY_PSW_ICONS } from "@constants/icon.constants";
import {
  ModernParagraph,
  IconBtn,
  DotSpinnerLoader,
  AnimateWrapper,
} from "@presentational/atoms";
import { AnimatePresence, Reorder } from "framer-motion";
import { lazy, memo, Suspense, useRef, useState } from "react";

const AlertDialog = lazy(
  () => import("@presentational/molecules/overlay/AlertDialog")
);

type TProps = {
  passwords: IHiddenValleyPassword[];
};
function HiddenValleyPasswordsList({ passwords }: TProps) {
  const { trans } = useLanguage();
  const deletePasswordTargeted = useRef<string | null>(null);
  const [isAlertDeleteActive, setIsAlertDeleteActive] =
    useState<boolean>(false);
  const [currentPasswordMoreOption, setCurrentPasswordMoreOption] = useState<
    string | null
  >(null);
  const [unveiledPassword, setUnveiledPassword] = useState<{
    passwordId: string;
    value: string;
  } | null>();
  const { onDelete, isLoading: isDeletingPassword } =
    useDeleteHiddenValleyPassword();
  const { toastSuccess, toastError, toastPromise } = useNotification();
  const { onReOrder } = useUpdatePasswordOrder();
  const { onUnveil } = useUnveilPassword();

  function handleCopyPassword(password: string) {
    navigator.clipboard
      .writeText(onUnveil(password))
      .then(() => {
        toastSuccess("Copy successfully");
      })
      .catch((err) => {
        toastError("Copy failed!");
      });
  }
  function handleUnveilPassword(
    isUnveil: boolean,
    passwordId: string,
    passwordValue: string
  ) {
    if (isUnveil) {
      setUnveiledPassword(null);
    } else {
      setUnveiledPassword({
        passwordId,
        value: onUnveil(passwordValue),
      });
    }
  }
  async function handleDeletePassword() {
    if (deletePasswordTargeted.current) {
      const promise = onDelete({ id: deletePasswordTargeted.current });
      toastPromise({
        successMsg: trans(
          "HIDDEN_VALLEY",
          "dashboard.alert-dialog-delete-success-msg"
        ),
        loadingMsg: trans(
          "HIDDEN_VALLEY",
          "dashboard.alert-dialog-delete-in-progress-msg"
        ),
        promise: promise,
      });
      try {
        await promise;
      } catch (error: any) {
        toastError(error.message);
      } finally {
        deletePasswordTargeted.current = null;
      }
    }
  }
  return (
    <>
      <Reorder.Group axis="y" layout values={passwords} onReorder={onReOrder}>
        <AnimatePresence mode="sync">
          {passwords.map((p, index) => {
            const isUnveil = unveiledPassword?.passwordId === p.passwordId;
            const isOpeningMoreOption = currentPasswordMoreOption?.includes(
              p.passwordId
            );
            return (
              <Reorder.Item
                key={p.passwordId}
                value={p}
                layout
                initial={{ opacity: 0, y: 100 }}
                animate={{
                  opacity: 1,
                  y: 0,
                  transition: {
                    duration: 0.3,
                    ease: "easeInOut",
                    delay: index * 0.15 + 0.1,
                  },
                }}
                exit={{
                  opacity: 0,
                  y: 100,
                }}
                className="w-full bg-Primary rounded-lg grid grid-cols-3 gap-3 py-3 px-5 relative mb-2"
              >
                <div className="col-span-1 flex flex-row items-center gap-3 whitespace-nowrap">
                  <button className="cursor-grab">
                    <i className="fi fi-br-menu-burger text-Gray"></i>
                  </button>
                  <div className="flex flex-col justify-center">
                    <ModernParagraph size="base" className="text-White">
                      {p.name}
                    </ModernParagraph>
                    {p.key ? (
                      <button className="flex flex-row items-center gap-1">
                        <ModernParagraph size="base" className="text-blue-500">
                          #
                        </ModernParagraph>
                        <ModernParagraph size="small" className="text-blue-500">
                          {p.key}
                        </ModernParagraph>
                      </button>
                    ) : null}
                  </div>

                  {p.icon ? (
                    <img
                      src={
                        HIDDEN_VALLEY_PSW_ICONS[
                          p.icon as keyof typeof HIDDEN_VALLEY_PSW_ICONS
                        ].publicPath
                      }
                      alt={p.icon}
                      className="w-5 h-5"
                    />
                  ) : null}
                </div>
                <div className="col-span-1 flex flex-row justify-center items-center gap-3">
                  <img
                    src={
                      ENCRYPT_METHODS[
                        p.encryptLevel as keyof typeof ENCRYPT_METHODS
                      ].imgPublicPath
                    }
                    alt={p.encryptLevel}
                    className="w-5 h-5"
                  />
                  <ModernParagraph
                    size="base"
                    className="text-White"
                    gradient={
                      ENCRYPT_METHODS[
                        p.encryptLevel as keyof typeof ENCRYPT_METHODS
                      ].gradient
                    }
                    gradientDirection={
                      ENCRYPT_METHODS[
                        p.encryptLevel as keyof typeof ENCRYPT_METHODS
                      ].gradientDirection
                    }
                  >
                    {trans(
                      "HIDDEN_VALLEY",
                      ENCRYPT_METHODS[
                        p.encryptLevel as keyof typeof ENCRYPT_METHODS
                      ].selectionName
                    )}
                  </ModernParagraph>
                </div>
                <AnimatePresence mode="wait">
                  {isOpeningMoreOption ? (
                    <AnimateWrapper
                      as="div"
                      animateType="opacity"
                      key={"more-options"}
                      className="col-span-1 flex flex-row justify-end items-center gap-3"
                    >
                      {isDeletingPassword &&
                      deletePasswordTargeted.current === p.passwordId ? (
                        <DotSpinnerLoader />
                      ) : (
                        <IconBtn
                          iconClassName="fi-ss-trash-xmark"
                          className="text-White opacity-65 hover:text-Red hover:opacity-100 transition-all"
                          onClick={() => {
                            deletePasswordTargeted.current = p.passwordId;
                            setIsAlertDeleteActive(true);
                          }}
                          disabled={isDeletingPassword}
                        />
                      )}

                      <IconBtn
                        iconClassName="fi-rs-circle-ellipsis"
                        className="text-White pr-[1px] opacity-65 hover:opacity-100 transition-opacity"
                        onClick={() => setCurrentPasswordMoreOption(null)}
                      />
                    </AnimateWrapper>
                  ) : (
                    <AnimateWrapper
                      as="div"
                      animateType="opacity"
                      key={"password-interactive"}
                      className="col-span-1 flex flex-row justify-end items-center gap-3"
                    >
                      {isUnveil ? (
                        <ModernParagraph
                          size="base"
                          className="text-White py-[0.15rem] line-clamp-1 overflow-ellipsis block"
                        >
                          {unveiledPassword.value}
                        </ModernParagraph>
                      ) : (
                        <ModernParagraph
                          size="base"
                          className="text-White pt-[0.3rem]"
                        >
                          ********
                        </ModernParagraph>
                      )}
                      <IconBtn
                        iconClassName={
                          isUnveil ? "fi-sr-eye" : "fi-sr-eye-crossed"
                        }
                        className="text-White opacity-65 hover:opacity-100 transition-opacity"
                        onClick={() =>
                          handleUnveilPassword(isUnveil, p.passwordId, p.value)
                        }
                      />
                      <IconBtn
                        iconClassName="fi-sr-copy"
                        className="text-White opacity-65 hover:opacity-100 transition-opacity"
                        onClick={() => handleCopyPassword(p.value)}
                      />
                      <IconBtn
                        iconClassName="fi-rs-circle-ellipsis"
                        className="text-White pr-[1px] opacity-65 hover:opacity-100 transition-opacity"
                        onClick={() =>
                          setCurrentPasswordMoreOption(p.passwordId)
                        }
                      />
                    </AnimateWrapper>
                  )}
                </AnimatePresence>
              </Reorder.Item>
            );
          })}
        </AnimatePresence>
      </Reorder.Group>
      <Suspense>
        <AnimatePresence mode="wait">
          {isAlertDeleteActive ? (
            <AlertDialog
              title={trans(
                "HIDDEN_VALLEY",
                "dashboard.alert-dialog-confirm-delete-title"
              )}
              description={trans(
                "HIDDEN_VALLEY",
                "dashboard.alert-dialog-confirm-delete-description"
              )}
              onConfirm={handleDeletePassword}
              handleClose={() => setIsAlertDeleteActive(false)}
            />
          ) : null}
        </AnimatePresence>
      </Suspense>
    </>
  );
}

export default memo(HiddenValleyPasswordsList);
