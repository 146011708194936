import { z } from "zod";

export interface IMedal {
  medalId: string;
  medalName: string;
  medalUrl: string;
  awardedAt: string;
}

export const MedalSchema = z.object({
  medalId: z.string(),
  medalName: z.string(),
  medalUrl: z.string(),
  awardedAt: z.string(),
});
