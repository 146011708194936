import {
  BluelyraService,
  TSearchYoutubeVideosParams,
  TSearchYoutubeVideosResponse,
} from "@business-layer/services";
import { useMutation } from "@tanstack/react-query";

const service = new BluelyraService();

export const useSearchYoutubeVideosMutation = () => {
  return useMutation<
    TSearchYoutubeVideosResponse,
    Error,
    TSearchYoutubeVideosParams,
    unknown
  >({
    mutationFn: service.searchYoutubeVideos,
  });
};
