import { TLang } from "@constants/lang.constants";

export function getHeadingFontConfig(lang: TLang) {
  switch (lang) {
    case TLang.VI:
      return {
        fontFamily: "SignikaNegative",
        fontWeight: 600,
      };
    case TLang.EN:
    case TLang.RU:
      return {
        fontFamily: "Acme",
        fontWeight: 500,
      };
    default:
      return {
        fontFamily: "inherit",
        fontWeight: 600,
      };
  }
}

export function getModernParagraphFontConfig(lang: TLang) {
  switch (lang) {
    case TLang.VI:
      return {
        fontFamily: "SignikaNegative",
        fontWeight: 400,
      };
    case TLang.EN:
    case TLang.RU:
      return {
        fontFamily: "Acme",
        fontWeight: 500,
      };
    default:
      return {
        fontFamily: "inherit",
        fontWeight: 500,
      };
  }
}

export type TParagraphFontSize =
  | "minion"
  | "small"
  | "base"
  | "medium"
  | "large"
  | "xlarge";
export function getParagraphFontSize(size: TParagraphFontSize): string {
  switch (size) {
    case "minion":
      return "0.5rem";
    case "small":
      return "0.65rem";
    case "base":
      return "0.75rem";
    case "medium":
      return "1rem";
    case "large":
      return "1.5rem";
    case "xlarge":
      return "2rem";
    default:
      return "inherit";
  }
}
