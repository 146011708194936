// import { isDevelopment } from "./axios";

import { isDevelopment } from "./axios";

const serverApi = process.env.REACT_APP_SERVER_BASE_URL ?? "";
const localApi = `http://localhost:2014`;

export const getApiUrl = (isProduction?: boolean) => {
  return !isDevelopment() || isProduction ? serverApi : localApi;
};
