import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { TBluelyraContextData } from "./type";
import { getLocalStorage } from "@utils/helpers";

const initialState: TBluelyraContextData["profile"] = {
  currentVideo: getLocalStorage(`f7581e5f7f766df16bb70330809f00a9`),
  playlist: getLocalStorage(`84981a10181dee4e54a9e21eb6178f5f`),
};

const slice = createSlice<
  TBluelyraContextData["profile"],
  SliceCaseReducers<TBluelyraContextData["profile"]>,
  "bluelyra-profile",
  SliceSelectors<TBluelyraContextData["profile"]>
>({
  name: "bluelyra-profile",
  initialState,
  reducers: {
    setBluelyraProfile: (
      state: TBluelyraContextData["profile"],
      action: PayloadAction<TBluelyraContextData["profile"]>
    ) => {
      return action.payload;
    },
    setBluelyraCurrentVideo: (
      state: TBluelyraContextData["profile"],
      action: PayloadAction<TBluelyraContextData["profile"]["currentVideo"]>
    ) => {
      return {
        ...state,
        currentVideo: action.payload,
      };
    },
    setBluelyraPlaylist: (
      state: TBluelyraContextData["profile"],
      action: PayloadAction<TBluelyraContextData["profile"]["playlist"]>
    ) => {
      return {
        ...state,
        playlist: action.payload,
      };
    },
  },
});

export const {
  setBluelyraProfile,
  setBluelyraCurrentVideo,
  setBluelyraPlaylist,
} = slice.actions;
export default slice.reducer;
