import React, { useState, useEffect, useMemo, useRef, memo } from "react";
import "@presentational/styles/animation.scss";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { COLOR_THEME, TColor } from "@presentational/themes/colors";

type TProps = {
  words: string[];
  typeSpeed?: number;
  deleteSpeed?: number;
  delayAfterWords?: number;
  infinite?: boolean;
  cursor?: boolean;
  cursorColor?: TColor;
};
const INITIAL_STATE = {
  value: "",
  charIndex: 0,
  wordIndex: 0,
  isTyping: true,
};
const Typewriter: React.FC<TProps> = ({
  words,
  typeSpeed = 100,
  deleteSpeed = 50,
  delayAfterWords = 2000,
  infinite = true,
  cursor = true,
  cursorColor = "White",
}) => {
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { currentLang } = useLanguage();
  const [currentWords, setCurrentWords] = useState<string[]>([""]);
  const [currentText, setCurrentText] = useState<{
    value: string;
    charIndex: number;
    wordIndex: number;
    isTyping: boolean;
  }>(INITIAL_STATE);
  const cursorElement = useMemo(
    () =>
      cursor ? (
        <span
          className="typewriter-cursor"
          style={{
            color: COLOR_THEME[cursorColor],
          }}
        >
          |
        </span>
      ) : null,
    [cursor, cursorColor]
  );

  function handleTyping() {
    setCurrentText((prev) => {
      let { value, charIndex, wordIndex, isTyping } = prev;
      if (isTyping) {
        if (currentWords[wordIndex].length > charIndex) {
          // Keep going
          value = `${value}${currentWords[wordIndex][charIndex]}`;
          ++charIndex;
          timeoutRef.current = setTimeout(handleTyping, typeSpeed);
        } else {
          // Come to delete
          isTyping = false;
          if (currentWords.length > 1) {
            if (currentWords.length > wordIndex + 1) {
              // Reset to new word
              ++wordIndex;
            } else if (currentWords.length >= wordIndex + 1) {
              wordIndex = 0;
            }
          }

          infinite &&
            (timeoutRef.current = setTimeout(
              handleTyping,
              deleteSpeed + delayAfterWords
            ));
        }
      } else {
        if (charIndex > 0) {
          // Keep going
          value = value.slice(0, -1);
          --charIndex;
          timeoutRef.current = setTimeout(handleTyping, deleteSpeed);
        } else {
          // Come to delete
          isTyping = true;
          if (currentWords.length > 1) {
            if (currentWords.length > wordIndex + 1) {
              // Reset to new word
              wordIndex = wordIndex + 1;
            } else if (currentWords.length >= wordIndex + 1) {
              wordIndex = 0;
            }
          }
          timeoutRef.current = setTimeout(handleTyping, typeSpeed);
        }
      }

      return {
        value,
        charIndex,
        wordIndex,
        isTyping,
      };
    });
  }
  function stopAndReset() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setCurrentText(INITIAL_STATE);
    handleTyping();
  }
  useEffect(() => {
    currentWords[0].length > 0 && stopAndReset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentLang, currentWords]);
  useEffect(() => {
    if (
      words.length > currentWords.length ||
      words.some((w, index) => w !== currentWords[index])
    ) {
      setCurrentWords(words);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [words]);

  return (
    <span className="typewriter-container">
      <span>{currentText.value}</span>
      {cursorElement}
    </span>
  );
};

export default memo(Typewriter);
