import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useGetCurrentWeather } from "@business-layer/business-logic/lib/weather";
import { IOpenWeatherResponse } from "@business-layer/services/entities";
import { DotSpinnerLoader, ModernParagraph } from "@presentational/atoms";
import { useEffect, useState } from "react";

function WeatherWidget() {
  const { onForecast } = useGetCurrentWeather();
  const [weatherData, setWeatherData] = useState<
    IOpenWeatherResponse | undefined | null
  >(undefined);
  const { trans } = useLanguage();

  useEffect(() => {
    onForecast()
      .then((result) => setWeatherData(result))
      .catch((error) => setWeatherData(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="w-[15rem] h-[12rem] rounded-t-[2.4rem] rounded-bl-[10rem] rounded-br-[2rem] absolute bottom-[0.35rem] left-[0.35rem] z-10 overflow-hidden">
      <div className="w-full h-full relative">
        <div className="w-full h-full bg-Secondary opacity-90 absolute top-0 left-0 z-0"></div>
        <div className="pl-2 pt-1 w-full h-full z-10 relative flex flex-col items-center">
          {weatherData ? (
            <>
              <div className="flex flex-row gap-4 items-center justify-center">
                <img
                  src="/assets/imgs/icons/sun-and-cloud.svg"
                  alt="weather forcast"
                  className="w-[7rem] h-[7rem]"
                />
                <div>
                  <p
                    className="text-White text-4xl"
                    style={{ fontFamily: "NerkoOne" }}
                  >
                    {weatherData.main.temp.toFixed(0)}°C
                  </p>
                  <ModernParagraph
                    size="base"
                    className="text-White capitalize"
                  >
                    {weatherData.weather[0].description}
                  </ModernParagraph>
                </div>
              </div>
              <div className="flex flex-row items-center justify-end gap-1 w-[70%] ml-auto mr-5 text-White">
                <i className="fi fi-sr-map-marker-home text-xs bottom-0"></i>
                <ModernParagraph size="small" className="line-clamp-1">
                  {weatherData.name}, {weatherData.sys.country}
                </ModernParagraph>
              </div>
            </>
          ) : (
            <div>
              <img
                src="/assets/imgs/icons/sun-and-cloud.svg"
                alt="weather forcast"
                className="w-[7rem] h-[7rem]"
              />
              {typeof weatherData === "undefined" ? (
                <div className="flex flex-row items-center justify-center gap-2">
                  <DotSpinnerLoader color="White" size="0.75rem" />
                  <ModernParagraph
                    size="small"
                    className="line-clamp-1 text-center text-White"
                  >
                    {trans("WEATHER", "widget.fetching-data")}
                  </ModernParagraph>
                </div>
              ) : (
                <ModernParagraph
                  size="small"
                  className="line-clamp-1 text-center text-White"
                >
                  {trans("WEATHER", "widget.no-weather-data")}
                </ModernParagraph>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default WeatherWidget;
