import { ModernParagraph, Typewriter } from "../text";
import { memo } from "react";

type TProps = {
  label: string;
};

function SimpleTextLoading({ label }: TProps) {
  return (
    <div className="flex flex-row items-center gap-1">
      <ModernParagraph size="medium" className="text-White">
        {label}
      </ModernParagraph>
      <p className="text-White text-lg mb-[2px]">
        <Typewriter
          words={["..."]}
          typeSpeed={200}
          deleteSpeed={200}
          infinite={true}
          delayAfterWords={0}
          cursor={false}
        />
      </p>
    </div>
  );
}
export default memo(SimpleTextLoading);
