// Importing necessary modules and functions
import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { useGetAllHiddenValleyPasswordMutation } from "../../fetching/mutation";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setPasswords } from "../context";
import { useGetPasswordState } from "./useGetPasswordState";
import { getLocalStorage } from "@utils/helpers";
import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";
import { IHiddenValleyPassword } from "@business-layer/services/entities";

function partition(
  passwords: IHiddenValleyPassword[],
  orderMap: Map<string, number>,
  left: number,
  right: number
): number {
  const pivotValue = orderMap.get(passwords[right].passwordId) ?? Infinity;
  let i = left - 1;

  for (let j = left; j < right; j++) {
    if ((orderMap.get(passwords[j].passwordId) ?? Infinity) <= pivotValue) {
      i++;
      [passwords[i], passwords[j]] = [passwords[j], passwords[i]];
    }
  }

  [passwords[i + 1], passwords[right]] = [passwords[right], passwords[i + 1]];
  return i + 1;
}
function sortPasswordsByOrder(
  passwords: IHiddenValleyPassword[],
  order: string[]
): IHiddenValleyPassword[] {
  const orderMap = new Map(order.map((id, index) => [id, index]));

  // Stack để mô phỏng đệ quy
  const stack: [number, number][] = [[0, passwords.length - 1]];

  while (stack.length > 0) {
    const [left, right] = stack.pop()!;

    if (left >= right) continue;

    const pivot = partition(passwords, orderMap, left, right);

    // Đẩy phân đoạn nhỏ hơn vào stack trước để giảm thiểu stack size
    if (pivot - left < right - pivot) {
      stack.push([pivot + 1, right]);
      stack.push([left, pivot - 1]);
    } else {
      stack.push([left, pivot - 1]);
      stack.push([pivot + 1, right]);
    }
  }
  return passwords;
}
export const useFetchAllHiddenValleyPasswords = () => {
  const token = useGetToken();
  const { mutateAsync } = useGetAllHiddenValleyPasswordMutation();
  const dispatch = useDispatch();
  const passwords = useGetPasswordState();
  useEffect(() => {
    if (passwords === null) {
      mutateAsync({ token })
        .then((res) => {
          const passwordOrder: string[] | null = getLocalStorage(
            LOCAL_STORAGE_KEYS.HIDDEN_VALLEY_PASSWORDS_ORDER
          );
          if (passwordOrder) {
            dispatch(setPasswords(sortPasswordsByOrder(res, passwordOrder)));
          } else {
            dispatch(setPasswords(res));
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    data: passwords,
  };
};
