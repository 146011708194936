import { memo } from "react";
import "./styles.scss";
import { COLOR_THEME, TColor } from "@presentational/themes/colors";

type TProps = {
  color?: TColor;
  size?: string;
  thick?: string;
};
function DotSpinnerLoader({
  color = "Gray",
  size = "1.25rem",
  thick = "0.15rem",
}: TProps) {
  return (
    <div className="flex flex-row items-center gap-3">
      <div
        className="circle-spinner rounded-full border-solid"
        style={{
          borderWidth: thick,
          borderColor: COLOR_THEME[color],
          borderLeftColor: COLOR_THEME.Transparent,
          width: size,
          height: size,
        }}
      ></div>
    </div>
  );
}
export default memo(DotSpinnerLoader);
