import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";
import { IHiddenValleyPassword } from "@business-layer/services/entities";
import { setLocalStorage } from "@utils/helpers";
import { useDispatch } from "react-redux";
import { setPasswords } from "../context";

export const useUpdatePasswordOrder = () => {
  const dispatch = useDispatch();
  function onReOrder(passwords: IHiddenValleyPassword[]) {
    dispatch(setPasswords(passwords));
    setLocalStorage(
      LOCAL_STORAGE_KEYS.HIDDEN_VALLEY_PASSWORDS_ORDER,
      passwords.map((p) => p.passwordId)
    );
  }
  return {
    onReOrder,
  };
};
