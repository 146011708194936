import { useState, useMemo, memo } from "react";
import { Heading } from "../text";

const HIDDEN_PASSWORD = "******";

function AuthBoxChat({
  content,
  type,
}: {
  content: string;
  type: "text" | "password";
}) {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const displayContent = useMemo(() => {
    return type === "password" && !isPasswordVisible
      ? HIDDEN_PASSWORD
      : content;
  }, [content, type, isPasswordVisible]);

  const togglePasswordVisibility = () => {
    setIsPasswordVisible((prev) => !prev);
  };

  return (
    <div className="bg-Primary rounded-xl rounded-br-none min-w-32 min-h-12 grid place-items-center px-8">
      {type === "password" ? (
        <div className="flex flex-row gap-3 justify-center items-center">
          <Heading
            as="h3"
            className="text-White font-light text-center text-base"
          >
            {displayContent}
          </Heading>
          <button className="text-White" onClick={togglePasswordVisibility}>
            <i
              className={`fi ${
                isPasswordVisible ? "fi-ss-eye-crossed" : "fi-ss-eye"
              }`}
            ></i>
          </button>
        </div>
      ) : (
        <Heading
          as="h3"
          className="text-White font-light text-center text-base"
        >
          {displayContent}
        </Heading>
      )}
    </div>
  );
}

export default memo(AuthBoxChat);
