import React, { memo, ReactNode } from "react";
import {
  getParagraphFontSize,
  TParagraphFontSize,
} from "@utils/helpers/font.helper";

type TProps = {
  children: ReactNode;
  className?: string;
  size: TParagraphFontSize;
};

function NerkoText({ children, className, size }: TProps) {
  const style = {
    fontFamily: "NerkoOne",
    fontSize: getParagraphFontSize(size),
  };

  return React.createElement("p", { className, style }, children);
}
export default memo(NerkoText);
