import {
  CommonButton,
  EmptyBlueberry,
  ModernParagraph,
  NoUnmountAnimateWrapper,
} from "@presentational/atoms";
import { COLOR_THEME } from "@presentational/themes/colors";
import { useCallback, useEffect, useRef, useState } from "react";
import { debounce } from "@utils/helpers";
import {
  useChangeBluelyraIsPlaying,
  useChangeBluelyraPlayed,
  useChangeBluelyraSeekTo,
  useChangeBluelyraVolume,
  useGetBluelyraProcess,
  usePlayVideoInPlaylist,
} from "@business-layer/business-logic/lib/bluelyra";
import { useGetBluelyraProfile } from "@business-layer/business-logic/lib/bluelyra";

function YoutubeMusicPlayer({
  onOpenBluelyraPlaylist,
}: {
  onOpenBluelyraPlaylist: () => void;
}) {
  const { currentVideo: videoInfo, playlist } = useGetBluelyraProfile();
  const { isPlaying, played, volume } = useGetBluelyraProcess();
  const { onChange: setIsPlaying } = useChangeBluelyraIsPlaying();
  const { onChange: setPlayed } = useChangeBluelyraPlayed();
  const { onSeekTo } = useChangeBluelyraSeekTo();
  const { onChange: setVolume } = useChangeBluelyraVolume();
  const { onNext, onPrev, setCurrentVideo } = usePlayVideoInPlaylist();
  const [isHoveringVolume, setIsHoveringVolume] = useState<boolean>(false);
  const [isHoveringThumbnail, setIsHoveringThumbnail] =
    useState<boolean>(false);
  const changePlayedContainerRef = useRef<HTMLDivElement>(null);
  const playedBtnRef = useRef<HTMLDivElement>(null);
  const changeVolumeContainerRef = useRef<HTMLDivElement>(null);
  const volumeBtnRef = useRef<HTMLDivElement>(null);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };
  const handlePlayedMouseMove = useCallback((event: any) => {
    if (changePlayedContainerRef.current && playedBtnRef.current) {
      const { left, width: containerWidth } =
        changePlayedContainerRef.current.getBoundingClientRect();
      const { width } = playedBtnRef.current.getBoundingClientRect();
      const offsetX = event.clientX - left;
      const newPlayed = Math.min(
        Math.max((offsetX - width / 2) / containerWidth, 0),
        1
      );
      setPlayed(newPlayed);
      onSeekTo(newPlayed);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePlayedMouseDown = (event: any) => {
    event.preventDefault();
    document.addEventListener("mousemove", handlePlayedMouseMove);
    document.addEventListener("mouseup", handlePlayedMouseUp);
  };
  const handlePlayedMouseUp = () => {
    document.removeEventListener("mousemove", handlePlayedMouseMove);
    document.removeEventListener("mouseup", handlePlayedMouseUp);
  };
  const handleVolumeMouseMove = useCallback((event: any) => {
    if (changeVolumeContainerRef.current && volumeBtnRef.current) {
      const { bottom, height: containerHeight } =
        changeVolumeContainerRef.current.getBoundingClientRect();
      const { height } = volumeBtnRef.current.getBoundingClientRect();
      const offsetY = bottom - event.clientY;
      const newVolume = Math.min(
        Math.max((offsetY - height / 2) / containerHeight, 0),
        1
      );
      setVolume(newVolume);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleVolumeMouseDown = (event: any) => {
    document.addEventListener("mousemove", handleVolumeMouseMove);
    document.addEventListener("mouseup", handleVolumeMouseUp);
  };
  const handleVolumeMouseUp = (event: any) => {
    document.removeEventListener("mousemove", handleVolumeMouseMove);
    document.removeEventListener("mouseup", handleVolumeMouseUp);
  };

  useEffect(() => {
    if (!videoInfo && Array.isArray(playlist) && playlist.length > 0) {
      setCurrentVideo(playlist[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="h-[280px] w-full bg-Primary rounded-b-[4rem] rounded-t-[2rem] px-2 flex flex-col gap-3">
      <div className="flex flex-row pt-3 justify-between items-center flex-shrink px-2">
        <div className="flex flex-row items-center gap-2 text-White">
          <i className="fi fi-sr-record-vinyl"></i>
          <ModernParagraph size="base" className="font-bold">
            Bluelyra
          </ModernParagraph>
        </div>
        <div className="flex flex-row items-center justify-end gap-2">
          <button
            type="button"
            className="text-White opacity-60 hover:opacity-100 transition-opacity"
            onClick={onPrev}
          >
            <i className="fi fi-br-rewind"></i>
          </button>
          <button
            type="button"
            className="text-White opacity-60 hover:opacity-100 transition-opacity"
            onClick={handlePlayPause}
          >
            <i className={`fi ${isPlaying ? "fi-br-pause" : "fi-br-play"}`}></i>
          </button>
          <button
            type="button"
            className="text-White opacity-60 hover:opacity-100 transition-opacity"
            onClick={onNext}
          >
            <i className="fi fi-br-forward"></i>
          </button>
        </div>
      </div>
      {videoInfo ? (
        <div
          className="flex-grow flex flex-col justify-end items-center w-full bg-center bg-cover bg-no-repeat mb-2 rounded-t-[1.5rem] rounded-b-[3.65rem] overflow-hidden"
          onMouseEnter={() => setIsHoveringThumbnail(true)}
          onMouseLeave={() => setIsHoveringThumbnail(false)}
          style={{
            backgroundImage: `url('${videoInfo.snippet.thumbnails.medium.url}')`,
          }}
        >
          <NoUnmountAnimateWrapper
            as="div"
            key="YoutubeMusicPlayer-progressBar"
            className="w-full pt-5"
            variants={{
              initial: {
                opacity: 0,
              },
              animate: { opacity: 1 },
              exit: {
                opacity: 0,
              },
            }}
            animate={isHoveringThumbnail ? "animate" : "exit"}
            style={{
              background: `linear-gradient(to top, ${COLOR_THEME.Dark}, ${COLOR_THEME.Transparent})`,
            }}
          >
            <div
              className="w-full flex flex-row items-center justify-center px-5 mb-2 gap-2 transition-opacity"
              style={{
                opacity: isHoveringVolume ? 0 : 1,
              }}
            >
              <img
                src="/assets/imgs/icons/youtube.svg"
                alt="youtube"
                className="w-4 h-4"
              />
              <ModernParagraph
                size="small"
                className="line-clamp-1 text-White text-justify"
              >
                {videoInfo.snippet.title}
              </ModernParagraph>
            </div>
            <div className="w-full">
              <div className="w-[60%] mx-auto mb-5 flex flex-row items-center gap-2">
                <div
                  className="relative flex-grow"
                  ref={changePlayedContainerRef}
                >
                  <hr className="w-full bg-White h-[2px]" />
                  <div
                    onMouseDown={handlePlayedMouseDown}
                    ref={playedBtnRef}
                    className="w-3 h-3 rounded-full bg-White absolute top-1/2 -translate-y-1/2 cursor-pointer"
                    style={{ left: `${played * 100}%` }}
                  ></div>
                </div>
                <button
                  type="button"
                  className="flex-shrink relative px-2"
                  onMouseEnter={debounce(() => setIsHoveringVolume(true), 100)}
                  onMouseLeave={debounce(() => setIsHoveringVolume(false), 100)}
                >
                  <i className="fi fi-br-volume text-White"></i>
                  <NoUnmountAnimateWrapper
                    as="div"
                    key={"volume-changer"}
                    isLayoutAnimation={true}
                    variants={{
                      initial: {
                        opacity: 0,
                        height: 0,
                      },
                      animate: {
                        opacity: 1,
                        height: "3rem",
                      },
                      exit: {
                        opacity: 0,
                        height: 0,
                      },
                    }}
                    animate={isHoveringVolume ? "animate" : "exit"}
                    className="absolute bottom-full left-[100%] -translate-x-[75%] w-[200%] pt-4"
                  >
                    <div
                      className="relative w-full h-full grid place-items-center"
                      ref={changeVolumeContainerRef}
                    >
                      <div className="w-[3px] h-full bg-White" />
                      <div
                        onMouseDown={handleVolumeMouseDown}
                        ref={volumeBtnRef}
                        className="w-3 h-3 rounded-full bg-White absolute left-1/2 -translate-x-1/2 cursor-pointer"
                        style={{ bottom: `${volume * 100}%` }}
                      ></div>
                    </div>
                  </NoUnmountAnimateWrapper>
                </button>
                <button
                  type="button"
                  className="flex-shrink relative"
                  onClick={onOpenBluelyraPlaylist}
                >
                  <i className="fi fi-br-sign-in-alt text-White"></i>
                </button>
              </div>
            </div>
          </NoUnmountAnimateWrapper>
        </div>
      ) : (
        <div className="bg-Secondary flex-grow flex flex-col justify-center items-center w-full mb-2 pb-2 rounded-t-[1.5rem] rounded-b-[3.65rem] overflow-hidden">
          <div className="mx-auto opacity-70 max-w-[50%]">
            <EmptyBlueberry />
          </div>
          <CommonButton
            theme="Pink"
            size="small"
            className="rounded-full opacity-70 hover:opacity-100 transition-all"
            onClick={onOpenBluelyraPlaylist}
          >
            + Thêm nhạc
          </CommonButton>
        </div>
      )}
    </div>
  );
}

export default YoutubeMusicPlayer;
