import { useDispatch } from "react-redux";
import {
  setBluelyraIsPlaying,
  setBluelyraPlaylist,
  setBluelyraProfile,
  TBluelyraContextData,
} from "../context";
import {
  IYouTubeSearchResultItem,
  IYouTubeVideoItem,
} from "@business-layer/services/entities";
import { useGetBluelyraProfile } from "./useGetBluelyraProfile";
import { setLocalStorage } from "@utils/helpers";
import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";

function convertSearchResultToVideoItem(
  searchResult: IYouTubeSearchResultItem
): IYouTubeVideoItem {
  return {
    kind: searchResult.kind,
    etag: searchResult.etag,
    id: searchResult.id.videoId || "", // Sử dụng videoId nếu có
    snippet: {
      publishedAt: searchResult.snippet.publishedAt,
      channelId: searchResult.snippet.channelId,
      title: searchResult.snippet.title,
      description: searchResult.snippet.description,
      thumbnails: {
        default: searchResult.snippet.thumbnails.default,
        medium: searchResult.snippet.thumbnails.medium,
        high: searchResult.snippet.thumbnails.high,
        standard: undefined,
        maxres: undefined,
      },
      channelTitle: searchResult.snippet.channelTitle,
      tags: [],
      categoryId: "",
      liveBroadcastContent: searchResult.snippet.liveBroadcastContent,
      localized: {
        title: searchResult.snippet.title,
        description: searchResult.snippet.description,
      },
      defaultAudioLanguage: undefined,
    },
  };
}

export const useHandleBluelyraPlaylist = () => {
  const dispatch = useDispatch();
  const { playlist, currentVideo } = useGetBluelyraProfile();

  // Internal
  function handleSetProfile(profile: TBluelyraContextData["profile"]) {
    dispatch(setBluelyraProfile(profile));

    setLocalStorage(
      LOCAL_STORAGE_KEYS.BLUELYRA_CURRENT_SONG,
      profile.currentVideo
    );
  }

  // External
  function onSet(playist: IYouTubeVideoItem[]) {
    dispatch(setBluelyraPlaylist(playist));
    setLocalStorage(LOCAL_STORAGE_KEYS.BLUELYRA_PLAYLIST, playist);
  }
  function onAdd(video: IYouTubeSearchResultItem) {
    const videoItem = convertSearchResultToVideoItem(video);
    // Video is exist
    if (
      playlist &&
      playlist.length > 0 &&
      playlist.some((p) => p.id === videoItem.id)
    ) {
      return;
    }
    const newPlaylist = [...(playlist ?? []), videoItem];

    if (!playlist || playlist.length === 0) {
      handleSetProfile({
        currentVideo: videoItem,
        playlist: newPlaylist,
      });
      dispatch(setBluelyraIsPlaying(true));
    } else {
      onSet(newPlaylist);
    }
  }
  function onRemove(videoIndex: number) {
    if (
      !playlist ||
      playlist.length === 0 ||
      videoIndex < 0 ||
      videoIndex >= playlist.length
    ) {
      return;
    }
    const newPlaylist = playlist.filter((_, index) => index !== videoIndex);
    if (currentVideo && playlist[videoIndex].id === currentVideo.id) {
      handleSetProfile({
        currentVideo: newPlaylist.length > 0 ? newPlaylist[0] : null,
        playlist: newPlaylist,
      });
      dispatch(setBluelyraIsPlaying(false));
    } else {
      onSet(newPlaylist);
    }
  }
  return {
    onSet,
    onAdd,
    onRemove,
  };
};
