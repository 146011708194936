import { COOKIE_KEYS } from "@business-layer/business-logic/configs";
import { getCookie } from "../helper/cookieHelper";
import { useAuthContext } from "../context";

export const useLoadToken = () => {
  const { dispatch } = useAuthContext();

  function handleLoadToken() {
    const token = getCookie(COOKIE_KEYS.ACCESS_TOKEN);
    const refreshToken = getCookie(COOKIE_KEYS.REFRESH_TOKEN);
    dispatch({
      type: "SET_ALL",
      payload: { token, refreshToken },
    });
    return {
      token,
      refreshToken,
    };
  }

  return { handleLoadToken };
};
