import { TEditAccountDetailProps } from "@business-layer/services";
import { useEditAccountDetailMutation } from "../../fetching/mutations";
import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useAccountDetail } from "./useAccountDetail";
import { useDispatch } from "react-redux";
import { setAccountDetail } from "../context";

export const useEditAccountDetail = () => {
  const { mutateAsync, isPending } = useEditAccountDetailMutation();
  const token = useGetToken();
  const { trans } = useLanguage();
  const { data } = useAccountDetail();
  const dispatch = useDispatch();

  async function onEdit(props: Omit<TEditAccountDetailProps, "token">) {
    try {
      await mutateAsync({ ...props, token });
      dispatch(setAccountDetail({ ...data, ...props }));
    } catch (error: any) {
      throw new Error(trans("SERVER", error.message));
    }
  }
  return {
    onEdit,
    isLoading: isPending,
  };
};
