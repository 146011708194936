import { AnimatePresence } from "framer-motion";
import { SingleSectionTemplate } from "@presentational/templates";
import { useState } from "react";
import {
  AuthHeader,
  MethodSelectChat,
  LoginChat,
  RegisterChat,
} from "@presentational/organisms";

type TAuthMethod = "login" | "register" | "google" | null;

function Login() {
  const [authType, setAuthType] = useState<TAuthMethod>(null);
  const [isGoingOut, setIsGoingOut] = useState<boolean>(false);

  function onSelectAuthMethod(method: TAuthMethod) {
    setAuthType(method);
  }

  return (
    <SingleSectionTemplate>
      <AnimatePresence mode="wait">
        {isGoingOut ? null : (
          <>
            <AuthHeader setIsGoingOut={setIsGoingOut} />
            <div className="relative w-1/2 h-full flex flex-col justify-between gap-4 mx-auto pointer-events-none">
              <AnimatePresence mode="wait">
                {authType === null || authType === "google" ? (
                  <MethodSelectChat
                    onSelect={onSelectAuthMethod}
                    key="method-select"
                  />
                ) : authType === "login" ? (
                  <LoginChat key="login-chat" />
                ) : authType === "register" ? (
                  <RegisterChat key="register-chat" />
                ) : null}
              </AnimatePresence>
            </div>
          </>
        )}
      </AnimatePresence>
    </SingleSectionTemplate>
  );
}

export default Login;
