import { Variants } from "framer-motion";

export const BluelyraPlaylistVariants: { [key: string]: Variants } = {
  playlistItem: {
    initial: { opacity: 0, scale: 0.5 },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.3,
        ease: "easeInOut",
      },
    },
    exit: {
      opacity: 0,
      scale: 0.5,
    },
  },
};
