import { ACCOUNT_ENDPOINTS } from "../../config/apis";
import { Services } from "../../service";
import {
  SEditAccountDetailResponse,
  SGetAccountDetailResponse,
} from "./schema";
import {
  TGetAccountDetailResponse,
  TGetAccountDetailProps,
  TEditAccountDetailProps,
  TEditAccountDetailResponse,
} from "./type";

export * from "./type";
export class AccountService extends Services {
  getAccountDetail = async ({
    token,
  }: TGetAccountDetailProps): Promise<TGetAccountDetailResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SGetAccountDetailResponse,
          TGetAccountDetailResponse
        >({
          ...ACCOUNT_ENDPOINTS.getAccountDetail,
          schema: SGetAccountDetailResponse,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  editAccountDetail = async ({
    token,
    ...data
  }: TEditAccountDetailProps): Promise<TEditAccountDetailResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SEditAccountDetailResponse,
          TEditAccountDetailResponse
        >({
          ...ACCOUNT_ENDPOINTS.editAccountDetail,
          schema: SEditAccountDetailResponse,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
          signal: this.abortController.signal,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
