import { z } from "zod";
import { IPreviewPlan, PreviewPlanSchema } from "./plan";
import { IPreviewDiary, PreviewDiarySchema } from "./diary";
import { IMedal, MedalSchema } from "./medal";

export interface IAccountDetail {
  accountId: string;
  avatar: string;
  email: string;
  username: string;
  createdAt: string;
  description: string | null;
  plan: IPreviewPlan;
  friends: {
    friendId: string;
    avatar: string;
    username: string;
    createdAt: string;
  }[];
  medals: IMedal[];
  diaries: IPreviewDiary[];
}
export const AccountDetailSchema = z.object({
  accountId: z.string(),
  avatar: z.string(),
  email: z.string(),
  username: z.string(),
  createdAt: z.string(),
  plan: PreviewPlanSchema,
  description: z.string().nullable(),
  friends: z.array(
    z.object({
      friendId: z.string(),
      avatar: z.string(),
      username: z.string(),
      createdAt: z.string(),
    })
  ),
  medals: z.array(MedalSchema),
  diaries: z.array(PreviewDiarySchema),
});
