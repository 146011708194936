import { HiddenValleyPasswordSchema } from "@business-layer/services/entities/password";
import z from "zod";

const SGetAllPasswordsResponse = z.array(HiddenValleyPasswordSchema);
const SAddNewPasswordResponse = z.object({
  createdPassword: HiddenValleyPasswordSchema,
});
const SDeletePasswordResponse = z.object({
  message: z.string(),
});

export {
  SGetAllPasswordsResponse,
  SAddNewPasswordResponse,
  SDeletePasswordResponse,
};
