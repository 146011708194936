import { useDispatch, useSelector } from "react-redux";
import {
  setBluelyraCurrentVideo,
  setBluelyraIsPlaying,
  TBluelyraContextData,
} from "../context";
import { IYouTubeVideoItem } from "@business-layer/services/entities";
import { useGetBluelyraProfile } from "./useGetBluelyraProfile";
import { LOCAL_STORAGE_KEYS } from "@business-layer/business-logic/configs";
import { setLocalStorage } from "@utils/helpers";

export const usePlayVideoInPlaylist = () => {
  const dispatch = useDispatch();
  const { isLoop, isPlaying } = useSelector<
    TBluelyraContextData,
    TBluelyraContextData["process"]
  >((state) => state.process);
  const { playlist, currentVideo } = useGetBluelyraProfile();

  // Internal
  function setFirstVideoOfPlaylist(playlist: IYouTubeVideoItem[]) {
    dispatch(setBluelyraCurrentVideo(playlist[0]));
    setLocalStorage(LOCAL_STORAGE_KEYS.BLUELYRA_CURRENT_SONG, playlist[0]);
  }

  // External
  function setCurrentVideo(newVideo: IYouTubeVideoItem) {
    dispatch(setBluelyraCurrentVideo(newVideo));
    setLocalStorage(LOCAL_STORAGE_KEYS.BLUELYRA_CURRENT_SONG, newVideo);
  }
  function onPlay(video: IYouTubeVideoItem) {
    dispatch(setBluelyraCurrentVideo(video));
    dispatch(
      setBluelyraIsPlaying(
        currentVideo && currentVideo.id !== video.id ? true : !isPlaying
      )
    );
  }
  function onNext() {
    // No playlist => end
    if (!playlist || playlist.length === 0) {
      return;
    }
    // No video => set first
    if (!currentVideo) {
      setFirstVideoOfPlaylist(playlist);
      return;
    }

    const videoIndex = playlist.findIndex(({ id }) => id === currentVideo.id);
    if (videoIndex >= 0) {
      setCurrentVideo(playlist[(videoIndex + 1) % playlist.length]);
    } else {
      // Video not found in playlist => set first
      setFirstVideoOfPlaylist(playlist);
      return;
    }
  }
  function onPrev() {
    // No playlist => end
    if (!playlist || playlist.length === 0) {
      return;
    }
    // No video => set first
    if (!currentVideo) {
      setFirstVideoOfPlaylist(playlist);
      return;
    }

    const videoIndex = playlist.findIndex(({ id }) => id === currentVideo.id);
    if (videoIndex >= 0) {
      setCurrentVideo(
        playlist[(videoIndex - 1 + playlist.length) % playlist.length]
      );
    } else {
      // Video not found in playlist => set first
      setFirstVideoOfPlaylist(playlist);
      return;
    }
  }
  function onEnd() {
    // No playlist => end
    if (!playlist || playlist.length === 0) {
      return;
    }
    // No video => set first
    if (!currentVideo) {
      setFirstVideoOfPlaylist(playlist);
      dispatch(setBluelyraIsPlaying(true));
      return;
    }

    const videoIndex = playlist.findIndex(({ id }) => id === currentVideo.id);
    if (videoIndex >= 0) {
      if (isLoop) {
        setCurrentVideo(playlist[(videoIndex + 1) % playlist.length]);
        dispatch(setBluelyraIsPlaying(true));
      } else if (videoIndex < playlist.length - 1) {
        setCurrentVideo(playlist[videoIndex + 1]);
        dispatch(setBluelyraIsPlaying(true));
      } else {
        setFirstVideoOfPlaylist(playlist);
        dispatch(setBluelyraIsPlaying(false));
      }
    } else {
      // Video not found in playlist => set first
      setFirstVideoOfPlaylist(playlist);
      dispatch(setBluelyraIsPlaying(true));
      return;
    }
  }
  return { setCurrentVideo, onPlay, onNext, onPrev, onEnd };
};
