import Cookies from "js-cookie";

export const setCookie = (key: string, value: any, expires?: any): void => {
  Cookies.set(key, value, { expires: new Date(expires) });
};

export const getCookie = (key: string): any => {
  const value = Cookies.get(key);
  return value !== undefined ? value : null;
};

export const removeCookie = (key: string): void => {
  Cookies.remove(key);
};
