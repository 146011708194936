import { NavigateOptions, useNavigate } from "react-router-dom";
import { useLanguage } from "./useLanguage";

export const useNavigateWithCurrentLang = () => {
  const { currentLang } = useLanguage();
  const navigate = useNavigate();
  function getHref(url: string) {
    return `/${currentLang}${url}`;
  }
  function handleNavigate(url: string, options?: NavigateOptions) {
    navigate(`/${currentLang}${url}`, options);
  }
  return {
    getHref,
    handleNavigate,
  };
};
