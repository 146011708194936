/* eslint-disable @typescript-eslint/no-unused-vars */
import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { useGetCurrentWeatherMutation } from "../../fetching/mutations";
import { IOpenWeatherResponse } from "@business-layer/services/entities";
import { useDispatch, useSelector } from "react-redux";
import { setAllWeatherData, TWeatherState } from "../context";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { setLocalStorage } from "@utils/helpers";
import { WEATHER_LOCAL_STORAGE_KEY } from "../../constants";
import { useState } from "react";
import { calcHoursBetweenTimes } from "@utils/helpers/datetime.helper";

export const useGetCurrentWeather = () => {
  const dispatch = useDispatch();
  const { currentLang } = useLanguage();
  const { coord, weather, forecastAt } = useSelector<any, TWeatherState>(
    (state) => state.weather
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutateAsync } = useGetCurrentWeatherMutation();
  const token = useGetToken();

  async function onForecast(): Promise<IOpenWeatherResponse> {
    try {
      // If weather data is exist and not stale
      if (
        forecastAt &&
        calcHoursBetweenTimes(new Date(forecastAt), new Date()) < 5 &&
        weather
      ) {
        return weather;
      }
      let location = {
        city: "Osaka",
        country: "Japan",
        regionName: "",
        lat: 10.762622,
        lon: 106.660172,
      };
      // Fetch new weather data
      setIsLoading(true);
      const result = await mutateAsync({
        lang: currentLang,
        lat: location.lat,
        lon: location.lon,
        units: "metric",
        token,
      });
      const newWeatherData = {
        weather: result,
        coord: [location.lat, location.lon],
        forecastAt: new Date().toISOString(),
      };
      dispatch(setAllWeatherData(newWeatherData));
      setLocalStorage(WEATHER_LOCAL_STORAGE_KEY.WEATHER, newWeatherData);
      return result;
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      setIsLoading(false);
    }
  }

  return {
    onForecast,
    isLoading,
  };
};
