import { useDispatch } from "react-redux";
import { deletePassword } from "../context/passwordReducers";
import { useDeleteHiddenValleyPasswordMutation } from "../../fetching/mutation";
import { TDeletePasswordParams } from "@business-layer/services";
import { useGetToken } from "@business-layer/business-logic/lib/auth";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

export const useDeleteHiddenValleyPassword = () => {
  const dispatch = useDispatch();
  const { mutateAsync, isPending } = useDeleteHiddenValleyPasswordMutation();
  const token = useGetToken();
  const { trans } = useLanguage();

  async function onDelete(
    params: Omit<TDeletePasswordParams, "token">
  ): Promise<string> {
    try {
      const res = await mutateAsync({ ...params, token });
      dispatch(deletePassword(params.id));
      return res.message;
    } catch (error: any) {
      console.error(error);
      throw new Error(trans("SERVER", error.message));
    }
  }
  return {
    onDelete,
    isLoading: isPending,
  };
};
