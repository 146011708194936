import { useMemo } from "react";
import { useAuthContext } from "../context";
import { getCookie } from "../helper/cookieHelper";
import { COOKIE_KEYS } from "@business-layer/business-logic/configs";

export const useGetToken = () => {
  const {
    state: { token },
  } = useAuthContext();
  return useMemo(() => token ?? getCookie(COOKIE_KEYS.ACCESS_TOKEN), [token]);
};
