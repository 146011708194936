type TSize = "small" | "medium" | "large";

type TProps = {
  iconClassName: string;
  className?: string;
  size?: TSize;
  onClick?: () => void;
  disabled?: boolean;
  type?: "button" | "reset" | "submit";
};

function getFontSize(size: TSize): string {
  switch (size) {
    case "small":
      return "0.75rem";
    case "medium":
      return "0.875rem";
    case "large":
      return "1rem";
    default:
      return "inherit";
  }
}

function IconBtn({
  iconClassName,
  className,
  onClick,
  size = "medium",
  disabled,
  type = "button",
}: TProps) {
  return (
    <button
      type={type}
      className={`${className} disabled:cursor-not-allowed`}
      onClick={() => {
        !disabled && onClick && onClick();
      }}
      disabled={disabled}
      style={{
        fontSize: getFontSize(size),
      }}
    >
      <i className={`fi ${iconClassName}`}></i>
    </button>
  );
}

export default IconBtn;
