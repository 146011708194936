import { ZodSchema } from "zod";
import { client, isCancel } from "../config/axios";
import { getApiUrl } from "../config/url";

const unknownError: string = "Unexpected error occurred";
export class Services {
  abortController?: AbortController;

  cancelRequest(): void {
    this.abortController && this.abortController.abort();
  }

  handleError(error: any): Error {
    if (isCancel(error)) {
      console.log("Cancel request: ", error);
      this.cancelRequest();
      return error;
    }
    if (error?.name && error.name === "ZodError") {
      console.error("ZodError: ", { error });
      return new Error(
        `Zod Error: ${error?.response?.data?.message ?? unknownError}`
      );
    } else {
      const message = error?.response?.data?.message;
      console.log("Request failed: ", {
        message,
        error,
      });
      return new Error(message ?? unknownError);
    }
  }

  async fetchApi<U extends ZodSchema<any>, T>({
    method,
    url,
    schema,
    params,
    data,
    headers = {},
    signal,
    transformResponse,
    isProduction,
    isUseProxy,
    withCredentials = false,
  }: {
    method: string;
    url: string;
    schema: U;
    params?: any;
    data?: any;
    headers?: any;
    signal: AbortSignal;
    transformResponse: (res: T) => void;
    isProduction?: boolean;
    isUseProxy?: boolean;
    withCredentials?: boolean;
  }): Promise<any> {
    console.log(isUseProxy ? url : getApiUrl(isProduction) + url);
    const response = await client({
      method,
      url: isUseProxy ? url : getApiUrl(isProduction) + url,
      data,
      params,
      headers,
      signal,
      withCredentials,
    });

    try {
      schema.parse(response.data);
    } catch (error) {
      console.error("ZOD ERROR: ", error);
    }

    return transformResponse(response.data);
  }
}
