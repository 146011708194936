/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  usePreRegister,
  useRegister,
} from "@business-layer/business-logic/lib/auth";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import { APP_URLS } from "@constants/urls.constants";
import {
  AuthBoxChat,
  SimpleTextLoading,
  ModernParagraph,
  Typewriter,
  AnimateWrapper,
  CustomAnimateWrapper,
} from "@presentational/atoms";
import { TColor } from "@presentational/themes/colors";
import { getHeadingFontConfig } from "@utils/helpers/font.helper";
import {
  useYupValidationResolver,
  emailSchema,
  passwordSchema,
  usernameSchema,
} from "@utils/validators/yup";
import { AnimatePresence } from "framer-motion";
import { useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

type TAuthData = {
  email: string;
  password: string;
  username: string;
};
type TLoginForm = {
  input: string;
};
type TMessage = {
  isUser: boolean;
  message: string;
  isLoading?: boolean;
  color?: TColor;
  type: "text" | "password";
};
type TAuthCurrent = "email" | "password" | "username";

type TAuth = {
  current: "email" | "password" | "username";
  inputConfig: {
    placeholder: string;
    type: "email" | "password" | "username";
  };
};
const VARIANTS = {
  loginInput: {
    initial: { opacity: 0, y: "30%" },
    animate: {
      opacity: 1,
      y: "0%",
      transition: { duration: 0.5, ease: "easeInOut", delay: 0.8 },
    },
    exit: {
      opacity: 0,
      y: "30%",
      transition: { duration: 0.5, ease: "easeInOut" },
    },
  },
};
function RegisterChat() {
  const navigate = useNavigate();
  const [isSendMessageDisabled, setIsSendMessageDisabled] =
    useState<boolean>(false);
  const { trans, currentLang } = useLanguage();
  const { onPreRegister } = usePreRegister();
  const { onRegister } = useRegister();
  const authData = useRef<TAuthData>({
    email: "",
    password: "",
    username: "",
  });
  const [authConfig, setAuthConfig] = useState<TAuth>({
    current: "email",
    inputConfig: {
      placeholder: trans("AUTH", "common.email-placeholder"),
      type: "email",
    },
  });
  const [messages, setMessage] = useState<TMessage[]>([]);
  const { toastReactHookFormErrorMsg, toastSuccess, toastError, toastPromise } =
    useNotification();
  const emailResolver = useYupValidationResolver(emailSchema);
  const passwordResolver = useYupValidationResolver(passwordSchema);
  const usernameResolver = useYupValidationResolver(usernameSchema);
  const resolver = useMemo(
    () =>
      authConfig === null
        ? undefined
        : authConfig.current === "email"
        ? emailResolver
        : authConfig.current === "password"
        ? passwordResolver
        : usernameResolver,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authConfig?.current]
  );
  const { handleSubmit, register, reset } = useForm<TLoginForm>({
    defaultValues: {
      input: "",
    },
    resolver,
  });

  // Methods
  function onSuccessSubmit({ input }: TLoginForm) {
    if (authConfig && authData.current) {
      authData.current[authConfig.current as TAuthCurrent] = input;
      reset();
      switch (authConfig.current) {
        case "email": {
          setIsSendMessageDisabled(true);
          setMessage((prev) => [
            ...prev,
            { isUser: true, message: input, type: "text" },
            {
              isUser: false,
              message: trans("AUTH", "common.checking"),
              isLoading: true,
              type: "text",
            },
          ]);
          onPreRegister(input)
            .then(() => {
              setMessage((prev) => [
                ...prev.slice(0, -1),
                {
                  isUser: false,
                  message: trans("AUTH", "common.email-exist"),
                  isLoading: false,
                  color: "White",
                  type: "text",
                },
              ]);
              setAuthConfig({
                ...authConfig,
                current: "password",
                inputConfig: {
                  type: "password",
                  placeholder: trans("AUTH", "common.password-placeholder"),
                },
              });
            })
            .catch((error) => {
              setMessage((prev) => [
                ...prev.slice(0, -1),
                {
                  isUser: false,
                  message: error.message,
                  isLoading: false,
                  color: "Red",
                  type: "text",
                },
              ]);
            })
            .finally(() => {
              setIsSendMessageDisabled(false);
            });

          break;
        }
        case "password": {
          setMessage((prev) => [
            ...prev,
            { isUser: true, message: input, type: "password" },
            {
              isUser: false,
              message: trans("AUTH", "register.username-question"),
              isLoading: false,
              color: "White",
              type: "text",
            },
          ]);
          setAuthConfig({
            ...authConfig,
            current: "username",
            inputConfig: {
              type: "username",
              placeholder: trans("AUTH", "register.username-placeholder"),
            },
          });
          break;
        }
        case "username": {
          setIsSendMessageDisabled(true);
          setMessage((prev) => [
            ...prev,
            { isUser: true, message: input, type: "text" },
            {
              isUser: false,
              message: trans("AUTH", "register.creating-account"),
              isLoading: true,
              type: "text",
            },
          ]);
          onRegister(authData.current)
            .then((msg) => {
              setMessage((prev) => [
                ...prev.slice(0, -1),
                {
                  isUser: false,
                  message: msg,
                  isLoading: false,
                  color: "Green",
                  type: "text",
                },
              ]);
              setTimeout(() => {
                navigate(`/${currentLang}${APP_URLS.DASHBOARD}`);
              }, 2000);
            })
            .catch((error) => {
              console.log("catch error here: ", error);
              setMessage((prev) => [
                ...prev.slice(0, -1),
                {
                  isUser: false,
                  message: error.message,
                  isLoading: false,
                  color: "Red",
                  type: "text",
                },
              ]);
            })
            .finally(() => {
              setIsSendMessageDisabled(false);
            });
          break;
        }
      }
    }
  }
  return (
    <>
      <div className="w-full max-h-[60%] flex flex-col justify-end flex-grow pointer-events-auto">
        <AnimateWrapper
          as="div"
          animateType="opacity-riseUp"
          key={"login-what-is-email"}
          className="flex flex-row gap-3 items-center justify-start pt-4"
        >
          <img
            src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
            alt="blueberry sensei"
            className="w-10 h-10 rounded-full shadow-[0px_0px_4px_3px_Primary] object-cover"
          />
          <ModernParagraph size="medium" className="text-White font-light">
            <Typewriter
              words={[trans("AUTH", "common.what-is-your-email")]}
              typeSpeed={30}
              infinite={false}
              cursor={false}
            />
          </ModernParagraph>
        </AnimateWrapper>
        <AnimatePresence mode="sync">
          {messages.map((m, i) => (
            <AnimateWrapper
              as="div"
              animateType="opacity-riseUp"
              key={`login-chat@${i}`}
              className="flex flex-row items-center justify-end mb-2 gap-3"
              style={{ justifyContent: m.isUser ? "flex-end" : "flex-start" }}
            >
              {m.isUser ? (
                <AuthBoxChat content={m.message} type={m.type} />
              ) : (
                <>
                  <img
                    src="/assets/imgs/logo/blueberry-sensei-logo-transparent.png"
                    alt="blueberry sensei"
                    className="w-10 h-10 rounded-full shadow-[0px_0px_4px_3px_Primary] object-cover"
                  />
                  {m.isLoading ? (
                    <SimpleTextLoading label={m.message} />
                  ) : (
                    <ModernParagraph
                      size="medium"
                      className="font-light"
                      style={{
                        color: m.color,
                      }}
                    >
                      <Typewriter
                        words={[m.message]}
                        typeSpeed={10}
                        infinite={false}
                        cursor={false}
                      />
                    </ModernParagraph>
                  )}
                </>
              )}
            </AnimateWrapper>
          ))}
        </AnimatePresence>
      </div>

      <form
        className="w-full h-2/5 mb-8 flex flex-col justify-end pointer-events-auto"
        onSubmit={handleSubmit(onSuccessSubmit, toastReactHookFormErrorMsg)}
        noValidate
      >
        {authConfig ? (
          <CustomAnimateWrapper
            as="div"
            key={"login-input"}
            className="bg-Primary rounded-lg overflow-hidden border-none relative"
            variants={VARIANTS.loginInput}
          >
            <input
              type={authConfig?.inputConfig?.type ?? "text"}
              className="w-full h-full bg-transparent px-8 py-4 text-White border-none outline-none disabled:cursor-not-allowed"
              placeholder={authConfig?.inputConfig?.placeholder ?? "..."}
              style={{
                ...getHeadingFontConfig(currentLang),
              }}
              disabled={isSendMessageDisabled}
              {...register("input")}
            />
            <button
              type="submit"
              className="absolute top-1/2 right-8 transform -translate-y-1/2 text-xl text-White disabled:cursor-not-allowed"
              disabled={isSendMessageDisabled}
            >
              <i className="fi fi-ss-paper-plane"></i>
            </button>
          </CustomAnimateWrapper>
        ) : null}
      </form>
    </>
  );
}
export default RegisterChat;
