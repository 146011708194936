import React, { memo, ReactNode } from "react";
import {
  getModernParagraphFontConfig,
  getParagraphFontSize,
  TParagraphFontSize,
} from "@utils/helpers/font.helper";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

type TProps = {
  children: ReactNode;
  className?: string;
  size: TParagraphFontSize;
  weight?:
    | "100"
    | "200"
    | "300"
    | "400"
    | "500"
    | "600"
    | "700"
    | "800"
    | "900";
  style?: React.CSSProperties;
  gradient?: [string, string]; // Ex: ["#ffffff", "#000000"]
  gradientDirection?: string; // Ex: "to right", "to bottom", "45deg"
};

function ModernParagraph({
  children,
  className,
  size,
  weight = "500",
  style: customStyle,
  gradient,
  gradientDirection = "to right",
}: TProps) {
  const { currentLang } = useLanguage();
  const style: React.CSSProperties = {
    ...getModernParagraphFontConfig(currentLang),
    ...customStyle,
    fontSize: getParagraphFontSize(size),
    fontWeight: weight,
    ...(gradient
      ? {
          background: `linear-gradient(${gradientDirection}, ${gradient[0]}, ${gradient[1]})`,
          WebkitBackgroundClip: "text",
          color: "transparent",
        }
      : {}),
  };

  return React.createElement("p", { className, style }, children);
}
export default memo(ModernParagraph);
