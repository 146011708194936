import {
  useChangeBluelyraIsLoop,
  useGetBluelyraProcess,
  useHandleBluelyraPlaylist,
  usePlayVideoInPlaylist,
  useSearchYoutubeVideos,
} from "@business-layer/business-logic/lib/bluelyra";
import { useGetBluelyraProfile } from "@business-layer/business-logic/lib/bluelyra";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import {
  IYouTubeSearchResultItem,
  IYouTubeVideoItem,
} from "@business-layer/services/entities";
import {
  AnimateWrapper,
  CustomAnimateWrapper,
  DotSpinnerLoader,
  EmptyBlueberry,
  Heading,
  ModernParagraph,
} from "@presentational/atoms";
import { COLOR_THEME } from "@presentational/themes/colors";
import {
  SearchYoutubeFormSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { AnimatePresence, Reorder } from "framer-motion";
import { MouseEvent, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { INITIAL_RESULTS_DATA } from "./__mock__";
import { BluelyraPlaylistVariants } from "./variants";
import { useLanguage } from "@business-layer/business-logic/lib/lang";

type TSearchYoutubeForm = {
  keys: string;
};

function BluelyraPlaylist({ onClose }: { onClose: () => void }) {
  const { trans } = useLanguage();
  const { isPlaying, isLoop } = useGetBluelyraProcess();
  const { onChange: onChangeIsLoop } = useChangeBluelyraIsLoop();
  const { playlist, currentVideo } = useGetBluelyraProfile();
  const { onAdd, onRemove, onSet } = useHandleBluelyraPlaylist();
  const [results, setResults] = useState<IYouTubeSearchResultItem[]>([]);
  const { onPlay } = usePlayVideoInPlaylist();
  const { toastReactHookFormErrorMsg, toastInfo } = useNotification();
  const { onSearch, isLoading } = useSearchYoutubeVideos();
  const { handleSubmit, register } = useForm<TSearchYoutubeForm>({
    defaultValues: {
      keys: "",
    },
    resolver: useYupValidationResolver(SearchYoutubeFormSchema),
  });
  const [isReordering, setIsReordering] = useState<boolean>(false);

  async function searchYoutubeVideo({ keys }: TSearchYoutubeForm) {
    try {
      setResults(await onSearch(keys));
    } catch (error) {
      console.error("Error fetching video info:", error);
    }
  }
  function handleReOrderPlaylist(newOrder: IYouTubeVideoItem[]) {
    onSet(newOrder);
  }
  function handleShufflePlaylist() {
    toastInfo("Chức năng đang được cập nhật");
  }
  function handleLoopPlaylist() {
    onChangeIsLoop(!isLoop);
  }
  function handlePlayVideo(
    e: MouseEvent<HTMLDivElement, any>,
    video: IYouTubeVideoItem
  ) {
    e.stopPropagation();
    e.preventDefault();
    onPlay(video);
  }
  useEffect(() => {
    setResults(INITIAL_RESULTS_DATA);
  }, []);

  return (
    <>
      <CustomAnimateWrapper
        as="div"
        key={"overlay-background"}
        variants={{
          initial: {
            opacity: 0,
          },
          animate: {
            opacity: 0.8,
            transition: {
              duration: 0.15,
              ease: "easeIn",
            },
          },
          exit: {
            opacity: 0,
          },
        }}
        className="fixed top-0 left-0 w-screen h-screen bg-Primary z-40 transition-opacity pointer-events-none"
      />
      <CustomAnimateWrapper
        as="div"
        key={"overlay-popup"}
        variants={{
          initial: {
            opacity: 0,
            scale: 0,
            x: "50%",
            y: "50%",
          },
          animate: {
            opacity: 1,
            scale: 1,
            x: 0,
            y: 0,
            transition: {
              duration: 0.3,
              type: "spring",
              stiffness: 300,
              damping: 20,
              bounce: 0.5,
            },
          },
          exit: {
            opacity: 0,
            scale: 0,
            x: "50%",
            y: "50%",
            transition: {
              duration: 0.2,
              ease: "easeIn",
            },
          },
        }}
        className="transition-opacity fixed top-0 left-0 w-screen h-screen z-40 flex flex-row justify-center items-start px-8 py-4 overflow-hidden"
      >
        <div className="absolute top-4 left-8 w-[calc(100%-4rem)] h-[calc(100%-2rem)] rounded-[2rem] bg-Dark opacity-[95%] z-10" />
        <div className="relative z-20 w-full h-full bg-Transparent grid grid-cols-[330px,1fr] grid-rows-1 gap-8 p-8">
          <div className="relative col-span-1 overflow-auto no-scrollbar z-0">
            <div className="flex flex-row justify-between items-center gap-6 mb-3">
              <Heading as="h6">{trans("BLUELYRA", "playlist.title")}</Heading>
              <div className="flex flex-row justify-end items-center gap-4">
                <button
                  type="button"
                  className="text-White"
                  onClick={handleShufflePlaylist}
                >
                  <i className="fi fi-rr-shuffle"></i>
                </button>
                <button
                  type="button"
                  className="transition-colors"
                  style={{
                    color: COLOR_THEME[isLoop ? "Sky" : "White"],
                  }}
                  onClick={handleLoopPlaylist}
                >
                  <i className="fi fi-rr-undo-alt"></i>
                </button>
              </div>
            </div>
            {Array.isArray(playlist) && playlist.length > 0 ? (
              <Reorder.Group
                axis="y"
                values={playlist}
                onReorder={handleReOrderPlaylist}
              >
                <AnimatePresence>
                  {playlist.map((p, index) => {
                    const isCurrent = currentVideo && p.id === currentVideo.id;
                    return (
                      <Reorder.Item
                        key={p.id}
                        value={p}
                        onDragStart={() => setIsReordering(true)}
                        onDragEnd={() => setIsReordering(false)}
                        variants={BluelyraPlaylistVariants.playlistItem}
                        initial="initial"
                        animate="animate"
                        exit="exit"
                      >
                        <div
                          onClick={(e) =>
                            !isReordering && handlePlayVideo(e, p)
                          }
                          style={{
                            backgroundColor: isCurrent
                              ? COLOR_THEME.Secondary
                              : COLOR_THEME.Transparent,
                          }}
                          className="flex flex-row items-center hover:!bg-Secondary transition-all pr-2 py-1 mb-1 rounded-md cursor-pointer parent__show-child-on-hover"
                        >
                          <div className="h-full w-fit text-White cursor-grab px-2 grid place-items-center">
                            <i className="fi fi-sr-menu-burger text-[0.5rem] bottom-0"></i>
                          </div>
                          <div
                            className="relative bg-cover bg-no-repeat bg-center w-2/5 min-w-[130px] aspect-video h-auto object-cover rounded-md pointer-events-none"
                            style={{
                              backgroundImage: `url('${p.snippet.thumbnails.medium.url}')`,
                            }}
                          >
                            {isCurrent && isPlaying ? (
                              <div className="absolute top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.5)] text-White grid place-items-center rounded-md">
                                <ModernParagraph
                                  size="small"
                                  className="text-center"
                                >
                                  {trans(
                                    "BLUELYRA",
                                    "playlist.item-playing-label"
                                  )}
                                </ModernParagraph>
                              </div>
                            ) : null}
                          </div>
                          <div className="px-2 pointer-events-none">
                            <ModernParagraph
                              size="base"
                              className="line-clamp-2 text-White text-left"
                            >
                              {p.snippet.title}
                            </ModernParagraph>
                            <ModernParagraph
                              size="small"
                              className="line-clamp-1 text-Gray text-justify font-normal"
                            >
                              {p.snippet.channelTitle}
                            </ModernParagraph>
                          </div>
                          <div className="flex-grow flex flex-row justify-end child__show-child-on-hover">
                            <button
                              type="button"
                              className="text-Red opacity-50 hover:opacity-100 transition-colors"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onRemove(index);
                              }}
                            >
                              <i className="fi fi-sr-trash"></i>
                            </button>
                          </div>
                        </div>
                      </Reorder.Item>
                    );
                  })}
                </AnimatePresence>
              </Reorder.Group>
            ) : (
              <AnimateWrapper
                as="div"
                animateType="opacity"
                key="empty-playlist"
                className="w-1/2 mx-auto opacity-50"
              >
                <EmptyBlueberry />
                <ModernParagraph
                  size="small"
                  className="text-White text-center"
                >
                  {trans("BLUELYRA", "playlist.empty-playlist")}
                </ModernParagraph>
              </AnimateWrapper>
            )}
          </div>
          <div className="relative col-span-1 px-8 py-4 z-0 overflow-hidden grid grid-rows-[2.5rem,1fr] gap-4">
            <div className="absolute rounded-[2rem] top-0 left-0 w-full h-full bg-Secondary opacity-30 z-0" />
            <form
              onSubmit={handleSubmit(
                searchYoutubeVideo,
                toastReactHookFormErrorMsg
              )}
              className="relative h-full flex flex-row justify-between items-center bg-Dark mx-auto w-1/2 max-w-[500px] z-10 rounded-[0.5rem] overflow-hidden"
            >
              <div className="flex flex-row items-center gap-3 relative flex-grow h-full">
                <img
                  src="/assets/imgs/icons/youtube.svg"
                  alt="youtube"
                  className="w-6 h-6 absolute top-1/2 left-0 transform -translate-y-1/2 pointer-events-none pl-3"
                />
                <input
                  type="text"
                  className="h-full w-full bg-Transparent outline-none border-none text-White font-normal pl-8 pr-4 text-xs"
                  placeholder={trans("BLUELYRA", "playlist.search-placeholder")}
                  {...register("keys")}
                />
              </div>
              <button
                type="submit"
                className="text-White opacity-70 hover:opacity-1 transition-opacity absolute top-1/2 right-3 transform -translate-y-1/2"
              >
                <i className="fi fi-rr-search"></i>
              </button>
            </form>
            <div className="relative grid grid-cols-4 gap-3 z-10 h-full w-full overflow-auto pr-4">
              {isLoading ? (
                <div className="col-span-4 grid place-items-center">
                  <DotSpinnerLoader />
                </div>
              ) : (
                results.map((r, index) => (
                  <button
                    key={`result@${index}`}
                    className="flex flex-col gap-2 parent__show-child-on-hover"
                    onClick={() => onAdd(r)}
                  >
                    <div
                      style={{
                        backgroundImage: `url('${r.snippet.thumbnails.high.url}')`,
                      }}
                      className="relative w-full h-auto aspect-video object-cover rounded-[0.5rem] bg-no-repeat bg-center bg-cover"
                    >
                      <div
                        className="absolute top-0 left-0 z-10 w-full h-full rounded-[0.5rem] flex flex-col items-center justify-center child__show-child-on-hover"
                        style={{
                          backgroundColor: "rgba(0,0,0,0.6)",
                        }}
                      >
                        <ModernParagraph
                          size="small"
                          className="text-White text-center"
                        >
                          {trans("BLUELYRA", "playlist.add-to-playlist-btn")}
                        </ModernParagraph>
                      </div>
                    </div>
                    <ModernParagraph
                      size="base"
                      className="text-White line-clamp-1 text-left"
                    >
                      {r.snippet.title}
                    </ModernParagraph>
                    <ModernParagraph
                      size="small"
                      className="text-Gray line-clamp-1 text-left"
                    >
                      {r.snippet.channelTitle}
                    </ModernParagraph>
                  </button>
                ))
              )}
            </div>
          </div>
          <button
            type="button"
            onClick={onClose}
            className="absolute top-4 right-4 z-10 rounded-full w-6 h-6 text-White border-White border-solid border-2 hover:text-Red hover:border-Red transition-colors grid place-items-center"
          >
            <ModernParagraph size="base">x</ModernParagraph>
          </button>
        </div>
      </CustomAnimateWrapper>
    </>
  );
}

export default BluelyraPlaylist;
