import {
  createSlice,
  PayloadAction,
  SliceCaseReducers,
  SliceSelectors,
} from "@reduxjs/toolkit";
import { TPasswordState } from "./type";
import { IHiddenValleyPassword } from "@business-layer/services/entities/password";

const initialState: TPasswordState = null;

export const slice = createSlice<
  TPasswordState,
  SliceCaseReducers<TPasswordState>,
  "hiddenValley",
  SliceSelectors<TPasswordState>
>({
  name: "hiddenValley",
  initialState,
  reducers: {
    setPasswords: (
      state: TPasswordState,
      action: PayloadAction<IHiddenValleyPassword[]>
    ) => {
      return action.payload;
    },
    addNewPassword: (
      state: TPasswordState,
      action: PayloadAction<IHiddenValleyPassword>
    ) => {
      if (state === null) {
        return [action.payload];
      }
      state.push(action.payload);
    },
    deletePassword: (state: TPasswordState, action: PayloadAction<string>) => {
      if (state !== null) {
        console.log(
          "new psw length: ",
          state.filter((s) => s.passwordId !== action.payload).length
        );
        return state.filter((s) => s.passwordId !== action.payload);
      }
    },
  },
});

export const { setPasswords, addNewPassword, deletePassword } = slice.actions;
export default slice.reducer;
