// Importing necessary modules and functions
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useAccessToken } from "./useAccessToken";
import { useLoginMutation } from "../../fetching/mutation";
import { useHandleRefreshToken } from "./useHandleRefreshToken";

export const useLogin = () => {
  const { trans } = useLanguage();
  const { setToken } = useAccessToken();
  const { setRefreshToken } = useHandleRefreshToken();
  const { mutateAsync, isPending } = useLoginMutation();

  const onLogin = async (email: string, password: string): Promise<string> => {
    try {
      try {
        const res = await mutateAsync({ email, password });
        console.log({ ...res.token, expires: res.refreshToken.expires });
        setToken({ ...res.token, expires: res.refreshToken.expires });
        setRefreshToken(res.refreshToken);
        return trans("SERVER", res.message as any);
      } catch (error: any) {
        throw new Error(trans("SERVER", error.message));
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  };

  return {
    onLogin,
    isLoading: isPending,
  };
};
