import "./presentational/styles/global.scss";
import MainRouter from "./routes";
import { useLogout } from "@business-layer/business-logic/lib/auth";
import { useEffect, useState } from "react";
import {
  enableDdosFirewallMiddleware,
  enableDelayDevResponseMiddleware,
  enableRefreshTokenMiddleware,
} from "@business-layer/services";
import { useAccessToken } from "@business-layer/business-logic/lib/auth/process/hooks/useAccessToken";
import { useHandleRefreshToken } from "@business-layer/business-logic/lib/auth/process/hooks/useHandleRefreshToken";
import "./presentational/styles/global.scss";
import { FallbackLoader } from "@presentational/atoms";
import { AnimatePresence } from "framer-motion";

const INTRO_DURATION = 1000;

function App() {
  const { onLogout } = useLogout();
  const { setToken } = useAccessToken();
  const { setRefreshToken } = useHandleRefreshToken();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    enableDdosFirewallMiddleware();
    enableDelayDevResponseMiddleware();
    enableRefreshTokenMiddleware(onLogout, setToken, setRefreshToken);
    const timer = setTimeout(() => setIsReady(true), INTRO_DURATION);
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <AnimatePresence mode="wait">
        {isReady ? null : <FallbackLoader key="FallbackLoader" />}
      </AnimatePresence>
      <MainRouter key="MainRouter" />
    </>
  );
}

export default App;
