import { useDispatch } from "react-redux";
import { setBluelyraIsPlaying } from "../context";

export const useChangeBluelyraIsPlaying = () => {
  const dispatch = useDispatch();
  function onChange(isPlaying: boolean) {
    dispatch(setBluelyraIsPlaying(isPlaying));
  }
  return {
    onChange,
  };
};
