import { ENV_KEYS } from "@business-layer/business-logic/configs";
import { AES, enc } from "crypto-js";

export const useUnveilPassword = () => {
  function onUnveil(encryptedPassword: string) {
    return AES.decrypt(
      encryptedPassword,
      ENV_KEYS.HIDDEN_VALLEY_ENCRYPT_SECRET
    ).toString(enc.Utf8);
  }
  return { onUnveil };
};
