import { toast } from "sonner";
import { useLanguage } from "../../lang";

type TToastPromiseProps = {
  successMsg?: string;
  failMsg?: string;
  loadingMsg?: string;
  promise: Promise<any>;
};
export const useNotification = () => {
  const { trans } = useLanguage();
  function toastSuccess(message: string) {
    toast.success(message);
  }
  function toastInfo(message: string) {
    toast.info(message);
  }
  function toastError(message: string) {
    toast.error(message);
  }
  function toastPromise({
    successMsg,
    failMsg,
    loadingMsg,
    promise,
  }: TToastPromiseProps) {
    toast.promise(promise, {
      loading: loadingMsg,
      success: successMsg,
      error: failMsg,
    });
  }
  function toastReactHookFormErrorMsg(data: Record<string, any>) {
    toast.error(trans("VALIDATION", Object.values(data)[0].message));
  }
  return {
    toastInfo,
    toastSuccess,
    toastError,
    toastPromise,
    toastReactHookFormErrorMsg,
  };
};
