import { NoUnmountAnimateWrapper } from "@presentational/atoms/animate";
import { COLOR_THEME } from "@presentational/themes/colors";
import { useState, useEffect } from "react";

const VARIANTS = {
  playMusicBtn: {
    initial: { rotate: 0 },
    animate: {
      rotate: -360,
      transition: {
        duration: 10,
        ease: "linear",
        repeat: Infinity,
      },
    },
    exit: { rotate: 0 },
  },
};
const BackgroundAudio = new Audio("/assets/sounds/hidden_valley_bg.mp3");
BackgroundAudio.loop = true;

function HeaderMusicBtn() {
  const [isPlayingBgMusic, setIsPlayingBgMusic] = useState<boolean>(false);
  function handleToggleBgMusic() {
    if (isPlayingBgMusic) {
      BackgroundAudio.pause();
      BackgroundAudio.currentTime = 0;
    } else {
      BackgroundAudio.play();
    }
    setIsPlayingBgMusic(!isPlayingBgMusic);
  }
  useEffect(() => {
    return () => {
      if (BackgroundAudio && isPlayingBgMusic) {
        BackgroundAudio.pause();
        BackgroundAudio.currentTime = 0;
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <NoUnmountAnimateWrapper
      as="button"
      key="HeaderMusicBtn"
      type="button"
      onClick={handleToggleBgMusic}
      className="text-xs text-Gray bg-Dark border-2 border-solid border-Gray w-8 h-8 rounded-full hover:text-White hover:border-White transition-colors"
      style={
        isPlayingBgMusic
          ? {
              color: COLOR_THEME.White,
              borderColor: COLOR_THEME.White,
            }
          : undefined
      }
      variants={VARIANTS.playMusicBtn}
      animate={isPlayingBgMusic ? "playing" : "muted"}
    >
      <i className="left-[-1px] fi fi-sr-music-alt"></i>
    </NoUnmountAnimateWrapper>
  );
}

export default HeaderMusicBtn;
