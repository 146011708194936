import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { getModernParagraphFontConfig } from "@utils/helpers/font.helper";
import { UseFormRegister } from "react-hook-form";
import { ModernParagraph } from "../text";

type TProps = {
  register: UseFormRegister<any>;
  watcher: any;
  name: string;
  label: string;
  type?: React.InputHTMLAttributes<HTMLInputElement>["type"];
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, "name" | "type">;
function CommonInput({
  register,
  watcher,
  name,
  label,
  type = "text",
  ...inputProps
}: TProps) {
  const { currentLang } = useLanguage();
  const val = watcher(name);

  return (
    <div className="flex flex-col gap-1 relative">
      <label
        htmlFor={name}
        style={getModernParagraphFontConfig(currentLang)}
        className="text-xs text-left !font-semibold"
      >
        {label}
      </label>
      <input
        {...inputProps}
        {...register(name)}
        id={name}
        className="rounded-xl rounded-tl-none bg-Secondary outline-none border-Secondary border-2 px-6 py-3 text-White text-sm !font-normal disabled:cursor-not-allowed"
        style={getModernParagraphFontConfig(currentLang)}
      />

      {inputProps.maxLength ? (
        <ModernParagraph
          size="minion"
          className="absolute bottom-1 right-2 opacity-60"
        >
          {val.length} / {inputProps.maxLength}
        </ModernParagraph>
      ) : null}
    </div>
  );
}

export default CommonInput;
