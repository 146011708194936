import { AnimateWrapper, DynamicIslandButton } from "@presentational/atoms";
import {
  YoutubeMusicPlayer,
  SundialWidget,
  TheHorseshoeWidget,
  WeatherWidget,
} from "@presentational/molecules";
import { AnimatePresence } from "framer-motion";
import { Suspense, useCallback, useState, lazy } from "react";

const BluelyraPlaylist = lazy(
  () => import("@presentational/organisms/overlay/BluelyraPlaylist")
);

function Dashboard() {
  const [isOpenBluelyraPlaylist, setIsOpenBluelyraPlaylist] =
    useState<boolean>(false);

  const handleOpenBluelyraPlaylist = useCallback(() => {
    setIsOpenBluelyraPlaylist(true);
  }, []);
  const handleCloseBluelyraPlaylist = useCallback(() => {
    setIsOpenBluelyraPlaylist(false);
  }, []);

  return (
    <AnimateWrapper
      as="main"
      animateType="opacity"
      key="dashboard"
      className="grid grid-cols-[1fr,300px] gap-2 w-full h-[calc(100%-2rem)] mt-4"
    >
      <div className="col-span-1 h-full bg-Primary rounded-t-[7rem] rounded-bl-[10rem] rounded-br-[5rem] overflow-hidden relative">
        {/* <img
          // src="https://blueberry-sensei-cdn--dev.b-cdn.net/account/dashboard-background/1331368.png"
          //   src="https://blueberry-sensei-cdn--dev.b-cdn.net/account/dashboard-background/dope-sukuna-pink-3840x2160-16935.png"
          src="https://blueberry-sensei-cdn--dev.b-cdn.net/account/dashboard-background/super-saiyan-blue-3840x2160-17603.jpg"
          alt="bg"
          className="object-cover h-full w-full relative z-0"
        /> */}
        <video
          className="object-cover h-full w-full relative z-0"
          loop={true}
          autoPlay={true}
          muted={true}
          playsInline={true}
          preload="auto"
        >
          <source
            width={1920}
            height={1080}
            src="https://dreamroom.blueberrysensei.space/account/dashboard-background/Sequence%2004.mp4"
            type="video/mp4"
          />
        </video>
        <WeatherWidget />
        <div className="absolute -top-4 left-1/2 transform -translate-x-1/2 bg-Secondary rounded-b-[3rem] z-10 w-72 h-16 pb-2 pt-4 px-7">
          <div className="bg-Primary rounded-full w-full h-full py-2 px-4 flex flex-row items-center justify-center gap-2">
            <DynamicIslandButton
              icon="fi-br-comment-alt"
              label="20"
              badge={false}
            />
            <DynamicIslandButton icon="fi-br-bell-ring" badge={true} />
          </div>
        </div>
      </div>
      <div className="col-span-1 h-full flex flex-col gap-2">
        <div className="flex-grow flex flex-col gap-3 w-full bg-Primary rounded-tr-[3rem] rounded-tl-[3.5rem] rounded-b-[2rem] px-2 max-h-[calc(100vh-2.5rem-280px)] overflow-hidden">
          {/* Sundial */}
          <div className="flex-shrink w-full">
            <SundialWidget />
          </div>

          {/* The horseshoe */}
          <div className="flex-grow w-full overflow-hidden hidden h-md:block">
            <TheHorseshoeWidget />
          </div>
        </div>
        <YoutubeMusicPlayer
          onOpenBluelyraPlaylist={handleOpenBluelyraPlaylist}
        />
      </div>
      <AnimatePresence mode="wait">
        {isOpenBluelyraPlaylist ? (
          <Suspense>
            <BluelyraPlaylist onClose={handleCloseBluelyraPlaylist} />
          </Suspense>
        ) : null}
      </AnimatePresence>
    </AnimateWrapper>
  );
}

export default Dashboard;
