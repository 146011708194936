import passwordReducers from "../lib/hiddenValley/process/context/passwordReducers";
import profileReducers from "../lib/bluelyra/process/context/profileReducers";
import processReducers from "../lib/bluelyra/process/context/processReducers";
import accountReducers from "../lib/account/process/context/accountReducers";
import weatherReducers from "../lib/weather/process/context/weatherReducers";
import {
  TBluelyraProcessState,
  TBluelyraProfileState,
} from "../lib/bluelyra/process/context";
import { TAccountState } from "../lib/account/process/context";
import { TPasswordState } from "../lib/hiddenValley/process/context";
import { TWeatherState } from "../lib/weather/process/context";

export * from "./socialConfig";
export * from "./constants";

/**
 * MUTATION CONFIG FOR REACT-QUERY
 */
export const mutationConfig = {
  MUTATION_RETRY: 0,
  USE_QUERY_RETRY: 1,
};

export const APP_REDUCERS = {
  profile: profileReducers,
  process: processReducers,
  account: accountReducers,
  passwords: passwordReducers,
  weather: weatherReducers,
};

export type TContextData = {
  profile: TBluelyraProfileState;
  process: TBluelyraProcessState;
  account: TAccountState;
  passwords: TPasswordState;
  weather: TWeatherState;
};
