import { ModernParagraph } from "@presentational/atoms";
import { memo } from "react";

type TProps = {
  label?: string;
  icon: string;
  badge?: boolean;
};
function DynamicIslandButton({ label, icon, badge }: TProps) {
  return (
    <button
      type="button"
      className="relative flex flex-row items-center justify-center gap-1 bg-White rounded-full py-1 px-2 hover:px-4 transition-all"
    >
      <i className={`fi ${icon} text-Dark text-xs pointer-events-none`}></i>
      {label ? (
        <ModernParagraph
          size="small"
          className="text-Dark text-xs font-bold pointer-events-none"
        >
          {label}
        </ModernParagraph>
      ) : null}
      {badge ? (
        <div className="w-2 h-2 rounded-full bg-Legendary absolute top-0 right-0 pointer-events-none" />
      ) : null}
    </button>
  );
}

export default memo(DynamicIslandButton);
