import {
  LANG_NS,
  TLang,
  TLangTranslationFunctionParams,
} from "@constants/lang.constants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useLanguage = () => {
  const { t, i18n } = useTranslation();

  const trans: TLangTranslationFunctionParams = useCallback(
    (ns, key) => {
      return t(key, { ns: LANG_NS[ns] });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const changeLang = useCallback((locales: TLang) => {
    i18n.changeLanguage(locales);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    currentLang: useMemo(() => i18n.language as TLang, [i18n.language]),
    trans,
    changeLang,
  };
};
