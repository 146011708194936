import { memo } from "react";
import { AnimateWrapper } from "@presentational/atoms";
type TProps = {
  children: React.ReactNode;
  onClose: () => void;
  containerStyle?: React.CSSProperties;
};
function CommonOverlayModal(props: TProps) {
  return (
    <AnimateWrapper
      key={"CommonOverlayModal"}
      as="div"
      animateType="opacity"
      isFastAnimation={true}
      className="fixed top-0 left-0 w-screen h-screen grid place-items-center z-40"
    >
      <div
        className="absolute top-0 left-0 w-full h-full bg-Dark opacity-70 z-0"
        onClick={props.onClose}
      />
      <AnimateWrapper
        as="div"
        animateType="fullScale"
        isBounce={true}
        key={"CommonOverlayModal-content"}
        className="relative z-10 bg-Primary p-5 rounded-xl"
        style={props.containerStyle}
      >
        {props.children}
      </AnimateWrapper>
    </AnimateWrapper>
  );
}

export default memo(CommonOverlayModal);
