import { TServiceEndpoints } from "./axios";

// AUTH
export const AUTH_ENDPOINT: TServiceEndpoints = {
  preLogin: {
    method: "POST",
    url: "/auth/user-prelogin",
  },
  login: {
    method: "POST",
    url: "/auth/user-login",
  },
  refreshToken: {
    method: "POST",
    url: "/auth/refresh",
  },
  preRegister: {
    method: "POST",
    url: "/auth/user-preregister",
  },
  register: {
    method: "POST",
    url: "/auth/user-register",
  },
};

// ACCOUNT
export const ACCOUNT_ENDPOINTS: TServiceEndpoints = {
  getAccountDetail: {
    method: "GET",
    url: "/account/detail",
  },
  editAccountDetail: {
    method: "PUT",
    url: "/account/detail/edit-detail",
  },
};

// BLUELYRA
export const BLUELYRA_ENDPOINTS: TServiceEndpoints = {
  searchYoutubeVideos: {
    method: "GET",
    url: "https://www.googleapis.com/youtube/v3/search",
  },
};

// WEATHER
export const WEATHER_ENDPOINTS: TServiceEndpoints = {
  getCurrentWeather: {
    method: "GET",
    url: "https://api.openweathermap.org/data/2.5/weather",
  },
  getUserIPAddress: {
    method: "GET",
    url: "https://api.ipify.org?format=json",
  },
  getUserLocation: {
    method: "GET",
    url: "http://ip-api.com/json",
  },
};

// HIDDEN VALLEY
export const HIDDEN_VALLEY_ENDPOINTS: TServiceEndpoints = {
  getAllPasswords: {
    method: "GET",
    url: "/hidden-valley/get-all-passwords",
  },
  addNewPassword: {
    method: "POST",
    url: "/hidden-valley/add-password",
  },
  deletePassword: {
    method: "DELETE",
    url: "/hidden-valley/delete-password",
  },
};

// SIRV
export const sirvBaseUrlTemplate = "https://api.sirv.com/v2";
export const connectSirvUrl = sirvBaseUrlTemplate + "/token";
export const uploadImageUrl = sirvBaseUrlTemplate + "/files/upload";
export const deleteImageUrl = sirvBaseUrlTemplate + "/files/delete";

// GOOGLE
export const googleGetUserInfoUrl =
  "https://people.googleapis.com/v1/people/me?personFields=names,emailAddresses";
export const googleValidateTokenUrl =
  "https://www.googleapis.com/oauth2/v3/tokeninfo";
