export enum USE_QUERY_KEYS {
  GET_ACCOUNT_DETAIL,
  GET_ALL_HIDDEN_VALLEY_PASSWORDS,
}
export const COOKIE_KEYS = {
  ACCESS_TOKEN: `e83b3aa199d91a73636f95cb059d683e2bf82cf1`,
  REFRESH_TOKEN: `82b5c8f159d3f831ce2c75eb6aaf84063bc2e39b`,
};
export const LOCAL_STORAGE_KEYS = {
  HIDDEN_VALLEY_PASSWORDS_ORDER: `5fccd978928b5e29e4653088f7a69cf5`,
  ACCOUNT_DETAIL: `315b94df7977bf8b1689171617ed6af9`,
  BLUELYRA_PLAYLIST: `84981a10181dee4e54a9e21eb6178f5f`,
  BLUELYRA_CURRENT_SONG: `f7581e5f7f766df16bb70330809f00a9`,
};

export const ENV_KEYS = {
  HIDDEN_VALLEY_ENCRYPT_SECRET:
    process.env.REACT_APP_HIDDEN_VALLEY_ENCRYPT_SECRET ?? "",
};
