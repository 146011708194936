// Importing necessary modules and functions
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { usePreLoginMutation } from "../../fetching/mutation";

export const usePreLogin = () => {
  const { trans } = useLanguage();
  const { mutateAsync, isPending } = usePreLoginMutation();

  const onPreLogin = async (email: string): Promise<string> => {
    try {
      await mutateAsync({ email });
      return trans("AUTH", "common.email-exist");
    } catch (error: any) {
      console.error(error);
      throw new Error(trans("SERVER", error.message));
    }
  };

  return {
    onPreLogin,
    isLoading: isPending,
  };
};
