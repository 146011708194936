import { CommonNotification } from "@presentational/atoms";

export function SingleSectionTemplate({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <section className="w-screen h-screen overflow-hidden bg-Dark relative">
      <CommonNotification />
      {children}
    </section>
  );
}
