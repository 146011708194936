import { TValidationTransKeys } from "@constants/lang.constants";
import {
  COMMON_INPUT_MAX_LENGTH,
  USERNAME_MAX_LENGTH,
} from "@constants/system.constants";
import * as y from "yup";

export const editProfileSchema = y.object({
  username: y
    .string()
    .typeError("common.typeError" as TValidationTransKeys)
    .required("common.required" as TValidationTransKeys)
    .max(USERNAME_MAX_LENGTH, "common.invalid-length" as TValidationTransKeys)
    .transform((value) => value.trim()),
  description: y
    .string()
    .typeError("common.typeError" as TValidationTransKeys)
    .max(
      COMMON_INPUT_MAX_LENGTH,
      "common.invalid-length" as TValidationTransKeys
    )
    .transform((value) => value.trim()),
});
