function formatDate(dateObj: Date | string, isReverdFormat?: boolean) {
  const dateRaw = typeof dateObj === "string" ? new Date(dateObj) : dateObj;

  let days: string[] = [
    "Chủ Nhật",
    "Thứ 2",
    "Thứ 3",
    "Thứ 4",
    "Thứ 5",
    "Thứ 6",
    "Thứ 7",
  ];

  let dayOfWeek: string = days[dateRaw.getDay()];
  let date: string = dateRaw.getDate().toString().padStart(2, "0");
  let month: string = (dateRaw.getMonth() + 1).toString().padStart(2, "0");
  let year: number = dateRaw.getFullYear();

  let hours: number = dateRaw.getHours();
  let minutes: number = dateRaw.getMinutes();

  const formattedHours: string = hours.toString().padStart(2, "0");
  const formattedMinutes: string = minutes.toString().padStart(2, "0");

  // Format month and year
  const monthYear = dateRaw.toLocaleString("vi-VN", {
    month: "long",
    year: "numeric",
  });

  return {
    dateMonthYear: isReverdFormat
      ? `${year}-${month}-${date}`
      : `${date}-${month}-${year}`,
    monthYear, // Added for grouping
    days: dayOfWeek,
    time24: `${formattedHours}:${formattedMinutes}`,
  };
}
function calcHoursBetweenTimes(start: Date, end: Date) {
  return Math.abs(end.getTime() - start.getTime()) / 3600000;
}

export { formatDate, calcHoursBetweenTimes };
