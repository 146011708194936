import {
  useChangeLanguage,
  useLanguage,
} from "@business-layer/business-logic/lib/lang";
import { SUPPORT_LANG, TLang } from "@constants/lang.constants";
import {
  AnimateWrapper,
  NoUnmountAnimateWrapper,
} from "@presentational/atoms/animate";
import { ModernParagraph } from "@presentational/atoms/text";
import { COLOR_THEME } from "@presentational/themes/colors";
import { AnimatePresence } from "framer-motion";
import { useEffect, useRef, useState } from "react";

const VARIANTS = {
  langBtn: {
    initial: {
      width: "2rem",
      height: "2rem",
      borderRadius: "5rem",
      backgroundColor: COLOR_THEME.Dark,
    },
    exit: {
      width: "2rem",
      height: "2rem",
      borderRadius: "5rem",
      borderColor: COLOR_THEME.Gray,
      color: COLOR_THEME.Gray,
      backgroundColor: COLOR_THEME.Dark,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
    animate: {
      width: "8rem",
      height: "8.5rem",
      borderRadius: "1rem",
      borderColor: COLOR_THEME.White,
      color: COLOR_THEME.Dark,
      backgroundColor: COLOR_THEME.White,
      transition: {
        duration: 0.2,
        ease: "easeInOut",
      },
    },
  },
};

function HeaderLangSelectBtn() {
  const [isSelectingLang, setIsSelectingLang] = useState<boolean>(false);
  const { trans } = useLanguage();
  const { onChange } = useChangeLanguage();
  const btnRef = useRef<HTMLDivElement>(null);

  function handleChangeLanguage(lang: TLang) {
    onChange(lang);
    handleToggleLangSelect();
  }
  function handleToggleLangSelect() {
    setIsSelectingLang(!isSelectingLang);
  }

  function handleClickOutsideDropdown(event: any) {
    if (
      btnRef.current &&
      event.target &&
      !btnRef.current.contains(event.target)
    ) {
      setIsSelectingLang(false);
    }
  }
  useEffect(() => {
    if (isSelectingLang) {
      window.addEventListener("click", handleClickOutsideDropdown);
    }
    return () => {
      window.removeEventListener("click", handleClickOutsideDropdown);
    };
  }, [isSelectingLang]);
  return (
    <NoUnmountAnimateWrapper
      ref={btnRef}
      as="div"
      key={"lang-select"}
      isLayoutAnimation={true}
      className="cursor-pointer flex flex-col justify-start text-xs text-Gray bg-Dark border-2 border-solid border-Gray w-8 h-8 rounded-full hover:!text-White hover:!border-White transition-colors overflow-hidden"
      variants={VARIANTS.langBtn}
      animate={isSelectingLang ? "opening" : "closed"}
      onClick={handleToggleLangSelect}
    >
      <div
        className="relative w-full h-8 flex flex-row justify-center items-center flex-nowrap"
        style={
          isSelectingLang
            ? {
                color: COLOR_THEME.Dark,
              }
            : {}
        }
      >
        <i className="fi fi-sr-earth-asia"></i>
        <AnimatePresence mode="wait">
          {isSelectingLang ? (
            <AnimateWrapper
              key={"lang-label"}
              as="div"
              animateType="opacity"
              className="relative inline-block whitespace-nowrap mb-1 ml-2 overflow-hidden bottom-[-1px]"
            >
              <ModernParagraph size="base" weight="400">
                {trans("COMMON", "lang-label")}
              </ModernParagraph>
            </AnimateWrapper>
          ) : null}
        </AnimatePresence>
      </div>
      <AnimatePresence mode="sync">
        {SUPPORT_LANG.map((lang, index) =>
          isSelectingLang ? (
            <AnimateWrapper
              as="div"
              animateType="opacity-height-fullScale"
              key={`lang-dropdown-item-${index}`}
              className="rounded-b-md z-10 flex flex-col justify-evenly items-center w-full pl-6 hover:scale-105 transition-transform"
            >
              <button
                className="text-Dark w-full h-8 flex flex-row gap-2 justify-start items-center"
                onClick={() => handleChangeLanguage(lang.name)}
              >
                <img
                  width={50}
                  height={50}
                  src={lang.flag}
                  alt="english"
                  className="w-4 h-4 rounded-md object-cover"
                />
                <ModernParagraph size="base" weight="400">
                  {lang.label}
                </ModernParagraph>
              </button>
            </AnimateWrapper>
          ) : null
        )}
      </AnimatePresence>
    </NoUnmountAnimateWrapper>
  );
}

export default HeaderLangSelectBtn;
