import { HIDDEN_VALLEY_ENDPOINTS } from "../../config/apis";
import { Services } from "../../service";
import {
  SAddNewPasswordResponse,
  SDeletePasswordResponse,
  SGetAllPasswordsResponse,
} from "./schema";
import {
  TAddNewPasswordParams,
  TAddNewPasswordResponse,
  TDeletePasswordParams,
  TDeletePasswordResponse,
  TGetAllPasswordsParams,
  TGetAllPasswordsResponse,
} from "./type";

export * from "./type";
export class HiddenValleyService extends Services {
  getAllPasswords = async ({
    token,
  }: TGetAllPasswordsParams): Promise<TGetAllPasswordsResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SGetAllPasswordsResponse,
          TGetAllPasswordsResponse
        >({
          ...HIDDEN_VALLEY_ENDPOINTS.getAllPasswords,
          schema: SGetAllPasswordsResponse,
          signal: this.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  addNewPassword = async ({
    token,
    ...data
  }: TAddNewPasswordParams): Promise<TAddNewPasswordResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SAddNewPasswordResponse,
          TAddNewPasswordResponse
        >({
          ...HIDDEN_VALLEY_ENDPOINTS.addNewPassword,
          schema: SAddNewPasswordResponse,
          signal: this.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
  deletePassword = async ({
    token,
    ...params
  }: TDeletePasswordParams): Promise<TDeletePasswordResponse> => {
    this.abortController = new AbortController();
    try {
      if (token) {
        return await this.fetchApi<
          typeof SDeletePasswordResponse,
          TDeletePasswordResponse
        >({
          ...HIDDEN_VALLEY_ENDPOINTS.deletePassword,
          schema: SDeletePasswordResponse,
          signal: this.abortController.signal,
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params,
          transformResponse: (res) => res,
        });
      } else {
        throw new Error("Unauthorized");
      }
    } catch (error) {
      throw this.handleError(error);
    }
  };
}
