// import { useLanguage } from "@business-layer/business-logic/lib/lang";
import {
  APP_INIT_ANIM_DELAY_DURATION,
  // INITIAL_ANIM_DELAY,
} from "@constants/system.constants";
import {
  AnimateWrapper,
  // CustomAnimateWrapper,
  // ModernParagraph,
} from "@presentational/atoms";
import { HiddenValleyIntroControlBar } from "@presentational/molecules";
// import { useNavigate } from "react-router-dom";

export function AuthHeader({
  setIsGoingOut,
}: {
  setIsGoingOut: (val: boolean) => void;
}) {
  // const { trans } = useLanguage();
  // const navigate = useNavigate();
  // function handleGoBack() {
  //   setIsGoingOut(true);
  //   setTimeout(() => {
  //     navigate(-1);
  //   }, 800);
  // }
  return (
    <AnimateWrapper
      as="div"
      animateType="opacity-fallDown"
      delay={APP_INIT_ANIM_DELAY_DURATION}
      key={"intro-header"}
      className="fixed top-0 left-0 w-full flex flex-row justify-end"
    >
      <div className="absolute top-[-2.5rem] left-[-2rem] w-32 h-32">
        <img
          src="/assets/imgs/graphics/blueberry.png"
          alt="blueberry sensei"
          className="w-32 h-32 object-cover transform rotate-[120deg]"
        />
        {/* <div
          key="blueberry_chat_box"
          className="absolute top-28 left-20 object-cover z-10 flex flex-col justify-end"
        >
          <CustomAnimateWrapper
            as="div"
            key={"blueberry_chat_box"}
            variants={{
              initial: { x: -100, y: 100, scaleX: 0, opacity: 0 },

              animate: {
                x: 0,
                y: 0,
                scaleX: 1,
                opacity: 1,
                transition: {
                  duration: 0.3,
                  type: "spring",
                  stiffness: 300,
                  damping: 20,
                  bounce: 0.5,
                },
              },
              exit: { x: -100, y: 100, scaleX: 0, opacity: 0 },
            }}
            delay={INITIAL_ANIM_DELAY}
          >
            <button
              className="py-3 px-5 bg-Primary rounded-2xl relative transition-colors hover:bg-DeepPink"
              onClick={handleGoBack}
            >
              <ModernParagraph
                size="small"
                className="text-justify whitespace-nowrap text-White"
              >
                <i className="fi fi-rs-arrow-small-left"></i>
                &nbsp;{trans("AUTH", "greeting.authentication-go-back-button")}
              </ModernParagraph>

              <div className="bg-inherit w-6 h-6 absolute top-[-0.25rem] left-[-0.25rem] rounded-se-full rounded-es-full"></div>
            </button>
          </CustomAnimateWrapper>
        </div> */}
      </div>
      <div className="pt-6 px-8">
        <HiddenValleyIntroControlBar />
      </div>
    </AnimateWrapper>
  );
}
