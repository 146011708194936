import { useEditAccountDetail } from "@business-layer/business-logic/lib/account/process/hooks/useEditAccountDetail";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import { useNotification } from "@business-layer/business-logic/lib/notification";
import { IAccountDetail } from "@business-layer/services/entities";
import { TValidationTransKeys } from "@constants/lang.constants";
import {
  COMMON_INPUT_MAX_LENGTH,
  USERNAME_MAX_LENGTH,
} from "@constants/system.constants";
import { CommonButton, Heading, ModernParagraph } from "@presentational/atoms";
import { CommonInput } from "@presentational/atoms/input";
import {
  editProfileSchema,
  useYupValidationResolver,
} from "@utils/validators/yup";
import { memo, useState } from "react";
import { useForm } from "react-hook-form";

type TEditProfileForm = {
  username: string;
  description: string;
};
type TProps = {
  accountDetail: IAccountDetail;
  onClose: () => void;
};
function EditProfileForm(props: TProps) {
  const { trans } = useLanguage();
  const { toastError, toastSuccess } = useNotification();
  const resolver = useYupValidationResolver(editProfileSchema);
  const { onEdit, isLoading } = useEditAccountDetail();
  const { register, handleSubmit, watch } = useForm<TEditProfileForm>({
    defaultValues: {
      username: props.accountDetail.username,
      description: props.accountDetail.description ?? "",
    },
    resolver,
  });
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  async function onSuccessSubmit(data: TEditProfileForm) {
    try {
      setErrorMessage(null);
      await onEdit(data);
      props.onClose();
      toastSuccess(trans("COMMON", "account-page.edit-profile.edit-success"));
    } catch (error: any) {
      toastError(error.message);
    }
  }
  return (
    <form
      onSubmit={handleSubmit(onSuccessSubmit, (error) =>
        setErrorMessage(Object.values(error)[0].message ?? null)
      )}
      className="text-White flex flex-col gap-3 w-[clamp(300px,40vw,600px)] text-center"
    >
      <Heading as="h3">
        {trans("COMMON", "account-page.edit-profile.modal-title")}
      </Heading>
      <CommonInput
        register={register}
        watcher={watch}
        name="username"
        maxLength={USERNAME_MAX_LENGTH}
        disabled={isLoading}
        label={trans(
          "COMMON",
          "account-page.edit-profile.username-input-label"
        )}
        placeholder={trans(
          "COMMON",
          "account-page.edit-profile.username-input-placeholder"
        )}
      />
      <CommonInput
        register={register}
        watcher={watch}
        name="description"
        maxLength={COMMON_INPUT_MAX_LENGTH}
        disabled={isLoading}
        label={trans(
          "COMMON",
          "account-page.edit-profile.description-input-label"
        )}
        placeholder={trans(
          "COMMON",
          "account-page.edit-profile.description-input-placeholder"
        )}
      />
      <ModernParagraph size="small" className="text-Red">
        {trans("VALIDATION", errorMessage as TValidationTransKeys)}
      </ModernParagraph>
      <div className="flex flex-row gap-3 justify-center">
        <CommonButton
          onClick={props.onClose}
          type="button"
          theme="Secondary"
          size="base"
          className="rounded-lg"
          disabled={isLoading}
        >
          {trans("COMMON", "cancel-title")}
        </CommonButton>
        <CommonButton
          type="submit"
          theme="Pink"
          size="base"
          className="rounded-lg"
          isLoading={isLoading}
        >
          {trans("COMMON", "confirm-title")}
        </CommonButton>
      </div>
    </form>
  );
}

export default memo(EditProfileForm);
