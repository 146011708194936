import { CommonButton, Heading, ModernParagraph } from "@presentational/atoms";
import { useLanguage } from "@business-layer/business-logic/lib/lang";
import CommonOverlayModal from "./CommonOverlayModal";

type TProps = {
  title: string;
  description: string;
  onConfirm: () => void;
  onCancel?: () => void;
  confirmTitle?: string;
  cancelTitle?: string;
  handleClose: () => void;
};
function AlertDialog({
  title,
  description,
  onConfirm,
  onCancel,
  confirmTitle,
  cancelTitle,
  handleClose,
}: TProps) {
  const { trans } = useLanguage();
  function handleCancel() {
    handleClose();
    onCancel && onCancel();
  }
  return (
    <CommonOverlayModal key={"alert-wrapper"} onClose={handleCancel}>
      <Heading color="White" as="h4" className="text-center mb-2">
        {title}
      </Heading>
      <ModernParagraph
        size="base"
        weight="400"
        className="text-center mb-7 text-White"
      >
        {description}
      </ModernParagraph>
      <div className="flex flex-row justify-between gap-3 flex-wrap">
        <CommonButton
          theme="Secondary"
          size="base"
          className="rounded-lg flex-grow"
          onClick={handleCancel}
        >
          {cancelTitle ?? trans("COMMON", "cancel-title")}
        </CommonButton>
        <CommonButton
          theme="Pink"
          size="base"
          className="rounded-lg flex-grow"
          onClick={() => {
            handleClose();
            onConfirm();
          }}
        >
          {confirmTitle ?? trans("COMMON", "confirm-title")}
        </CommonButton>
      </div>
    </CommonOverlayModal>
  );
}

export default AlertDialog;
