import {
  CommonButton,
  ModernParagraph,
  NerkoText,
} from "@presentational/atoms";

function TheHorseshoeWidget() {
  return (
    <>
      <div className="flex flex-row justify-start items-start h-fit w-full relative">
        <div className="h-10 flex flex-row justify-end items-end relative z-0">
          <div className="bg-Secondary w-12 h-full rounded-tl-[1rem] rounded-tr-[1.25rem] grid place-items-center">
            <img
              src="/assets/imgs/logo/the-horseshoe.png"
              alt="horseshoe"
              width={50}
              height={50}
              className="w-7 h-7"
            />
          </div>
          <div className="bg-Secondary relative w-8 h-[60%]">
            <div className="absolute top-0 left-0 w-full h-full bg-Primary rounded-bl-[4rem]"></div>
          </div>
        </div>
        <NerkoText
          size="large"
          className="text-White absolute right-2 bottom-1 z-10"
        >
          Horseshoe
        </NerkoText>
      </div>
      <div className="w-full h-full bg-Secondary rounded-tr-[1rem] rounded-b-[1.65rem] px-3 py-2 mb-2 max-h-[calc(100%-3rem)] overflow-hidden flex flex-col justify-between">
        <div className="flex-shrink overflow-hidden">
          <ModernParagraph size="medium" className="text-White line-clamp-2">
            Ông chủ thầu cầu đường "phi lợi nhuận"
          </ModernParagraph>
          <ModernParagraph
            size="base"
            className="text-White text-justify !font-thin flex-shrink "
          >
            Nếu tình cờ gặp anh Phan Xuân Bá ở đâu đó mà chưa được giới thiệu về
            nghề nghiệp, chắc nhiều người sẽ đoán anh là một nghệ sỹ. Ít ai ngờ,
            một người thư sinh, trắng trẻo với mái tóc bồng bềnh nghệ sỹ ấy lại
            là giám đốc một công ty về xây dựng cầu đường ở nông thôn.
          </ModernParagraph>
        </div>

        <div className="flex flex-row justify-between items-center flex-shrink mt-2">
          <ModernParagraph
            size="base"
            className="text-White text-justify !font-thin"
          >
            15 giờ trước
          </ModernParagraph>
          <CommonButton
            size="base"
            theme="Pink"
            className="rounded-full w-10 h-10 !p-0"
            onClick={() => {}}
          >
            <i className="fi fi-sr-incognito -bottom-1"></i>
          </CommonButton>
        </div>
      </div>
    </>
  );
}

export default TheHorseshoeWidget;
