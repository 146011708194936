import { THiddenValleyEncryptionMethod } from "@constants/encrypt.constants";
import { z } from "zod";

export interface IHiddenValleyPassword {
  passwordId: string;
  name: string;
  value: string;
  key: string | null;
  encryptLevel: THiddenValleyEncryptionMethod;
  icon: string | null;
  createdAt: string;
}
export const HiddenValleyPasswordSchema = z.object({
  passwordId: z.string(),
  name: z.string(),
  value: z.string(),
  key: z.string().nullable(),
  encryptLevel: z.string(),
  icon: z.string().nullable(),
  createdAt: z.string(),
});
