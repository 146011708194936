// Importing necessary constants

import { IToken } from "@business-layer/services/entities";
import { COOKIE_KEYS } from "../../../../configs/constants";
// Importing authentication context
import { useAuthContext } from "../context";
import { removeCookie, setCookie, getCookie } from "@utils/helpers";

// Function to get the token
export const getToken = (): string | null => {
  return getCookie(COOKIE_KEYS.ACCESS_TOKEN);
};

export const useAccessToken = () => {
  // Getting the dispatch function from the authentication context
  const { dispatch } = useAuthContext();

  // Function to set the token
  const setToken = (newToken: IToken): void => {
    // Dispatch the new token to the context
    dispatch({
      type: "SET_TOKEN",
      payload: newToken.value,
    });
    setCookie(
      COOKIE_KEYS.ACCESS_TOKEN,
      newToken.value,
      new Date(newToken.expires)
    );
  };

  // Function to delete the token
  const deleteToken = (): void => {
    // Remove the token from the context
    dispatch({
      type: "SET_TOKEN",
      payload: null,
    });
    removeCookie(COOKIE_KEYS.ACCESS_TOKEN);
  };

  // Returning the hook functions
  return {
    getToken,
    setToken,
    deleteToken,
  };
};
