export const INITIAL_RESULTS_DATA = [
  {
    kind: "youtube#searchResult",
    etag: "jys52m44nsoYSQ_zJfLZnz7aXh8",
    id: {
      kind: "youtube#video",
      videoId: "MSt_8kpewQc",
    },
    snippet: {
      publishedAt: "2024-08-15T23:01:49Z",
      channelId: "UCL2dtWBeCSiXB0mxg3lHEgA",
      title:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Hot Nhất 2024",
      description:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Hot Nhất 2024 #edmtiktok ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/MSt_8kpewQc/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/MSt_8kpewQc/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/MSt_8kpewQc/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Min Xinh",
      liveBroadcastContent: "none",
      publishTime: "2024-08-15T23:01:49Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "4pSoeDujwEna_8LcbqPL0CL4sIM",
    id: {
      kind: "youtube#video",
      videoId: "7fWh_STiknY",
    },
    snippet: {
      publishedAt: "2024-08-14T12:00:08Z",
      channelId: "UCSY2Jb3C82bNYWv4DesCeJQ",
      title:
        "Top 20 Bản Nhạc EDM Alan Walker Remix Cực Phiêu Gây Nghiện ♫ Nhạc Điện Tử Gây Nghiện Hay Nhất 2024",
      description:
        "Nếu bạn thấy hay thì Subcribes và ấn chuông thông báo để không bỏ lỡ video mới :3 ⚡ Stream On Spotify ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/7fWh_STiknY/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/7fWh_STiknY/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/7fWh_STiknY/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "FURY EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-08-14T12:00:08Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "FVJMspsHPJ4ODV3_WmWBz18vvqA",
    id: {
      kind: "youtube#video",
      videoId: "JLjLM1pnd-Y",
    },
    snippet: {
      publishedAt: "2024-08-14T23:03:02Z",
      channelId: "UCL2dtWBeCSiXB0mxg3lHEgA",
      title:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Mới Nhất 2024",
      description:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Mới Nhất 2024 #edmtiktok ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/JLjLM1pnd-Y/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/JLjLM1pnd-Y/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/JLjLM1pnd-Y/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Min Xinh",
      liveBroadcastContent: "none",
      publishTime: "2024-08-14T23:03:02Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "jjNAC4_USv3BzlL8wiwohk1_JJA",
    id: {
      kind: "youtube#video",
      videoId: "oQIF3RIwACg",
    },
    snippet: {
      publishedAt: "2024-08-15T07:00:08Z",
      channelId: "UCdWmirD6MzuTFeUAtAv4TKQ",
      title:
        "Music Mix 2024 🎧 Mashups &amp; Remixes Of Popular Songs 🎧 EDM Bass Boosted Music Mix",
      description:
        "Listen on Spotify: https://magicmusic.link/BestCarPlaylist https://deepuniverse.lnk.to/HouseMusic ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/oQIF3RIwACg/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/oQIF3RIwACg/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/oQIF3RIwACg/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Forever Young",
      liveBroadcastContent: "none",
      publishTime: "2024-08-15T07:00:08Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "1_wqdSOeLA246iRxdZ3uynH-Zzg",
    id: {
      kind: "youtube#video",
      videoId: "6FQkvjixk7g",
    },
    snippet: {
      publishedAt: "2024-08-13T23:00:59Z",
      channelId: "UCL2dtWBeCSiXB0mxg3lHEgA",
      title:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Hot Nhất 2024",
      description:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Hot Nhất 2024 #edmtiktok ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/6FQkvjixk7g/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/6FQkvjixk7g/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/6FQkvjixk7g/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Min Xinh",
      liveBroadcastContent: "none",
      publishTime: "2024-08-13T23:00:59Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "wSuvkUGOE9_4Un4lgt8jfQzYRJ8",
    id: {
      kind: "youtube#video",
      videoId: "6rqVx5ZW-YY",
    },
    snippet: {
      publishedAt: "2024-08-04T12:00:10Z",
      channelId: "UCSY2Jb3C82bNYWv4DesCeJQ",
      title:
        "🔥 Top Nhạc EDM Mix Alan Walker Cực Phiêu Gây Nghiện ♫ Nhạc Điện Tử Gây Nghiện Hay Nhất 2024",
      description:
        "Nếu bạn thấy hay thì Subcribes và ấn chuông thông báo để không bỏ lỡ video mới :3 ⚡ Stream On Spotify ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/6rqVx5ZW-YY/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/6rqVx5ZW-YY/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/6rqVx5ZW-YY/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "FURY EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-08-04T12:00:10Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "79LDkna9_sauySEgk-DW4EXjnxE",
    id: {
      kind: "youtube#video",
      videoId: "xvfn_m-oZ4w",
    },
    snippet: {
      publishedAt: "2024-08-07T13:45:20Z",
      channelId: "UCrhNjae2abWsOIqZVAYLZtw",
      title:
        "EDM Mashup Mix 2024 | Best Mashups &amp; Remixes of Popular Songs - Party Music 2024",
      description:
        "EDM Mashup Mix 2024 | Best Mashups & Remixes of Popular Songs - Party Music 2024 - Mixed by Daveepa. Listen on Spotify: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/xvfn_m-oZ4w/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/xvfn_m-oZ4w/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/xvfn_m-oZ4w/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "daveepa",
      liveBroadcastContent: "none",
      publishTime: "2024-08-07T13:45:20Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "kwtJ9i_FlDz4ttmgspmrqAInXZk",
    id: {
      kind: "youtube#video",
      videoId: "fy0QPlBxDTo",
    },
    snippet: {
      publishedAt: "2024-08-16T07:30:19Z",
      channelId: "UCV1AVTmvECyLJ_w5SgLcX5g",
      title:
        "BASS BOOSTED SONGS 2024 🔈 CAR MUSIC 2024 🔈 EDM BASS BOOSTED MUSIC 2024",
      description:
        "Stream on Spotify : https://magicmusic.link/BestCarPlaylist © Follow Dream Club https://bit.ly/3NXv27G © Follow Magic Music ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/fy0QPlBxDTo/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/fy0QPlBxDTo/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/fy0QPlBxDTo/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Dream Club",
      liveBroadcastContent: "none",
      publishTime: "2024-08-16T07:30:19Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "t9tQyh7dNJqm2HnhZD59gYeMfs0",
    id: {
      kind: "youtube#video",
      videoId: "HMUpdXAjaMI",
    },
    snippet: {
      publishedAt: "2024-04-13T09:00:09Z",
      channelId: "UCvpQ7CVnMkqhWGbBNKQDVNg",
      title:
        "Thương Ly Biệt Remix ♫ BXH Nhạc Trẻ EDM Hót Nhất Hiện Nay - Top 15 Bản EDM TikTok Hot Trend 2024",
      description:
        "nhactreedm #nhacedm #nhactreremix #nhacremix Thương Ly Biệt Remix ♫ BXH Nhạc Trẻ EDM Hót Nhất Hiện Nay - Top 15 Bản ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/HMUpdXAjaMI/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/HMUpdXAjaMI/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/HMUpdXAjaMI/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Hey Hey",
      liveBroadcastContent: "none",
      publishTime: "2024-04-13T09:00:09Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "eTr_7cy3kq8PN0fHek0hdEF7QOA",
    id: {
      kind: "youtube#channel",
      channelId: "UCn85crrYIk3S4pKsp0famCw",
    },
    snippet: {
      publishedAt: "2018-01-21T13:28:04Z",
      channelId: "UCn85crrYIk3S4pKsp0famCw",
      title: "EDM Music One",
      description:
        "Welcome to EDM Music One ! On this channel you will find a wide variety of different Deep House, Tropical House, Chill Out, EDM ...",
      thumbnails: {
        default: {
          url: "https://yt3.ggpht.com/Sgw7QBQkILCIIerBLkaK6i2mdp_NZiXyRGOYBugH2bIpPXvIHU-1wk_MVXmuyC8sImhbxXkUwJg=s88-c-k-c0xffffffff-no-rj-mo",
        },
        medium: {
          url: "https://yt3.ggpht.com/Sgw7QBQkILCIIerBLkaK6i2mdp_NZiXyRGOYBugH2bIpPXvIHU-1wk_MVXmuyC8sImhbxXkUwJg=s240-c-k-c0xffffffff-no-rj-mo",
        },
        high: {
          url: "https://yt3.ggpht.com/Sgw7QBQkILCIIerBLkaK6i2mdp_NZiXyRGOYBugH2bIpPXvIHU-1wk_MVXmuyC8sImhbxXkUwJg=s800-c-k-c0xffffffff-no-rj-mo",
        },
      },
      channelTitle: "EDM Music One",
      liveBroadcastContent: "none",
      publishTime: "2018-01-21T13:28:04Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "5WPMAvGWUzyvc6A49YNUTgfFY7g",
    id: {
      kind: "youtube#playlist",
      playlistId: "RDCLAK5uy_kIlC_He_q02_we1RKAq1_4JEYmueTqnGs",
    },
    snippet: {
      publishedAt: "2018-01-17T17:00:52Z",
      channelId: "UC",
      title: "Energizing EDM",
      description:
        "Put your hands up with this energetic collection of mainstage EDM anthems.",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/LRPGqNeav_M/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/LRPGqNeav_M/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/LRPGqNeav_M/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "",
      liveBroadcastContent: "none",
      publishTime: "2018-01-17T17:00:52Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "Ef-7QyznZpWtYhIrjADRtDRhKkU",
    id: {
      kind: "youtube#video",
      videoId: "_oIX8WJHnbQ",
    },
    snippet: {
      publishedAt: "2024-08-13T08:01:06Z",
      channelId: "UCyi1X8FNujkAnsAEFKxZGiw",
      title:
        "PARTY REMIX 2024 🔥 Mashups &amp; Remixes Of Popular Songs 🔥 DJ Remix Club Music Dance Mix 2024",
      description:
        "Music Mix 2024 Remixes of Popular Songs EDM Bass Boosted Music Mix ➤ Listen on Spotify: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/_oIX8WJHnbQ/default_live.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/_oIX8WJHnbQ/mqdefault_live.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/_oIX8WJHnbQ/hqdefault_live.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Knight EDM",
      liveBroadcastContent: "live",
      publishTime: "2024-08-13T08:01:06Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "AqCLGN0V9Utn6O1g7NTIdhO5gN0",
    id: {
      kind: "youtube#playlist",
      playlistId: "RDCLAK5uy_nBQm8_YpP--R6zU8p3dypKm1QKqzWY6qU",
    },
    snippet: {
      publishedAt: "2018-01-17T18:01:05Z",
      channelId: "UC",
      title: "Feeling Epic EDM",
      description: "Get yourself moving with these high energy Dance anthems!",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/xpNJ0qzaX4Q/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/xpNJ0qzaX4Q/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/xpNJ0qzaX4Q/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "",
      liveBroadcastContent: "none",
      publishTime: "2018-01-17T18:01:05Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "MHOqAMA0mHjliwONiuz9lm3WjzY",
    id: {
      kind: "youtube#playlist",
      playlistId: "RDCLAK5uy_lmztFiBPwa16-qz6nwMaNrtVFPzCYNOkA",
    },
    snippet: {
      publishedAt: "2018-01-17T18:01:15Z",
      channelId: "UC",
      title: "Chill EDM Hits",
      description: "Chill EDM anthems, with The Chainsmokers.",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/PkjaBCLOAec/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/PkjaBCLOAec/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/PkjaBCLOAec/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "",
      liveBroadcastContent: "none",
      publishTime: "2018-01-17T18:01:15Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "MHGceP1Xr6I6CJdEXUDns9ruvwU",
    id: {
      kind: "youtube#video",
      videoId: "DvSU66RdrV4",
    },
    snippet: {
      publishedAt: "2024-08-12T23:01:18Z",
      channelId: "UCL2dtWBeCSiXB0mxg3lHEgA",
      title:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Mới Nhất 2024",
      description:
        "EDM TikTok Hay 2024 ♫ BXH Nhạc Trẻ Remix Hay Nhất Hiện Nay - Top 15 Bản EDM TikTok Mới Nhất 2024 #edmtiktok ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/DvSU66RdrV4/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/DvSU66RdrV4/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/DvSU66RdrV4/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Min Xinh",
      liveBroadcastContent: "none",
      publishTime: "2024-08-12T23:01:18Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "52ZsfRhTWqE_cv4lAUX4Yk8aI90",
    id: {
      kind: "youtube#video",
      videoId: "dmeQMmI-EkY",
    },
    snippet: {
      publishedAt: "2024-08-15T22:55:08Z",
      channelId: "UCkVN7V208QukMxz6kpfSLbQ",
      title:
        "Nhạc Remix Hot Tiktok 2024🎼TOP 30 Nhạc Trẻ Remix Hay Nhất 2024 - BXH Nhạc Remix Nghe Nhiều Nhất 2024",
      description:
        "Nhạc Remix Hot Tiktok 2024  TOP 30 Nhạc Trẻ Remix Hay Nhất 2024 - BXH Nhạc Remix Nghe Nhiều Nhất 2024 Thu Hạ Remix ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/dmeQMmI-EkY/default_live.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/dmeQMmI-EkY/mqdefault_live.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/dmeQMmI-EkY/hqdefault_live.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Thu Hạ Remix",
      liveBroadcastContent: "live",
      publishTime: "2024-08-15T22:55:08Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "UpPrLo-iYlK5z6pqlSqsriFnHnw",
    id: {
      kind: "youtube#playlist",
      playlistId: "RDCLAK5uy_n0oLcyKJhNW8BmrnMySAoVuLjRZfgozG0",
    },
    snippet: {
      publishedAt: "2018-01-17T17:00:52Z",
      channelId: "UC",
      title: "Energizing EDM",
      description: "Give yourself extra energy with these massive EDM hits.",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/CPN3IgGd6sc/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/CPN3IgGd6sc/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/CPN3IgGd6sc/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "",
      liveBroadcastContent: "none",
      publishTime: "2018-01-17T17:00:52Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "UFeXEoriFw1P7jsW-Fg9X6dzB5o",
    id: {
      kind: "youtube#video",
      videoId: "jmoH3GmZ0mw",
    },
    snippet: {
      publishedAt: "2024-08-15T04:48:33Z",
      channelId: "UCAJ1rjf90IFwNGlZUYuoP1Q",
      title:
        "Ibiza Summer Mix 2024 🍓 Best Of Tropical Deep House Music Chill Out Mix 2023 🍓 Chillout Lounge",
      description:
        "Ibiza Summer Mix 2024 Best Of Tropical Deep House Music Chill Out Mix 2023 Chillout Lounge Stream/Download: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/jmoH3GmZ0mw/default_live.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/jmoH3GmZ0mw/mqdefault_live.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/jmoH3GmZ0mw/hqdefault_live.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Magic Club",
      liveBroadcastContent: "live",
      publishTime: "2024-08-15T04:48:33Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "E5y9-G0f1SpVWkwbNDaqroISwBw",
    id: {
      kind: "youtube#playlist",
      playlistId: "PLECIVWKTJ69_Uch9spcjD3TZ1eThel6sl",
    },
    snippet: {
      publishedAt: "2021-05-11T15:20:10Z",
      channelId: "UCIitwYtRrd2JGTTe1B0IAJA",
      title: "EDM Remixes of Popular Songs",
      description: "",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/TJ-KcMxaqPg/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/TJ-KcMxaqPg/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/TJ-KcMxaqPg/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Magic Music Mix",
      liveBroadcastContent: "none",
      publishTime: "2021-05-11T15:20:10Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "HPQoiYkqyJ-yo2pi2-vQRibd-zg",
    id: {
      kind: "youtube#playlist",
      playlistId: "RDCLAK5uy_mGt2vOEjyX4yUCxY4OrzVzZLR8nPXGPgE",
    },
    snippet: {
      publishedAt: "2019-09-25T08:26:20Z",
      channelId: "UC",
      title: "&#39;10s EDM",
      description:
        "From warehouses to Vegas clubs, the 2010s saw dance music reenter the mainstream. Chart EDM's rise with some of the biggest ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/MGuM2SlZBJ4/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/MGuM2SlZBJ4/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/MGuM2SlZBJ4/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "",
      liveBroadcastContent: "none",
      publishTime: "2019-09-25T08:26:20Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "zKy36XEzHWw3MFLEhhDnZTGFNNs",
    id: {
      kind: "youtube#video",
      videoId: "sTmgaP2gYsk",
    },
    snippet: {
      publishedAt: "2024-02-29T07:00:29Z",
      channelId: "UCVfi_UYv_ZES-BIdFqmrMuQ",
      title:
        "Music Mix 2024 🎧 EDM Remixes of Popular Songs 🎧 EDM Gaming Music Mix ​",
      description:
        "Music Mix 2024 EDM Remixes of Popular Songs EDM Gaming Music Mix ​ #MagicRecord #MusicMusic #Edm Listen on ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/sTmgaP2gYsk/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/sTmgaP2gYsk/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/sTmgaP2gYsk/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Infinity EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-02-29T07:00:29Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "LvsWUSyzUfzJkneHHLJVRlGgHWc",
    id: {
      kind: "youtube#video",
      videoId: "yFEPlb4QBug",
    },
    snippet: {
      publishedAt: "2024-02-29T23:01:39Z",
      channelId: "UCdmidUqyM0OKBIHOByFLhMQ",
      title:
        "EDM Tik Tok ♫ Top Nhạc Tik Tok Tiếng Anh (Us - Uk) Mix Gây Nghiện Hay Nhất 2024",
      description:
        "EDM Tik Tok ♫ Top Nhạc Tik Tok Tiếng Anh (Us - Uk) Mix Gây Nghiện Hay Nhất 2024 ▻ Listen now: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/yFEPlb4QBug/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/yFEPlb4QBug/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/yFEPlb4QBug/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Cloud EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-02-29T23:01:39Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "6Z8yiw8jmLaRDHt9jUP28io_4Aw",
    id: {
      kind: "youtube#video",
      videoId: "y_FkQ2jHd1c",
    },
    snippet: {
      publishedAt: "2023-01-24T07:00:46Z",
      channelId: "UCdWmirD6MzuTFeUAtAv4TKQ",
      title:
        "Music Mix 2023 🎧 Remixes of Popular Songs 🎧 EDM Gaming Music Mix",
      description:
        "Listen on Spotify: https://magicmusic.link/BestCarPlaylist https://deepuniverse.lnk.to/HouseMusic ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/y_FkQ2jHd1c/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/y_FkQ2jHd1c/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/y_FkQ2jHd1c/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Forever Young",
      liveBroadcastContent: "none",
      publishTime: "2023-01-24T07:00:46Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "hazIoFTZ0LJhv_PKVMs3KjEYMR8",
    id: {
      kind: "youtube#video",
      videoId: "U7W-u7sBEJE",
    },
    snippet: {
      publishedAt: "2024-04-21T08:00:15Z",
      channelId: "UCXF85mM28a_ClQ0x01yTSow",
      title:
        "Music Mix 2024 🎧 EDM Mixes of Popular Songs 🎧 EDM Bass Boosted Music Mix #62",
      description:
        "Music Mix 2024 EDM Mixes of Popular Songs EDM Bass Boosted Music Mix #62 Listen on Spotify / Apple / Youtube ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/U7W-u7sBEJE/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/U7W-u7sBEJE/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/U7W-u7sBEJE/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "EDM Vibe",
      liveBroadcastContent: "none",
      publishTime: "2024-04-21T08:00:15Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "FIhY7YAO47uAV9JUq4SzsUaQBRE",
    id: {
      kind: "youtube#video",
      videoId: "g7O-7rF0Hqk",
    },
    snippet: {
      publishedAt: "2024-07-29T15:01:29Z",
      channelId: "UC1l7wYrva1qCH-wgqcHaaRg",
      title: "David Guetta live @ Tomorrowland 2024",
      description:
        "David Guetta @ Tomorrowland, Saturday, July 28th 2024 Subscribe to be notified for new videos: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/g7O-7rF0Hqk/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/g7O-7rF0Hqk/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/g7O-7rF0Hqk/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "David Guetta",
      liveBroadcastContent: "none",
      publishTime: "2024-07-29T15:01:29Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "t-h-Pvrgeb1Au9WgUluJd3f_iGw",
    id: {
      kind: "youtube#playlist",
      playlistId: "PL6Y6az3uyYAZJailKdu-zkc9JnvCyvgoN",
    },
    snippet: {
      publishedAt: "2022-09-13T19:26:03Z",
      channelId: "UCIGiH7sE1UTPwq4PtGUcfWg",
      title: "RAGE 💪 EDM WORKOUT MOTIVATION",
      description: "EDM WORKOUT & MOTIVATION a ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/RXRWE_XQ8aE/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/RXRWE_XQ8aE/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/RXRWE_XQ8aE/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Fabio Amorelli",
      liveBroadcastContent: "none",
      publishTime: "2022-09-13T19:26:03Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "vKs6HZtxBXFkX2jAzmKQ80KQmS4",
    id: {
      kind: "youtube#video",
      videoId: "zkO3wjHT4GU",
    },
    snippet: {
      publishedAt: "2024-03-16T23:02:40Z",
      channelId: "UCdmidUqyM0OKBIHOByFLhMQ",
      title:
        "Top 20 Bản Nhạc EDM Gây Nghiện Hay Nhất 2024 ♫ Nhạc EDM Nhiều Cảm Xúc Cho Ngày Dài Mỏi Mệt",
      description:
        "Top 20 Bản Nhạc EDM Gây Nghiện Hay Nhất 2024 ♫ Nhạc EDM Nhiều Cảm Xúc Cho Ngày Dài Mỏi Mệt ▻ Listen now: ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/zkO3wjHT4GU/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/zkO3wjHT4GU/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/zkO3wjHT4GU/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Cloud EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-03-16T23:02:40Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "HcL6PUk1aRp_I9hv-2bGypLTZFE",
    id: {
      kind: "youtube#playlist",
      playlistId: "PLPqHbpPVyy5dQmoAdC_8taXMkbJTPT1AS",
    },
    snippet: {
      publishedAt: "2018-09-29T07:14:04Z",
      channelId: "UCN1maa0shxUBs2GfMSs_UPg",
      title: "Best Dance / EDM Songs HITS Of All Time",
      description:
        "EDM #Dance #Playlist #GlobalHits #AddictiveChart #Masterpiece #Trending #Classics Follow the playlist on #Spotify ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/IcrbM1l_BoI/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/IcrbM1l_BoI/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/IcrbM1l_BoI/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: " World Dance Music Chart",
      liveBroadcastContent: "none",
      publishTime: "2018-09-29T07:14:04Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "eo0aeDyLjL_437BnnjfaK9heFb0",
    id: {
      kind: "youtube#video",
      videoId: "rmW0WtNRmeQ",
    },
    snippet: {
      publishedAt: "2024-05-22T00:01:18Z",
      channelId: "UCs5J6-8iuwocLnJAfSG_Brg",
      title:
        "Lướt Sóng Đạp Mây - TOP 20 Bản EDM Hot Tiktok TRIỆU VIEW - BXH Nhạc Trẻ Remix Hot Nhất Hiện Nay",
      description:
        "Lướt Sóng Đạp Mây - TOP 20 Bản EDM Hot Tiktok TRIỆU VIEW - BXH Nhạc Trẻ Remix Hot Nhất Hiện Nay Lướt Sóng Đạp Mây ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/rmW0WtNRmeQ/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/rmW0WtNRmeQ/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/rmW0WtNRmeQ/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Lạc Lạc EDM",
      liveBroadcastContent: "none",
      publishTime: "2024-05-22T00:01:18Z",
    },
  },
  {
    kind: "youtube#searchResult",
    etag: "H6AhFQsgYZEomRb3dXcOtemOTpY",
    id: {
      kind: "youtube#video",
      videoId: "jETECLiAF3o",
    },
    snippet: {
      publishedAt: "2022-03-17T11:00:15Z",
      channelId: "UCmQChhvMypqrwyqyRqbX9tQ",
      title:
        "EDM Tik Tok - Tổng Hợp 20 Nhạc Tik Tok Tiếng Anh US - UK Gây Nghiện Hay Nhất 2022",
      description:
        "EDM Tik Tok - Tổng Hợp 20 Nhạc Tik Tok Tiếng Anh US - UK Gây Nghiện Hay Nhất 2022 #edmtiktok #nhactiktok #tiktoktienganh ...",
      thumbnails: {
        default: {
          url: "https://i.ytimg.com/vi/jETECLiAF3o/default.jpg",
          width: 120,
          height: 90,
        },
        medium: {
          url: "https://i.ytimg.com/vi/jETECLiAF3o/mqdefault.jpg",
          width: 320,
          height: 180,
        },
        high: {
          url: "https://i.ytimg.com/vi/jETECLiAF3o/hqdefault.jpg",
          width: 480,
          height: 360,
        },
      },
      channelTitle: "Top Songs Radio",
      liveBroadcastContent: "none",
      publishTime: "2022-03-17T11:00:15Z",
    },
  },
];
