/* eslint-disable @typescript-eslint/no-unused-vars */

import { useFetchAccountDetail } from "@business-layer/business-logic/lib/account";
import {
  useGetBluelyraProcess,
  useChangeBluelyraPlayed,
  usePlayVideoInPlaylist,
} from "@business-layer/business-logic/lib/bluelyra";
import { useGetBluelyraProfile } from "@business-layer/business-logic/lib/bluelyra";
import { APP_URLS } from "@constants/urls.constants";
import { CommonNotification } from "@presentational/atoms";
import { AppHeader, AppNavbar } from "@presentational/organisms";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useMemo, useRef } from "react";
import ReactPlayer from "react-player";
import { OnProgressProps } from "react-player/base";
import { useLocation } from "react-router-dom";

export function AppTemplate({ children }: { children?: React.ReactNode }) {
  const playerRef = useRef<ReactPlayer>(null);
  const { pathname } = useLocation();
  const { isPlaying, volume, seekTo } = useGetBluelyraProcess();
  const { currentVideo } = useGetBluelyraProfile();
  const { onEnd } = usePlayVideoInPlaylist();
  const { onChange: setPlayed } = useChangeBluelyraPlayed();
  const isNotDashboard = useMemo(
    () => !pathname.includes(APP_URLS.DASHBOARD),
    [pathname]
  );
  useFetchAccountDetail();

  const handleProgress = (state: OnProgressProps) => {
    setPlayed(state.played);
  };

  useEffect(() => {
    if (seekTo && playerRef.current) {
      playerRef.current.seekTo(seekTo - 0.00001);
    }
  }, [seekTo]);

  return (
    <>
      <CommonNotification />
      <div
        key={"hidden-valley-home-wrapper"}
        className="bg-Secondary relative grid grid-cols-[6rem,1fr] gap-3 pl-5 pr-5 no-scrollbar h-screen overflow-hidden"
      >
        {currentVideo ? (
          <ReactPlayer
            ref={playerRef}
            url={`https://www.youtube.com/watch?v=${currentVideo.id}`}
            playing={isPlaying}
            volume={volume}
            stopOnUnmount={true}
            progressInterval={1000}
            onEnded={onEnd}
            onProgress={handleProgress}
            style={{ display: "none" }} // Ẩn video để chỉ nghe nhạc
          />
        ) : null}

        <AppNavbar />
        {/* <AnimatePresence mode="wait">
            {isNotDashboard ? <AppHeader key="app-header" /> : null}
          </AnimatePresence> */}
        {children}
      </div>
    </>
  );
}
